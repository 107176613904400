import React from 'react'
import {useRive,Layout,Fit,Alignment} from '@rive-app/react-canvas';
import RiveFileMain from './budgetevenloading.riv'



export default function RiveSmallCircleLoading({mainText}) {

    const { RiveComponent } = useRive({
        src: RiveFileMain,
        stateMachines: "State Machine 1",
        autoplay: true,
        artboard:'SmallCircleLoading',
      
        layout: new Layout({
            fit:Fit.Contain,
            alignment: Alignment.Center
        })
        
      });


      // useEffect(() => {
      
      //   if(rive !== null){
      //     rive.setTextRunValue('MainText',mainText)
      //   }

      // },[rive,mainText])




    return (

      <div style={{height:25,width:25}}>
           <RiveComponent       /> 
           </div>
     )
   
      
    
   
   
     
   

  


}
