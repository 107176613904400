
import { Connect, ConnectEventHandlers, ConnectOptions, ConnectDoneEvent, 
  ConnectCancelEvent, ConnectErrorEvent, ConnectRouteEvent } from 'connect-web-sdk';
import React,{useEffect} from 'react'
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";


export default function FinicityAlreadySemiAutoWithBudget (){
  const location = useLocation();
  const navigate = useNavigate();
    const connectURL = location.state.urlToUse


  useEffect(() => {
    // Define the event handlers
    const connectEventHandlers: ConnectEventHandlers = {
        onDone: (event: ConnectDoneEvent) => { 
          

          navigate('../finicityasabl',{state: {finCustomerId: location.state.finCustomerId, url:location.state.urlToUse}})
          
        
        },
        onCancel: (event: ConnectCancelEvent) => {
          
          navigate('../finicityasabl',{state: {finCustomerId: location.state.finCustomerId, url:location.state.urlToUse}})
        
        },
        onError: (event: ConnectErrorEvent) => { 
          navigate('../finicityasabl',{state: {finCustomerId: location.state.finCustomerId, url:location.state.urlToUse}})
         },
        onRoute: (event: ConnectRouteEvent) => { console.log(event); },
        onUser: (event: any) => { console.log(event); },
        onLoad: () => { console.log('loaded'); }
    };

    // Options for the Connect SDK
    const connectOptions: ConnectOptions = {
        overlay: 'rgba(199,201,199, 0.5)'
    };

    // Launch the Connect functionality
    Connect.launch(connectURL, connectEventHandlers, connectOptions);

    // Optionally return a cleanup function
    return () => {
        // Any cleanup logic goes here
        // Connect.close() if such a method exists to properly cleanup the connection
    };
}, []); // Empty dependency array ensures this effect runs only once after the initial render

return (
    <div style={{backgroundColor:'white'}}>
     
    </div>
)
}

