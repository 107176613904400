

import React,{useEffect} from 'react'
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";
import { updateStep,updateAutoStep } from "utils/FirebaseAnalyticsMethods";

import { Connect, ConnectEventHandlers, ConnectOptions, ConnectDoneEvent, ConnectCancelEvent, ConnectErrorEvent, ConnectRouteEvent } from 'connect-web-sdk';


  export default function AutoFinicity(){

    const location = useLocation();
    const navigate = useNavigate();
  
    let finCustomerId = location.state.finCustomerId
    let topUrl = location.state.url
    let connectURL = location.state.url


   useEffect(() => {
    updateStep('FinicityPortal')
    updateAutoStep('FinicityPortal')
   },[])

    useEffect(() => {
      // Define the event handlers
      const connectEventHandlers: ConnectEventHandlers = {
          onDone: (event: ConnectDoneEvent) => { 
            
            navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
            
          
          },
          onCancel: (event: ConnectCancelEvent) => {
            
            navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
          
          },
          onError: (event: ConnectErrorEvent) => { navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})},
          onRoute: (event: ConnectRouteEvent) => {  },
          onUser: (event: any) => {  },
          onLoad: () => { }
      };

      // Options for the Connect SDK
      const connectOptions: ConnectOptions = {
          overlay: 'rgba(199,201,199, 0.5)'
      };

      // Launch the Connect functionality
      Connect.launch(connectURL, connectEventHandlers, connectOptions);

      // Optionally return a cleanup function
      return () => {
          // Any cleanup logic goes here
          // Connect.close() if such a method exists to properly cleanup the connection
      };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
      <div style={{backgroundColor:'white'}}>
       
      </div>
  )
  }

// export default function AutoFinicityA (){

//   const location = useLocation();
//   const navigate = useNavigate();


//   const [loading, setLoading] = useState(false);

//     let finCustomerId = location.state.finCustomerId
//     let topUrl = location.state.url


//    useEffect(() => {
//     updateStep('FinicityPortal')
//     updateAutoStep('FinicityPortal')
//    },[])

//     useEffect(() => {
//         let urlToUse = location.state.url

//         const script = document.createElement('script');
      
  
//         script.src = "https://connect2.finicity.com/assets/sdk/finicity-connect.min.js"
//         script.async = true;
    
    
//       document.body.append(script);

  
//         script.onload = function() {
     
//                 window.finicityConnect.launch(urlToUse,{
//                     selector:'#connect-container',
//                     overlay:'rgba(255,255,255,0)',
                     
                  
                    
//                     success:(event) =>{
//                       navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
                   
        
//                     },
//                     done: (event) => {
//                       navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
//                     },
//                     cancel:(event) => {
//                       navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
//                     },
//                     error:(event) => {
//                       navigate('/errorgetstarted',{state: {finCustomerId: finCustomerId, url:topUrl}})
//                     },
//                     loaded: () => {
              
//                         script.focus();
                        
//                     },
//                     route: (event) => {
              
               
                   
//                     },
//                     user: (event) => {
                
                
                       
//                     }
//                 })
//             }

//     },[location.state.urlToUse])




//     return(
//        <div>
     
      
        
//              <Backdrop sx={{backgroundColor:'white'}} open={loading} style={{zIndex:1400}}>
//           <Grid container direction='column'  justifyContent='center' alignItems='center'>
           
//             <Grid item style={{paddingTop:5}}>
//               <Typography style={{color:'white', fontWeight:'bold', textAlign:'center'}}>Refreshing your account data...</Typography>
//               <Typography style={{color:'white', fontWeight:'bold', textAlign:'center'}}>This can take up to a minute...</Typography>
//             </Grid>
//           </Grid>
        
        
//         </Backdrop> 
//        </div>
//     )
// }