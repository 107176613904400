import React,{useEffect} from 'react'
import { Backdrop,Grid,useTheme,Typography } from '@mui/material'
import { Ellipsis } from 'react-spinners-css'
import { getFunctions,httpsCallable } from 'firebase/functions'
import { useNavigate,useLocation } from 'react-router'
import { getFirestore,doc,updateDoc,setDoc,query,where,collection,getDocs } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getTodaysEpoch, getMonthBeginningEpoch } from 'utils/Helpers'

const functions = getFunctions()
const db = getFirestore();

export default function FinicityAlreadySemiAutoBudgetLoading() {
    
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
refreshFinAccounts();
    })

    async function refreshFinAccounts(){
        let finCustomerId = location.state.finCustomerId
        let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');
  
        let accounts =  await retrieveBalances({customerId: finCustomerId}).then(function(result) {
        
            return result.data.accounts;
          }).catch(function(error){
        
            return false;
          })

       
          uploadAccounts(accounts);
    }

    async function uploadAccounts(finAccountsArray){
        const uid = getAuth().currentUser.uid;

        //checkforashflowaccount
        let cashCollectionRef = collection(db,'EuklesUsersData',uid,'Accounts')
        let q = query(cashCollectionRef,where("isCashFlowAccount","==",true))

        let cashFlowAccountId = await getDocs(q).then(querySnapshot => {
          let accountId = ''
          querySnapshot.forEach(doc => {
            accountId = doc.data().id
          })

          return accountId
        }).catch(e => {
          return ''
        })



        for(let i = 0; i < finAccountsArray.length; i++){
          if(String(finAccountsArray[i].id) === String(cashFlowAccountId)){
            let myObjectAlt = {...finAccountsArray[i], isFinicity:true, isCashFlowAccount:true}
            let accountsDocRef = doc(db,'EuklesUsersData',uid,'Accounts',String(finAccountsArray[i].id))
            await setDoc(accountsDocRef,myObjectAlt)
           await  uploadBranding(finAccountsArray[i])
          }
          else {
            let myObject = {...finAccountsArray[i], isFinicity:true}
            let accountDocRef = doc(db,'EuklesUsersData',uid, 'Accounts',String(finAccountsArray[i].id))
            await setDoc(accountDocRef,myObject)
         
            await uploadBranding(finAccountsArray[i])
          }
          
        }
        getCurrentMonthTransactions();

    }

    async function uploadBranding(account){
     
        const uid = getAuth().currentUser.uid;
        let logo = 'nil'
        let icon = 'nil'
        let tile = 'nil'
    
        let getBranding = httpsCallable(functions,'retrieveInstitutionBranding')
      
        let brandingObj = await getBranding({institutionId: account.institutionId}).then((res) => {
                 
          
             return res.data.branding;
         }).catch((error) => {
          
             return false;
         })
      
        
      
         if(brandingObj !== false){
          if(typeof brandingObj.logo !== 'undefined'){
            logo = brandingObj.logo
          }
      
          if(typeof brandingObj.icon !== 'undefined'){
           icon = brandingObj.icon
          }
      
          if(typeof brandingObj.tile !== 'undefined'){
            tile = brandingObj.tile
          }
    
    
       let accountsRef = doc(db,'EuklesUsersData',uid,'Accounts',String(account.id))
          
       await updateDoc(accountsRef,{logo:logo,icon:icon,tile:tile})
         }

        
      }

      async function getCurrentMonthTransactions(){
 
        let endingEpoch = getTodaysEpoch()
    
    
       
        let beginningEpoch = getMonthBeginningEpoch();
    
    
        let getTransactions = httpsCallable(functions,'getMonthToDateTransactions');
      
        let rawTransactionArray = await getTransactions({customerId: location.state.finCustomerId,  beginningEpoch: beginningEpoch, endingEpoch: endingEpoch}).then((result) => {
               
      
             
               return result.data.newTransactionArray
           }).catch((error) => {
             
               return 'error';
           })
    
     
    
                 rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Investment Income')
                rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Other')
                rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Mutual Fund')
                rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Sell Other')
                rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'RETIREMENT ACCT FEE')
                rawTransactionArray = rawTransactionArray.filter(obj => obj.description !== 'Buy Stock')
    
           
         //getTrackingRules(rawTransactionArray)
         getAlreadyTrackedTransactions(beginningEpoch,rawTransactionArray)
        // await uploadTransactions(rawTransactionArray)
        }

        async function getAlreadyTrackedTransactions(beginningEpoch, rawTransactionArray){
          const userUid = getAuth().currentUser.uid;
          let collectionRef = collection(db,'EuklesUsersData',userUid,'Transactions')
          let q = query(collectionRef,where("trackingEpoch",">=",beginningEpoch))
          let trackedTransactions = await getDocs(q).then(querySnapshot => {
              let localArray = []
              querySnapshot.forEach(doc => {
                  localArray.push({id: doc.id})
              })
              return localArray
          }).catch(e => {
              return []
          })
  
       
        
  
          let filteredTransactions = []
          for(let i = 0; i < rawTransactionArray.length; i++){
              let transaction = rawTransactionArray[i]
              let existingIndex = trackedTransactions.findIndex(obj => parseInt(obj.id) === parseInt(transaction.id))
              if(existingIndex === -1){
                  filteredTransactions.push(transaction)
              }
          }
  
 
     await uploadTransactions(filteredTransactions)
     
      
      }

        async function uploadTransactions(transactions){
          const userUid = getAuth().currentUser.uid;
          for(let i = 0; i < transactions.length; i++){
            let transaction = transactions[i]
            let myObject = {...transaction, trackingStatus: 'New'}
            let transDoc = doc(db,'EuklesUsersData',userUid,'Transactions',String(transaction.id))

            await setDoc(transDoc,myObject).then(res => {
                return true
            }).catch(e => {
    
                
            })
        }

        navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
        }



    return(
  
        <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
           <Grid container direction='column'  justifyContent='center' alignItems='center'>
             <Grid item>
             <Ellipsis color={theme.palette.primary.main} />
             </Grid>
             <Grid item style={{paddingTop:5}}>
               <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>Organizing your accounts...</Typography>
             </Grid> 
           </Grid>
          
          
         </Backdrop> 
          )
}
