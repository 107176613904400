import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { BudgetContextTwo } from 'contexts/BudgetContextTwo';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { Ellipsis } from 'react-spinners-css';
import { formatTransactionDate } from 'utils/Helpers';
import { getAuth } from 'firebase/auth';
import { getFirestore,doc,getDocs,getDoc,collection,updateDoc,query, where } from 'firebase/firestore';
import { getFunctions,httpsCallable } from 'firebase/functions';
import { H2,H5} from "components/uicomponents/Typography";
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import {Navigate} from "react-router-dom";
import React,{useEffect, useState,useRef,useContext} from 'react'
import { Table,TableHead,TableRow,TableCell,TableBody,useTheme,Typography, CardHeader,CardContent,ListItem,ListItemIcon,ListItemText,List,Button,Backdrop,Grid,useMediaQuery,Box,styled,Card } from '@mui/material'
import { useNavigate } from 'react-router';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import toast from 'react-hot-toast';
import RefreshIcon from '@mui/icons-material/Refresh';

const db = getFirestore();
const functions = getFunctions();

const TopAreaWrapper = styled(Card)(({
    theme
  }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexWrap: "wrap",
    [theme.breakpoints.down(700)]: {
      "& h2": {
        paddingTop: "1rem"
      },
      "& img": {
        display: "none"
      }
    }
  }));


export default function UpdatedAccounts() {

    const theme = useTheme();
    const {isAuthenticated } = useContext(BudgetContextTwo)
    const downMd = useMediaQuery(theme.breakpoints.down('md'))
    const isDownLarge = useMediaQuery(theme.breakpoints.down('lg'));
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    const isUpSmall = useMediaQuery(theme.breakpoints.up('sm'))

    const selectedAccountIdRef = useRef(null)
    const finCustomerIdRef = useRef(null)
    const selectedAccountPositionRef = useRef(null)
  
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const [showLoading,setShowLoading] = useState(true)
    const [accountLineItemsArray, setAccountLineItemsArray] = useState([])
    const [localPremiumUser,setLocalPremiumUser] = useState(false)

    const navigate = useNavigate();

    const budgetMethodRef = useRef('')
   

    useEffect(() => {
        augustUpdateGetAccounts();

    },[])
    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])
    

      async function augustUpdateGetAccounts(){
        const topUserUid = getAuth().currentUser.uid

        let docRef = doc(db,'EuklesUsers',topUserUid)
        let userData = await getDoc(docRef).then(doc => {
          return {isPremiumUser: doc.data().isPremiumUser, finCustomerId: doc.data().finCustomerId,budgetMethod: doc.data().budgetMethod}
        }).catch(e => {
          return false
        })

        finCustomerIdRef.current = userData.finCustomerId
        budgetMethodRef.current = userData.budgetMethod
        setLocalPremiumUser(userData.isPremiumUser)
        

        if(userData.isPremiumUser){

          let collectionRef = collection(db,'EuklesUsersData',topUserUid,'Accounts')
          const accounts =  await getDocs(collectionRef).then(querySnapshot => {
            let localArray = []
            querySnapshot.forEach((doc) => {
              if(doc.data().isFinicity){
        
               
        
                let accountNickname = ''
                if(typeof doc.data().accountNickname !== 'undefined'){
                  accountNickname = doc.data().accountNickname
                }
                else {
                  accountNickname = doc.data().name
                }
                let accountNumberDisplay = ''
                if(typeof doc.data().accountNumberDisplay !== 'undefined'){
                  accountNumberDisplay = doc.data().accountNumberDisplay
                }
                let aggregationAttemptDate = ''
                if(typeof doc.data().aggregationAttemptDate !== 'undefined'){
                  aggregationAttemptDate = doc.data().aggregationAttemptDate
                }
        
                let aggregationStatusCode = ''
                if(typeof doc.data().aggregationStatusCode !== 'undefined'){
                  aggregationStatusCode = doc.data().aggregationStatusCode
                }
        
                let aggregationSuccessDate = ''
                if(typeof doc.data().aggregationSuccessDate !== 'undefined'){
                  aggregationSuccessDate = doc.data().aggregationSuccessDate
                }
        
                let balance = ''
                if(typeof doc.data().balance !== 'undefined'){
                  balance = doc.data().balance
                }
        
                let balanceDate = ''
                if(typeof doc.data().balanceDate !== 'undefined'){
                  balanceDate = doc.data().balanceDate
                }
                let createdDate = ''
                if(typeof doc.data().createdDate !== 'undefined'){
                  createdDate = doc.data().createdDate
                }
                let currency = ''
                if(typeof doc.data().currency !== 'undefined'){
                  currency = doc.data().currency
                }
                let detailCurrentBalance = ''
              
                let detailLastPaymentAmount = ''
                let detailLastPaymentDate = ''
                let detailPaymentDueDate = ''
                let detailPaymentMinAmount = ''
                let detailStatementCloseBalance = ''
        
                if(typeof doc.data().detail !== 'undefined'){
                  if(typeof doc.data().detail.availableBalanceAmount !== 'undefined'){
                    balance = doc.data().detail.availableBalanceAmount
                  }
                  if(typeof doc.data().detail.currentBalance !== 'undefined'){
                    detailCurrentBalance = doc.data().detail.currentBalance
                    //balance = detailCurrentBalance
                  }
        
                  if(typeof doc.data().detail.lastPaymentAmount !== 'undefined'){
                    detailLastPaymentAmount = doc.data().detail.lastPaymentAmount
                  }
        
                  if(typeof doc.data().detail.lastPaymentDate !== 'undefined'){
                    detailLastPaymentDate = doc.data().detail.lastPaymentDate
                  }
        
                  if(typeof doc.data().detail.paymentDueDate !== 'undefined'){
                    detailPaymentDueDate = doc.data().detail.paymentDueDate
                  }
        
                  if(typeof doc.data().detail.paymentMinAmount !== 'undefined'){
                    detailPaymentMinAmount = doc.data().detail.paymentMinAmount
                  }
        
                  if(typeof doc.data().detail.statementCloseBalance !== 'undefined'){
                    detailStatementCloseBalance = doc.data().detail.statementCloseBalance
                  }
        
        
                }
        
        
        
                let displayPosition = ''
                if(typeof doc.data().displayPosition !== 'undefined'){
                  displayPosition = doc.data().displayPosition
                }
                let accountId = ''
                if(typeof doc.data().accountId !== 'undefined'){
                  accountId = doc.data().accountId
                }
                let institutionId =  ''
                if(typeof doc.data().institutionId !== 'undefined'){
                  institutionId = doc.data().institutionId
                }
                let institutionLoginId = ''
                if(typeof doc.data().institutionLoginId !== 'undefined'){
                  institutionLoginId = doc.data().institutionLoginId
                }
        
                let isFinicity = true
                let lastTransactionDate = ''
                if(typeof doc.data().lastTransactionDate !== 'undefined'){
                  lastTransactionDate = doc.data().lastTransactionDate
                }
                let lastUpdatedDate = ''
                if(typeof doc.data().lastUpdatedDate !== 'undefined'){
                  lastUpdatedDate = doc.data().lastUpdatedDate
                }
                let marketSegment = ''
                if(typeof doc.data().marketSegment !== 'undefined'){
                  marketSegment = doc.data().marketSegment
                }
                let name = ''
                if(typeof doc.data().name !== 'undefined'){
                  name = doc.data().name
                }
                let number = ''
                if(typeof doc.data().number !== 'undefined'){
                  number = doc.data().number
                }
        
                let oldestTransactionDate = ''
                if(typeof doc.data().oldestTransactionDate !== 'undefined'){
                  oldestTransactionDate = doc.data().oldestTransactionDate
                }
        
                let status = ''
                if(typeof doc.data().status !== 'undefined'){
                  status = doc.data().status
                }
                let type = ''
                if(typeof doc.data().type !== 'undefined'){
                  type = doc.data().type
                }
        
                let hasPositions = false
        
                if(typeof doc.data().position !== 'undefined'){
                  let positionArray = doc.data().position
                  if(positionArray.length >= 2){
                    hasPositions = true
                  }
                }

                let icon = 'nil'
                let logo = 'nil'
                let tile = 'nil'
               
      
      
                if(typeof doc.data().icon !== 'undefined'){
                  icon = doc.data().icon
                }
      
                if(typeof doc.data().logo !== 'undefined'){
                 logo = doc.data().logo
                }
      
                if(typeof doc.data().tile !== 'undefined'){
                  tile = doc.data().tile
                }
                
        
                localArray.push({docId: doc.id, accountNickname: accountNickname, accountNumberDisplay: accountNumberDisplay, 
                  aggregationAttemptDate: aggregationAttemptDate, aggregationStatusCode: aggregationStatusCode,
                  aggregationSuccessDate: aggregationSuccessDate, balance: balance, balanceDate: balanceDate, 
                  createdDate: createdDate, currency:currency, detailCurrentBalance: detailCurrentBalance,
                detailLastPaymentAmount: detailLastPaymentAmount, detailLastPaymentDate: detailLastPaymentDate,
                 detailPaymentDueDate: detailPaymentDueDate, detailPaymentMinAmount: detailPaymentMinAmount,
                detailStatementCloseBalance: detailStatementCloseBalance, displayPosition: displayPosition, accountId: doc.id, id:doc.id, institutionId: institutionId, institutionLoginId: institutionLoginId, isFinicity: true, 
              lastTransactionDate: lastTransactionDate, lastUpdatedDate: lastUpdatedDate, marketSegment: marketSegment, name: name, number:number, oldestTransactionDate: oldestTransactionDate, status:status,
            type: type, hasPositions: hasPositions,icon: icon, logo: logo, tile:tile})
        
        
        
              }
              else {
                //localArray.push({docId: doc.id, balance: doc.data().balance, isFinicity: false,  name: doc.data().name, institutionId: doc.data().institutionId})
              }
             
            })
            return localArray
          }).catch(e => {
            return []
          })
        
          

          // for(let i = 0; i < accounts.length; i++){
          //   if(accounts[i].logo === 'nil'){
          //     uploadBranding(accounts[i])
          //   }
          // }

          if(accounts.length >= 1){
           sortAccounts(accounts)
           //uploadBranding(accounts)
          }
          else {
    
          }
        
        }
        else {
          setShowLoading(false);
        }


      
      }

      async function uploadBranding(account){

        

        const topUserUid = getAuth().currentUser.uid;
   
        let logo = 'nil'
        let icon = 'nil'
        let tile = 'nil'

      
        let getBranding = httpsCallable(functions,'retrieveInstitutionBranding')
      
        let brandingObj = await getBranding({institutionId: account.institutionId}).then((res) => {
                 
          
             return res.data.branding;
         }).catch((error) => {
          
             return false;
         })
      
        
      
         if(brandingObj !== false){
          if(typeof brandingObj.logo !== 'undefined'){
            logo = brandingObj.logo
          }
      
          if(typeof brandingObj.icon !== 'undefined'){
           icon = brandingObj.icon
          }
      
          if(typeof brandingObj.tile !== 'undefined'){
            tile = brandingObj.tile
          }
    
    
       let accountsRef = doc(db,'EuklesUsersData',topUserUid,'Accounts',String(account.docId))
          
       await updateDoc(accountsRef,{logo:logo,icon:icon,tile:tile})
         }
      }
    

      function camelCaseForce(title){
      
        let titleArray = title.split(" ")
        for(let i = 0; i < titleArray.length; i++){
          
          titleArray[i] = titleArray[i].toLowerCase()
          titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].substring(1)
        }
      
        titleArray = titleArray.join(" ")
        if(titleArray === 'Creditcard'){
          titleArray = 'Credit Card'
        }
        return titleArray
       
      }

      function checkScreenSize(){
        let m = 0;
      
      
        if(isDownLarge && isUpSmall){
          m = 60;
        }
      
        if(isDownMedium){
          m = 0;
        }
        return m;
      }

      async function getAccountsUpdated(){
        setShowLoading(true);
       const userUid = getAuth().currentUser.uid;
        let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');
       
      
          let finAccountsArray = await retrieveBalances({customerId: finCustomerIdRef.current}).then(function(result) {
        
            return result.data.accounts;
          }).catch(function(error){
        
            return false;
          })
       
 

          //checkAccountStatus(finAccountsArray)

          let accountsRef = collection(db,'EuklesUsersData',userUid,'Accounts')
          let q = query(accountsRef,where("isCashFlowAccount","==",true))

          let cashFlowAccountId = await getDocs(q).then(querySnapshot => {
           
            let id = ''
            //should only be one entry
           
              querySnapshot.forEach(doc => { 
           
                id = doc.id
              })
            
            return id
          }).catch(e => {
            return false
          })
    
         
    
          if(finAccountsArray === false || finAccountsArray.length === 0){
            //error

          }
          else {
            
            for(let i = 0; i < finAccountsArray.length; i++){
                if(finAccountsArray[i].id !== cashFlowAccountId){
                    let myObject = {...finAccountsArray[i], isFinicity:true}
                    let accountsDoc = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
                    await updateDoc(accountsDoc,myObject)
                    //await uploadBranding(finAccountsArray[i])
                }
                else {
                    let myObjectAlt = {...finAccountsArray[i], isFinicity:true, isCashFlowAccount:true}
                    let accountsDocRef = doc(db,'EuklesUsersData',userUid,'Accounts',String(finAccountsArray[i].id))
                    await updateDoc(accountsDocRef,myObjectAlt)
                   // await uploadBranding(finAccountsArray[i])
                }
             
          }
        }
    
      

    augustUpdateGetAccounts();
        
          }

      async function handleTopPartClick(){
        navigate('../pricing',{state:{comingFrom:'budgethome'}});

       }

      async function sortAccounts(accounts){

        

        let assets = accounts.filter(obj => obj.balance >= 0.00)
        let liabilities = accounts.filter(obj => obj.balance < 0)
      
        let assetsGroupedByInstitution = []
        for(let i = 0; i < assets.length; i++){
          let existingIndex = assetsGroupedByInstitution.findIndex(obj => obj.institutionId === assets[i].institutionId)
          if(existingIndex === -1){
           let obj = {parentInstitution: assets[i].institutionId, logo: assets[i].logo, icon: assets[i].icon,tile: assets[i].tile, isFinicity: assets[i].isFinicity, institutionId: assets[i].institutionId, accountsArray:[assets[i]]}
           assetsGroupedByInstitution.push(obj)
          }
          else {
            assetsGroupedByInstitution[existingIndex].accountsArray.push(assets[i])
          }
        }
      
      
        let liabilitiesGroupedByInstitution = []
        for(let i = 0; i < liabilities.length; i++){
          let existingIndex = liabilitiesGroupedByInstitution.findIndex(obj => obj.institutionId === liabilities[i].institutionId)
          if(existingIndex === -1){
           let obj = {parentInstitution: liabilities[i].institutionId, logo: liabilities[i].logo, icon:liabilities[i].icon,tile:liabilities[i].tile, isFinicity: liabilities[i].isFinicity, institutionId: liabilities[i].institutionId, accountsArray:[liabilities[i]]}
           liabilitiesGroupedByInstitution.push(obj)
          }
          else {
            liabilitiesGroupedByInstitution[existingIndex].accountsArray.push(liabilities[i])
          }
        }
      
      // for(let i = 0; i < assetsGroupedByInstitution.length; i++){
      //   if(assetsGroupedByInstitution[i].isFinicity){
      
        
      //   let getBranding = httpsCallable(functions,'retrieveInstitutionBranding')
      
      //   let brandingObj = await getBranding({institutionId: assetsGroupedByInstitution[i].institutionId}).then((res) => {
                 
          
      //        return res.data.branding;
      //    }).catch((error) => {
      //        console.log(error);
      //        return false;
      //    })
      
        
      
      //    if(brandingObj !== false){
      //     if(typeof brandingObj.logo !== 'undefined'){
      //       assetsGroupedByInstitution[i].logo = brandingObj.logo
      //     }
      
      //     if(typeof brandingObj.icon !== 'undefined'){
      //       assetsGroupedByInstitution[i].icon = brandingObj.icon
      //     }
      
      //     if(typeof brandingObj.tile !== 'undefined'){
      //       assetsGroupedByInstitution[i].tile = brandingObj.tile
      //     }
      
      
      //    }
      //   }
      
      
      
      
      // }
      
      // for(let i = 0; i < liabilitiesGroupedByInstitution.length; i++){
        
      //   if(liabilitiesGroupedByInstitution[i].isFinicity){
      
        
      
      //   let getBranding = httpsCallable(functions,'retrieveInstitutionBranding')
      
      //   let brandingObj = await getBranding({institutionId: liabilitiesGroupedByInstitution[i].parentInstitution}).then((res) => {
                 
          
      //        return res.data.branding;
      //    }).catch((error) => {
      //        console.log(error);
      //        return false;
      //    })
      
      //    if(brandingObj !== false){
      //     if(typeof brandingObj.logo !== 'undefined'){
      //       liabilitiesGroupedByInstitution[i].logo = brandingObj.logo
      //     }
      
      //     if(typeof brandingObj.icon !== 'undefined'){
      //       liabilitiesGroupedByInstitution[i].icon = brandingObj.icon
      //     }
      
      //     if(typeof brandingObj.tile !== 'undefined'){
      //       liabilitiesGroupedByInstitution[i].tile = brandingObj.tile
      //     }
      
      
      //    }
      
      
      //   }
      
      
      // }
      
      let allAccounts = []
      assetsGroupedByInstitution.forEach(obj => {
        allAccounts.push(obj)
      })
      liabilitiesGroupedByInstitution.forEach(obj => {
        allAccounts.push(obj)
      })

     

      let lineItemsArray = []

      for(let i = 0; i < assetsGroupedByInstitution.length;i++){
        let accountsArray = assetsGroupedByInstitution[i].accountsArray
        for(let b = 0; b < accountsArray.length; b++){
          lineItemsArray.push({...accountsArray[b],logo:assetsGroupedByInstitution[i].logo, icon: assetsGroupedByInstitution[i].icon})
        }
      }

      for(let i = 0; i < liabilitiesGroupedByInstitution.length;i++){
        let accountsArray = liabilitiesGroupedByInstitution[i].accountsArray
        for(let b = 0; b < accountsArray.length; b++){
          lineItemsArray.push({...accountsArray[b],logo: liabilitiesGroupedByInstitution[i].logo, icon: liabilitiesGroupedByInstitution[i].icon})
        }
      }

      try{
        lineItemsArray.sort((a,b) => a.institutionId - b.institutionId)
      }catch(e){
        console.log(e)
      }
    
      setAccountLineItemsArray(previousState => [...lineItemsArray])
      // console.log(lineItemsArray[0])
      // arrayOfObjects.sort((a, b) => a.age - b.age);


      setShowLoading(false)
        
      }
  

function handleCancel(){
  setShowConfirmDelete(false);
}

async function handleDelete(){
  setShowConfirmDelete(false)
setShowLoading(true);

//  let topUserUid = getAuth().currentUser.uid;

//  let docRef = doc(db,'EuklesUsersData',topUserUid,'Accounts',String(selectedAccountIdRef.current))

//  await deleteDoc(docRef).then(res => {
//   return true;
//  }).catch(e => {
//   return false;
//  })

let currentArray = accountLineItemsArray
currentArray.splice(selectedAccountPositionRef.current,1)
setAccountLineItemsArray(previousState => [...currentArray])

let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
let urlResult = await retrieveUrl({customerId: finCustomerIdRef.current}).then(function(result){


    return result;
}).catch(function(error){

    return false;
})


navigate('../finicityasawb',{state:{comingFrom: 'budgethome',urlToUse:  urlResult.data.theUrl,newUser:false,finCustomerId:finCustomerIdRef.current}});

 setShowLoading(false);
}

function handleDeleteAccountClick(account,position){

  //account.id
  selectedAccountIdRef.current = account.docId
  selectedAccountPositionRef.current = position
  setShowConfirmDelete(true);
}


async function handleFixInstitution(item){
  
  setShowLoading(true);
  let retrieveUrl = httpsCallable(functions,'generateFixCustomUrl');
  let urlResult = await retrieveUrl({customerId: finCustomerIdRef.current, institutionLoginId: item.institutionLoginId}).then(function(result){

      
      return result;
  }).catch(function(error){
      console.log('error generating fin url');
      return false;
  })

  if(urlResult.data.theUrl === 'error getting url'){
   
    toast.error('Budget Even has encountered an error')
    return false;
  }
  else {
    
 
    let theUrl = String(urlResult.data.theUrl);

    if(budgetMethodRef.current === 'Semi-Auto'){
      navigate('../finicityasawb',{state:{comingFrom: 'budgethome',urlToUse: theUrl,newUser:false,finCustomerId:finCustomerIdRef.current}});
    }
    else {
      navigate('../finicityaawb',{state:{comingFrom: 'budgethome',urlToUse: theUrl,newUser:false,finCustomerId:finCustomerIdRef.current}});
    }
  


  
    return true;
    
  }

}

 
      
  if(isAuthenticated){
    if(showLoading){
      return (
        <Backdrop open={showLoading} style={{zIndex:1400,backgroundColor: theme.palette.primary.background}}>
        <Grid container direction='column'  justifyContent='center' alignItems='center'>
          <Grid item>
          <Ellipsis color={theme.palette.primary.main} />
          </Grid>
          <Grid item style={{paddingTop:5}}>
            <Typography style={{color:'white', fontWeight:'bold'}}></Typography>
          </Grid>
        </Grid>
       
       
      </Backdrop> 
      )
    }
    else {
      if(localPremiumUser){
        return (
          
          <Box pt={2} pb={10} style={{marginLeft: checkScreenSize()}}>
          <TopAreaWrapper>
          <Box style={{padding:25}}>
                  <H2 style={{color: theme.palette.primary.dialogBackground}} paddingLeft={3} paddingBottom={1}>
                    Account details
                  </H2>
              </Box>

              {showConfirmDelete ? <ConfirmDeleteModal handleCancel={handleCancel} handleDelete={handleDelete} /> : null}
          </TopAreaWrapper>
       
          
             <Button onClick={() => getAccountsUpdated()} startIcon={<RefreshIcon />} style={{marginTop:20}}>Refresh Accounts</Button>
            <Table style={{width: isDownMedium ? '100%' : 'auto',   minWidth: isDownMedium ? 300 : 500, marginTop:5}}>
          <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
            <TableRow>
            <TableCell  style={{color:'white', fontWeight:'bold'}}>Institution</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Account</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Balance</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Last Update</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Due Date</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Last Payment Amount</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>View Transactions</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Update</TableCell>
            <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Remove</TableCell>
            </TableRow>
      
          </TableHead>
          <TableBody style={{backgroundColor:'white', }}>
  
  
          {accountLineItemsArray.map((item,i) => {
                          return(
                              <TableRow key={i.toString()}>
                                       <TableCell  >
                                        {item.icon === 'nil' ? null :    <img src={item.icon} style={{height:40,width:40}}></img>}
                                
                                  </TableCell>
                                  <TableCell  >
                                  <Typography  style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14,maxWidth:200, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
                                  </TableCell>
                               
                                  <TableCell >
                                 
                                      
                                        <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.balance)}</Typography>
                                    
                                       
                                       
                                  
                                  </TableCell>

                                  <TableCell >
                                 
                                      

                                 <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{formatTransactionDate(item.aggregationSuccessDate)}</Typography>
                                
                                
                           
                           </TableCell>
  
                                  <TableCell >
                                  {/* detailCurrentBalance: detailCurrentBalance,
                detailLastPaymentAmount: detailLastPaymentAmount, detailLastPaymentDate: detailLastPaymentDate,
                 detailPaymentDueDate: detailPaymentDueDate, detailPaymentMinAmount: detailPaymentMinAmount, */}
                                 
                                 <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.detailPaymentDueDate === '' ? 'n/a' : formatTransactionDate(item.detailPaymentDueDate)}</Typography>
                           
                           </TableCell>
  
                           <TableCell >
                                 
                                 
                                 <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >{item.detailLastPaymentAmount === '' ? 'n/a' :new Intl.NumberFormat('us-US',{style:'currency',currency:'usd'}).format(item.detailLastPaymentAmount)} </Typography>
                           
                           </TableCell>
  
                                  <TableCell >
                                 
                              
                                  <Typography onClick={() => navigate('/dashboard/accounttransactions',{state:{accountId: item.accountId,icon:item.icon}})} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >View Transactions</Typography>
                                </TableCell>
                              
                                <TableCell >
                                 
                              
                                 <Typography onClick={() => handleFixInstitution(item)} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >Update Credentials</Typography>
                               </TableCell>

                               <TableCell >
                                 
                              
                                 <Typography onClick={() => handleDeleteAccountClick(item,i)} style={{textAlign:'center',cursor:'pointer',color: theme.palette.primary.blackcoral.blackcoral,fontSize: 14, fontWeight:'600'}} >Remove Account</Typography>
                               </TableCell>
                              </TableRow>
                          )
                      })}
          </TableBody>
          </Table>
  
  
  
  
  
          <div style={{width:'100%', height:200}}>
                    <Typography style={{color:'transparent'}}>Hello</Typography>
                  </div>
     
      
                
      
            
  
               
         
         </Box>
        )
      }
      else {
        return (


<Grid container direction='row' justifyContent='center' style={{paddingTop:25}}>
<Card 
sx={{
width: downMd ? 400 : 800


}}
>
<CardHeader title='Accounts all in One Place' titleTypographyProps={{color:'white', fontWeight:'bold', textAlign:'center'}} sx={{backgroundColor: theme.palette.primary.dialogBackground}} />
<CardContent style={{alignContent:'center', justifyContent:'center', alignItems:'center', display:'flex', flexDirection:'column'}}>

<Grid container direction='row' justifyContent='center'>

<Grid item>
<CurrencyExchangeIcon
    sx={{
      fontSize: 100,
      marginBottom: 2,
      color: theme.palette.success.main
    }}
  />
</Grid>


</Grid>


<Grid container direction='row' justifyContent='center' alignContent='center' alignItems='center' style={{width:400}}>

<Grid item md={12} lg={12}>
<List>
      <ListItem>
          <ListItemIcon>
              <LooksOneIcon style={{color: theme.palette.secondary.yellow}} />
          </ListItemIcon>
          <ListItemText sx={{color: theme.palette.text.disabled, fontWeight:'bold'}}
          primary={<H5>Connect your credit cards, checking, savings, and more!</H5>}
       
          
          />
      </ListItem>
      <ListItem>
          <ListItemIcon>
              <LooksTwoIcon style={{color: theme.palette.secondary.yellow}} />
          </ListItemIcon>
          <ListItemText sx={{color: theme.palette.text.disabled, fontWeight:'bold'}}
          primary={<H5>View recent transactions, payment due dates, and balances.</H5>}
     
          
          />
      </ListItem>

      <ListItem>
          <ListItemIcon>
              <Looks3Icon style={{color: theme.palette.secondary.yellow}} />
          </ListItemIcon>
          <ListItemText sx={{color: theme.palette.text.disabled, fontWeight:'bold'}}
          primary={<H5>Keep an eye on all of your accounts from a single home page.</H5>}
      
          
          />
      </ListItem>
  </List>
</Grid>


</Grid>

<Button variant='contained' startIcon={<AccountBalanceIcon />}  
onClick={() => handleTopPartClick()} 
style={{color:'white',backgroundColor: theme.palette.primary.dialogBackground, fontWeight:'bold', marginRight:15}}>Connect Account</Button>


</CardContent>
</Card>
</Grid>
        )
      }
    }
  }else {
    return(
      <Navigate to='/' replace={true} />
    )
  }
   
   
}
