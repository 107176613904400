
import AutoCard from "../OptionsCards/AutoCard";
import BottomSpacer from "components/uicomponents/BottomSpacer";
import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import {doc,getFirestore,updateDoc, setDoc } from "firebase/firestore";
import { getAnalytics,logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getCurrentMonthAndYearString } from "utils/Helpers";
import { Grid,useTheme,Button} from '@mui/material';
import { Helmet } from "react-helmet";
import { H3} from "components/uicomponents/Typography";
import ManualCard from "../OptionsCards/ManualCard";
import OnBoardingMenu from "../MenuPopover/OnBoardingMenu";
import './onboardingstyles.css'
import React,{useState,useEffect,useRef} from 'react'
import SemiAutoCard from "../OptionsCards/SemiAutoCard";
import { useNavigate} from "react-router";
import { uploadError } from "utils/FirebaseAnalyticsMethods";




  const db = getFirestore();
  

export default function GetStarted (){

 
  const navigate = useNavigate();
    const selectedOptionRef = useRef('Manual')
    const theme = useTheme();
    const [value, setValue] = useState('Auto');
   
    
    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])

    useEffect(() => {
      
      updateStep();
      }, []);


      function GoogleConversion() {
        return (
          <div>
            {/* Your other component content */}
      
            {/* Add the script using Helmet */}
            <Helmet>
              <script>
                {`
                  gtag('event', 'conversion', {'send_to': 'AW-939462200/KrDdCOj0kocZELic_L8D'});
                `}
              </script>
            </Helmet>
          </div>
        );
      }

  

  


      async function handleGoManual(){
        
        try{
          selectedOptionRef.current = 'Manual'
          let uid = getAuth().currentUser.uid;
      
          let monthYear = getCurrentMonthAndYearString();
  
          const analytics = getAnalytics();
          logEvent(analytics,'bb_webUserSelectedManual')
    
       
    
    
          let mArray = [monthYear]
          let monthDocRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
          await setDoc(monthDocRef,{mArray})
   
          let userDocRef = doc(db,'EuklesUsers',uid)
          await updateDoc(userDocRef,{
            onBoardingSelection: 'Manual',
            budgetMethod: 'Manual',
            isPremiumUser:false,
            finCustomerId:'nil',
            noCashFlowSet: true,
            queryMonth: monthYear
          })
        
  
          let incomeDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Income')
          await setDoc(incomeDocRef,{
            ['Paychecks']: {
              plannedValue:0.00,
              spentValue:0.00,
              podName:'Income'
          }
          })
    
          let savingsDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Savings')
          await setDoc(savingsDocRef,{
            ['Savings']: {
              plannedValue:0.00,
              spentValue:0.00,
              podName:'Savings'
          }
          })
  
          let foodDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Food')
       
            await setDoc(foodDocRef,{
              ['Groceries']: {
                plannedValue:0.00,
                spentValue:0.00,
                podName:'Food'
            },
            ['Dining Out']: {
              plannedValue:0.00,
              spentValue:0.00,
              podName:'Food'
          }
            })
  
            let uidUpdate = doc(db,'EuklesUsersData',uid)
            await updateDoc(uidUpdate,{userUid:uid})
          
            
    
           
            navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
        }catch(e){
          uploadError(e.message,'GetStarted, handleGoManual')
        }
  
  
      
  
      }
    
      function handelGoSemiAuto(){
        navigate('../onboardcheckout',{state:{comingFrom: 'OnBoarding', planType:'Semi-Auto'}})
      
      }
      function handleGoFullAuto(){
        navigate('../onboardcheckout',{state:{comingFrom: 'OnBoarding', planType:'Auto'}})
      }

      async function handleValue(value){

        setValue(value);
    
     
      }

      async function updateStep(){
       
        let uid = getAuth().currentUser.uid
        let docRef = doc(db,'EuklesUsers',uid)
        await updateDoc(docRef,{currentStep: 1})
      }



        return (
         <Grid container direction="column" justifyContent='center' alignContent='center' mb={25}>
          <div style={{position:'absolute',top:15,right:25}}>
            <OnBoardingMenu navigate={navigate} />
          </div>

          <Grid container direction='row' justifyContent='center' mt={5}>
          <Grid item>
          <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
          </Grid>
          
          </Grid>
      

          <Grid container direction='row' justifyContent='center'>
          <Grid item>
          <H3 style={{maxWidth:400,marginBottom:50,fontWeight:'bold', textAlign:'center',color:theme.palette.primary.fiverGrayText}}>Select an Option to Continue</H3>
          </Grid>
          </Grid>

        
   
      
    
      
    
       
          <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <Button onClick={() => handleValue('Auto')} style={{fontWeight:'bold',width:150}} variant={value === 'Auto' ? 'contained' : 'outlined'}>Auto</Button>
                <Button onClick={() => handleValue('Semi-Auto')} style={{fontWeight:'bold',width:150}} variant={value === 'Semi-Auto' ? 'contained' : 'outlined'}>Semi-Auto</Button>
                <Button onClick={() => handleValue('Manual')} style={{fontWeight:'bold',width:150}} variant={value === 'Manual' ? 'contained' : 'outlined'}>Manual</Button>
          </div>
       
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:15}}>
          
          {value === 'Auto' ? <AutoCard handlePurchase={handleGoFullAuto}  /> : null}
          {value === 'Semi-Auto' ? <SemiAutoCard  handlePurchase={handelGoSemiAuto}/> : null}
          {value === 'Manual' ? <ManualCard handlePurchase={handleGoManual} /> : null}

        </div>
       
<GoogleConversion />
 
    <BottomSpacer />
      
      </Grid>
      )
      
     
      
        
    
 
  
  
}