import { collection, doc, getFirestore, setDoc, getDoc, getDocs,  addDoc,} from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getTodaysEpoch} from "./Helpers";




const db = getFirestore();



export async function addSingleTrackingRule(transaction,podTitle,lineItemDescription,transactionCategory){
  let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
    transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
    const userUid = getAuth().currentUser.uid;


    let docRef = collection(db,'EuklesUsersData',userUid, 'TrackingRules')
   const addRule = await addDoc(docRef,trackingRule).then(res => {
      return true;
    }).catch(e => {
      return false;
    })
    return addRule;
}

export async function getBudgetPodsByMonth(monthToUse){
  let uid = getAuth().currentUser.uid;
  //let monthToUse = getCurrentMonthAndYearString();
let podRef = collection(db,'EuklesUsersData',uid,monthToUse)
let podsArray = await getDocs(podRef).then(function(querySnapshot){
    if(querySnapshot.size <= 0){
        //show no budget for this month
        return false;
    }
    else if(querySnapshot.size > 0){

        let podArray = [];
       
        querySnapshot.forEach(function(doc){
           
            let localList = []
            let keys = Object.keys(doc.data());
            let fullData = doc.data();
         
     
            
            //*********LINE ITEM MAP********* */
            keys.map((name, i) => {
              let p = Number(fullData[keys[i]].plannedValue)
              p = Number(p.toFixed(2));
              let s =  Number(fullData[keys[i]].spentValue)
              s = Number(s.toFixed(2));
           
                let lineItem = {
                    description: keys[i],
                    plannedValue: p,
                    spentValue: s,
                    podName: doc.id,
                    checked: false
                }
                localList.push(lineItem)
                return null;
            })
         //************END LINE ITEM MAP************* */

         
            let budgetObject = {
                title: doc.id,
                data: localList,
                checked: false
            }
            
               
                    podArray.push(budgetObject)
        

        })
        return podArray;
    }
}).catch((e) => {
  console.log('error ',e);
  return [];
})

return podsArray
}


export async function getSavingsGoal(){
  const userUid = getAuth().currentUser.uid;
  let docRef = doc(db,'EuklesUsersData',userUid, 'MonthlySavingsGoal', 'goal');
  let theGoal= await getDoc(docRef).then((doc) => {

    return {error:false, value: doc.data().value}
  }).catch((err) => {
   
    return {error:true, value: 0.00}
  })

  if(!theGoal.error){
    return theGoal.value
    
  }
  else {
    return 100

  }
}

export async function getBudgetMonths(){
  const userUid = getAuth().currentUser.uid;
  const monthsDoc = doc(db,'EuklesUsersData',userUid,'MonthsWithBudgets','months')

  let budgetMonthsArray = await getDoc(monthsDoc).then(function(doc){
    let theArray = doc.data().mArray;
    
    return theArray;
  }).catch((error) => {
    //show alert and reload
    return [];
});
return budgetMonthsArray;
}


export async function uploadAllTransactionsToBeTracked(transactionsWithDetailsArray,podName,lineItemDescription,selectedMonth){
  try{
    const userUid = getAuth().currentUser.uid;
    const db = getFirestore();
    let todayEpoch = getTodaysEpoch();
 
    
    for(let i = 0; i < transactionsWithDetailsArray.length; i++){
       
      let transaction = transactionsWithDetailsArray[i]
    
      //let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
      let trackingMonthAndYear = selectedMonth
    
      let trackingStatus = podName === 'Delete' ? 'Deleted' : 'Tracked'
    
    
       let myObject = {...transaction,podName: podName, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}
  
       let docRef = doc(db,'EuklesUsersData',userUid, 'Transactions', String(transaction.id));

       await setDoc(docRef,myObject).then(res => {
        return true;
       }).catch(e => {
        return false;
       })
    
         
      
     }
    
     return true;
  }catch(e){
    console.log(e)
    return false;
  }



}

export async function updateSpentValues(spentValuesArray,podName,lineItemDescription){

}

















export function isLastCharAlphanumeric(str) {
    // Regular expression that matches any letter or number at the end of a string
    const regex = /[a-zA-Z0-9]$/;
  
    // Test if the last character matches the pattern
    return regex.test(str);
  }
  

