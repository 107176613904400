

import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import { Button,useTheme } from "@mui/material";
import { doc,getFirestore,updateDoc } from "firebase/firestore";
import { getAnalytics,logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import {H3} from "components/uicomponents/Typography";
import './onboardingstyles.css'
import React,{useState,useEffect} from 'react'
import toast from "react-hot-toast";
import { useNavigate } from "react-router";


  const db = getFirestore();
  

export default function ErrorGetStarted (){
    
  const theme = useTheme();    
    const navigate = useNavigate();   
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
       
        setIsVisible(true);
        
        updateAnalytics();
      updateStep();
      displayToast();
      }, []);

      function displayToast(){
        toast.error("Oops","Looks like an error has occurred.  Please try again")
      }

      async function updateStep(){
        let uid = getAuth().currentUser.uid
        let docRef = doc(db,'EuklesUsers',uid)
        await updateDoc(docRef,{currentStep: 1})
      }

      async function updateAnalytics(){
        const analytics = getAnalytics();
        logEvent(analytics,'bb_web_error_get_started')
      }


        return (
            <div className="center-container">


          
       <div className={`fade-in-container ${isVisible ? 'visible' : ''}`}>
            <div>
            <img style={{alignSelf:'center'}} src={BudgetEvenLogo}  alt="Logo" />
            </div>

      
        
         <H3 style={{maxWidth:400,marginBottom:50,fontWeight:'bold', textAlign:'center',color:theme.palette.primary.fiverGrayText}}>Let's connect your credit card and checking accounts so Budget Even can go to work for you.</H3>
         

          <Button onClick={() => navigate('/createclient')} style={{margin: '0 auto', display: "flex"}} variant='contained'>Let's Go!</Button>
         
        </div>
   

          </div>
        )
      
     
      
        
    
 
  
  
}