

import BottomSpacer from 'components/uicomponents/BottomSpacer';
import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import {  doc, getFirestore, setDoc, getDoc,updateDoc} from "firebase/firestore";
import { Ellipsis} from 'react-spinners-css'
import { getAuth} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Grid, Backdrop,useMediaQuery,Table, TableBody, TableCell, TableHead, TableRow,useTheme, Typography,Button} from '@mui/material';
import {H3} from "components/uicomponents/Typography";
import NoAccountsConnected from "../ErrorCards/NoAccountsConnected";
import React,{useEffect,useState} from 'react'
import RiveMainLoading from 'rivefiles/RiveMainLoading';
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";
import { uploadError } from 'utils/FirebaseAnalyticsMethods';
import RiveSmallCircleLoading from 'rivefiles/RiveSmallCircleLoading';



const db = getFirestore();
const functions = getFunctions();
//const analytics = getAnalytics();

export default function AutoFinicityLoading() {



    const theme = useTheme()
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    const uid = getAuth().currentUser.uid
    const navigate = useNavigate();
    const location = useLocation();
    const finCustomerId = location.state.finCustomerId
    const url = location.state.url

 
    const [allAccounts, setAllAccounts] = useState([])
    const [budgetAccounts, setBudgetAccounts] = useState([]) 
    const [checkingAccounts, setCheckingAccounts] = useState([])
    const [loading, setLoading] = useState(true)  
    const [mainLoadingText,setMainLoadingText] = useState('Gathering your accounts...')
    const [primaryCheckingAccountId, setPrimaryCheckingAccountId] = useState('')
    const [primaryCheckingAccountBalance, setPrimaryCheckingAccountBalance] = useState(0.00)
    const [showNoCheckingError, setShowNoCheckingError] = useState(false)
    const [showNoCardError, setShowNoCardError] = useState(false)
    const [showNoCheckingOrCreditCardError,setShowNoCheckingOrCreditCardError] = useState(false)  



 
    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])
    
  
useEffect(() => {
 updateLocalStep();
},[])

async function updateLocalStep(){
  let uid = getAuth().currentUser.uid
  let docRef = doc(db,'EuklesUsers',uid)
  await updateDoc(docRef,{currentStep: 'autoFinicityLoading',autoCurrentStep:'autoFinicityLoading'})
}

useEffect(() => {
 
  getAccountsUpdated();

},[])


function camelCaseForce(title){
try{
  let titleArray = title.split(" ")
  for(let i = 0; i < titleArray.length; i++){
    
    titleArray[i] = titleArray[i].toLowerCase()
    titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].substring(1)
  }

  titleArray = titleArray.join(" ")
  if(titleArray === 'Creditcard'){
    titleArray = 'Credit Card'
  }
  if(titleArray === 'Ira'){
    titleArray = 'IRA'
  }
  return titleArray
}catch(e){
  uploadError(e.message,`AutoFinicityLoading.js,camelCaseForce ${title}`)
}

}

async function checkIfSubscribedThroughStripe(){
  try{
    let docRef = doc(db,'EuklesUsers',uid)
    let method = await getDoc(docRef).then(doc => {
        return doc.data().subscriptionMethod
    })
    return method
  }catch(e){
    uploadError(e.message,'AutoFinicityLoading.js,checkIfSubscribedThroughStripe')
  }

}


function handleSelectPrimaryCheckingAccount(account){

  setPrimaryCheckingAccountId(account.id)
  setPrimaryCheckingAccountBalance(account.balance)
}

function handleGoBackToConnectingAccounts(){

  setShowNoCardError(false);
  setShowNoCheckingError(false);
  setShowNoCheckingOrCreditCardError(false);
 navigate('/finicityconnect',{state:{url:url,finCustomerId:finCustomerId}})
}


function handleNavigateHome(){
  navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
}

async function getAccountsUpdated(){

  try{
    let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');

    let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
  
      return result.data.accounts;
    }).catch(function(error){
  
      return false;
    })


    if(finAccountsArray === false || finAccountsArray.length === 0){
      //error
 
    }
    else {

      let hasChecking = false
      let hasCreditCard = false

      for(let i = 0; i < finAccountsArray.length; i++){
        if(finAccountsArray[i].type === 'checking'){
          hasChecking = true
        }
        if(finAccountsArray[i].type === 'creditCard'){
          hasCreditCard = true
        }
      }


      if(!hasChecking || !hasCreditCard){

        if(!hasChecking && !hasCreditCard){
         setShowNoCheckingOrCreditCardError(true)
  
        }
        else if(!hasChecking && hasCreditCard){
          setShowNoCheckingError(true)
    
        }

        else if(hasChecking && !hasCreditCard){
          setShowNoCardError(true)

        }

      }

      setMainLoadingText('Obtaining details...')

      for(let i = 0; i < finAccountsArray.length; i++){
          let getInstitutionName = httpsCallable(functions,'retrieveInstitutionName')
 
        let realName = await getInstitutionName({institutionId: finAccountsArray[i].institutionId}).then((res) => {
             
           
             return res.data.name[0]
         }).catch((error) => {
       
             return '';
         })

         finAccountsArray[i].institutionName = realName
        
      }

      setAllAccounts(previousState => [...finAccountsArray])

      for(let i = 0; i < finAccountsArray.length; i++){
        let myObject = {...finAccountsArray[i], isFinicity:true}
        let accountDocRef = doc(db,'EuklesUsersData',uid, 'Accounts',finAccountsArray[i].id)
        await setDoc(accountDocRef,myObject)
     
    }

      let filteredBudgetAccounts = []

        let currentCheckingArray = checkingAccounts
        for(let i = 0; i < finAccountsArray.length; i++){
        
          if(finAccountsArray[i].type.toLowerCase() === 'checking'){
            let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
            accountTransactionsArray:[]}
            filteredBudgetAccounts.push(obj)
              currentCheckingArray.push(finAccountsArray[i])
              
          }
          if(finAccountsArray[i].type.toLowerCase() === 'creditcard'){
            let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
              accountTransactionsArray:[]}
              filteredBudgetAccounts.push(obj)
          }

          if(finAccountsArray[i].type.toLowerCase() === 'savings'){
            let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
              accountTransactionsArray:[]}
              filteredBudgetAccounts.push(obj)
          }
        }

        setCheckingAccounts(previousState => [...currentCheckingArray])
        if(currentCheckingArray.length === 1){
          setPrimaryCheckingAccountId(currentCheckingArray[0].id)
          setPrimaryCheckingAccountBalance(currentCheckingArray[0].balance)
        }
setBudgetAccounts(previousState => [...filteredBudgetAccounts])          


        }

        
     
 
      setLoading(false)
  }catch(e){
    uploadError(e.message,`AutoFinicityLoading.js,getAccountsUpdated ${finCustomerId}`)
  }

   
     
      }



    
if(loading){
  return(
    <div style={{display:'flex',flexDirection:'column', flex:1, justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
                       <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
 <RiveMainLoading mainText={mainLoadingText}/>

    </div>
 

  )
}
else {

    if(showNoCheckingError){
      return (
        <NoAccountsConnected checkIfSubscribedThroughStripe={checkIfSubscribedThroughStripe} handleNavigateHome={handleNavigateHome} handleContinue={handleGoBackToConnectingAccounts} message='Looks like you still need to connect a checking account.  Budget Even works best with your primary checking account and credit or debit card connected.'/>
      )
   
      
    }


    if(showNoCardError){
      return (
        <NoAccountsConnected checkIfSubscribedThroughStripe={checkIfSubscribedThroughStripe} handleNavigateHome={handleNavigateHome} handleContinue={handleGoBackToConnectingAccounts} message='Looks like you still need to connect a credit or debit card.  Budget Even works best with your primary checking account and credit or debit card connected.'/>
      )
      
    }

    if(showNoCheckingOrCreditCardError){
      return (
        <NoAccountsConnected checkIfSubscribedThroughStripe={checkIfSubscribedThroughStripe} handleNavigateHome={handleNavigateHome} handleContinue={handleGoBackToConnectingAccounts} message='Looks like you still need to connect a checking account and credit or debit card.  Budget Even works best with your primary checking account and credit or debit card connected.'/>
      )
    }



    else {
        return (
            <div style={{display:'flex',flexDirection:'column', flex:1, justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
                       <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
      
        
            <H3 style={{marginBottom:10,marginTop:25, textAlign:'center', color: theme.palette.primary.navy, fontWeight:'bold'}}>{budgetAccounts.length > 1 ?  'The accounts below will be used to create your budget, cash flow projection, and net worth balance sheet. ' : 'The following account will be used to create your budget, cash flow projection, and net worth balance sheet. '}  </H3>
          
    
            {checkingAccounts.length > 1 ? <H3 style={{marginBottom:10,marginTop:25, textAlign:'center', color: theme.palette.primary.navy, fontStyle:'italic', fontWeight:'bold'}}>Please select your primary checking account from the list below to generate your 12 month cash flow projection.</H3> : null  }
    
            <Table style={{width: isDownMedium ? '100%' : 'auto',maxWidth:500,alignSelf:'center',marginTop:15,  minWidth: isDownMedium ? 300 : 500,}}>
            <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
              <TableRow>
              <TableCell  style={{color:'white', fontWeight:'bold'}}>Institution</TableCell>
              <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Name</TableCell>
          
              </TableRow>
        
            </TableHead>
            <TableBody style={{backgroundColor:'white', }}>
            {allAccounts.map((item,i) => {
                        if(item.type === 'checking' && checkingAccounts.length > 1){
                          return(
                            <TableRow key={i.toString()} style={{backgroundColor: primaryCheckingAccountId === item.id ? theme.palette.primary.dialogBackground : 'white'}}
                            onClick={() => handleSelectPrimaryCheckingAccount(item)}>
                              <TableCell  >
                                <Typography style={{color: primaryCheckingAccountId === item.id ? 'white' : theme.palette.primary.blackcoral,  fontWeight:'600'}}>{camelCaseForce(item.institutionName)}</Typography>
                                </TableCell>
                                <TableCell  >
                                <Typography style={{color: primaryCheckingAccountId === item.id ? 'white' : theme.palette.primary.blackcoral, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
                                </TableCell>
                             
                             
                              
                            </TableRow>
                        )
                          }
                        else {
                          return(
                            <TableRow key={i.toString()}>
                              <TableCell  >
                                <Typography style={{color: theme.palette.primary.blackcoral,fontWeight:'600'}}>{camelCaseForce(item.institutionName)}</Typography>
                                </TableCell>
                                <TableCell  >
                                <Typography style={{color: theme.palette.primary.blackcoral, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
                                </TableCell>
                             
                             
                              
                            </TableRow>
                        )
                        
                        }
                      })}
            </TableBody>
            </Table>
    
    
    
        
                  <div style={{marginTop:25,alignSelf:'center'}}>
                  <Button variant='contained'style={{alignSelf:'center'}}
                  onClick={() => navigate('/autosubmit',{state:{budgetAccounts: budgetAccounts,allAccounts: allAccounts, primaryCheckingAccountBalance: primaryCheckingAccountBalance,
                  finCustomerId: finCustomerId,url:url, primaryCheckingAccountId: primaryCheckingAccountId}})}>Submit</Button>
                  </div>
             
                 <BottomSpacer />
            </div>
        )
    }

    
  

}
 
}