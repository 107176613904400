import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from 'firebase/auth';
import { getLastMonthStartAndEndEpoch } from './Helpers';
import { doc, getFirestore, setDoc,  updateDoc} from "firebase/firestore";




const db = getFirestore();
const functions = getFunctions();

export async function handleSaveAndGenerateCashFlow(finCustomerId, primaryAccountId, accountBalance){


    let dates = getLastMonthStartAndEndEpoch()



    let getFinTransactionsSingleAccount = httpsCallable(functions,'getFinTransactionsSingleAccount');
    
       
      
       let transactionEntries = await getFinTransactionsSingleAccount({customerId: finCustomerId, accountId: primaryAccountId,
       monthEndEpoch: dates.monthEnd, monthStartEpoch: dates.monthStart
    
       }).then(function(result) {
         
         return result.data.newTransactionArray;
       }).catch(function(error){

         return false;
       })

     
      
      if(!transactionEntries ||  typeof transactionEntries === 'undefined' || transactionEntries === null || transactionEntries === ''){
        //return error
      }
      else if(transactionEntries[0].length === 0){

        //return error

      }


      else {
      
          let editTransArray = [...transactionEntries[0]];
          for(let i = 0; i < editTransArray.length; i++){
            editTransArray[i].docId = editTransArray[i].id
          }


        handleSaveAndGenerateStepTwo(editTransArray,primaryAccountId, accountBalance)
      }
    
      
       

      }

    
     
     export async function handleSaveAndGenerateStepTwo(transactions,theAccountId,accountBalance ){
   
        
        let revisedArray = [];
   
    
        for(let i = 0; i < transactions.length; i++){
           let transactionDay = new Date(transactions[i].transactionDate * 1000).toISOString().slice(8,10);
          
         
          let obj = {docId: transactions[i].id, amount: transactions[i].amount, dayDigit: transactionDay, 
            description: transactions[i].categorization.bestRepresentation,
                     one:{
                       amount: transactions[i].amount
                     },
                     two:{
                      amount: transactions[i].amount
                    },
                    three:{
                      amount: transactions[i].amount
                    },
                    four:{
                      amount: transactions[i].amount
                    },
                    five:{
                      amount: transactions[i].amount
                    },
                    six:{
                      amount: transactions[i].amount
                    },
                    seven:{
                      amount: transactions[i].amount
                    },
                    eight:{
                      amount: transactions[i].amount
                    },
                    nine:{
                      amount: transactions[i].amount
                    },
                    ten:{
                      amount: transactions[i].amount
                    },
                    eleven:{
                      amount: transactions[i].amount
                    },
                    twelve:{
                      amount: transactions[i].amount
                    }
  
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    } 
          revisedArray.push(obj);
          
        }
        revisedArray = revisedArray.sort((a,b) => (parseInt(a.dayDigit) > parseInt(b.dayDigit)) ? 1 : -1)
  

   
      addInitialCashFlowDataToFirestore(revisedArray,theAccountId)
          
  
      }


      export async function addInitialCashFlowDataToFirestore(transactionArray, theAccountId){
        
        const topUserUid = getAuth().currentUser.uid
          let max = transactionArray.length - 1;
       
        
             for(let i = 0; i < transactionArray.length; i++){
            
               let obj = {dayDigit: transactionArray[i].dayDigit, description: transactionArray[i].description, one: {amount: transactionArray[i].amount},
               two: {amount: transactionArray[i].amount},
               three: {amount: transactionArray[i].amount},
               four: {amount: transactionArray[i].amount},
               five: {amount: transactionArray[i].amount},
               six: {amount: transactionArray[i].amount},
               seven: {amount: transactionArray[i].amount},
               eight: {amount: transactionArray[i].amount},
               nine: {amount: transactionArray[i].amount},
               ten: {amount: transactionArray[i].amount},
               eleven: {amount: transactionArray[i].amount},
               twelve: {amount: transactionArray[i].amount},
              
              
              
              }
       
              let cashFlowDoc = doc(db,'EuklesUsersData',topUserUid,'CashFlowData',String(transactionArray[i].docId))
              await setDoc(cashFlowDoc,obj)
          
          
              if(i === max){
                
                let accountsDoc = doc(db,'EuklesUsersData',topUserUid,'Accounts',theAccountId)
                await updateDoc(accountsDoc,{isCashFlowAccount:true})
                let userDoc = doc(db,'EuklesUsers',topUserUid)
                await updateDoc(userDoc,{noCashFlowSet: false})
     
            
              }
            }
            
       
           
       return true
          
               
            
        }



        export async function handleSaveAndGenerateCashFlowWithReturn(finCustomerId, primaryAccountId, accountBalance){

 
            
  
          let dates = getLastMonthStartAndEndEpoch()
          //return {monthStart: monthStartStringToEpoch, monthEnd: monthEndStringToEpoch}
      
      
          let getFinTransactionsSingleAccount = httpsCallable(functions,'getFinTransactionsSingleAccount');
          
             
            
             let transactionEntries = await getFinTransactionsSingleAccount({customerId: finCustomerId, accountId: primaryAccountId,
             monthEndEpoch: dates.monthEnd, monthStartEpoch: dates.monthStart
          
             }).then(function(result) {
               
               return result.data.newTransactionArray;
             }).catch(function(error){
      
               return false;
             })
      
           
            
            if(!transactionEntries ||  typeof transactionEntries === 'undefined' || transactionEntries === null || transactionEntries === ''){
              //return error
            }
            else if(transactionEntries[0].length === 0){
      
              //return error
      
            }
      
      
            else {
            
                let editTransArray = [...transactionEntries[0]];
                for(let i = 0; i < editTransArray.length; i++){
                  editTransArray[i].docId = editTransArray[i].id
                }
      
            
      
              return {transactions: editTransArray, accountBalance: accountBalance, primaryAccountId: primaryAccountId}
        
            }
          
            
             
      
            }


            export async function handleSaveAndGenerateStepTwoWithReturn(transactions,theAccountId,accountBalance ){
   
        
              let revisedArray = [];
          
          
              for(let i = 0; i < transactions.length; i++){
                 let transactionDay = new Date(transactions[i].transactionDate * 1000).toISOString().slice(8,10);
                
               
                let obj = {docId: transactions[i].id, amount: transactions[i].amount, dayDigit: transactionDay, 
                  description: transactions[i].categorization.bestRepresentation,
                           one:{
                             amount: transactions[i].amount
                           },
                           two:{
                            amount: transactions[i].amount
                          },
                          three:{
                            amount: transactions[i].amount
                          },
                          four:{
                            amount: transactions[i].amount
                          },
                          five:{
                            amount: transactions[i].amount
                          },
                          six:{
                            amount: transactions[i].amount
                          },
                          seven:{
                            amount: transactions[i].amount
                          },
                          eight:{
                            amount: transactions[i].amount
                          },
                          nine:{
                            amount: transactions[i].amount
                          },
                          ten:{
                            amount: transactions[i].amount
                          },
                          eleven:{
                            amount: transactions[i].amount
                          },
                          twelve:{
                            amount: transactions[i].amount
                          }
        
                          
                          
                          
                          
                          
                          
                          
                          
                          
                          
                          } 
                revisedArray.push(obj);
                
              }
              revisedArray = revisedArray.sort((a,b) => (parseInt(a.dayDigit) > parseInt(b.dayDigit)) ? 1 : -1)
        

          
        
            return {transactionArray: revisedArray,accountId: theAccountId}
         
        
                
        
            }


            export async function addInitialCashFlowDataToFirestoreWithReturn(transactionArray, theAccountId){
        
                const topUserUid = getAuth().currentUser.uid
              let max = transactionArray.length - 1;
           
            
                 for(let i = 0; i < transactionArray.length; i++){
                
                   let obj = {dayDigit: transactionArray[i].dayDigit, description: transactionArray[i].description, one: {amount: transactionArray[i].amount},
                   two: {amount: transactionArray[i].amount},
                   three: {amount: transactionArray[i].amount},
                   four: {amount: transactionArray[i].amount},
                   five: {amount: transactionArray[i].amount},
                   six: {amount: transactionArray[i].amount},
                   seven: {amount: transactionArray[i].amount},
                   eight: {amount: transactionArray[i].amount},
                   nine: {amount: transactionArray[i].amount},
                   ten: {amount: transactionArray[i].amount},
                   eleven: {amount: transactionArray[i].amount},
                   twelve: {amount: transactionArray[i].amount},
                  
                  
                  
                  }
           
                  let cashFlowDoc = doc(db,'EuklesUsersData',topUserUid,'CashFlowData',String(transactionArray[i].docId))
             
                 await setDoc(cashFlowDoc,obj)
                  if(i === max){
                    let accountsDoc = doc(db,'EuklesUsersData',topUserUid,'Accounts',theAccountId)
                    await updateDoc(accountsDoc,{isCashFlowAccount:true})
                    let userDoc = doc(db,'EuklesUsers',topUserUid)
                    await updateDoc(userDoc,{noCashFlowSet: false})

                
                  }
                }
                
           
               
           return true
              
                   
                
            }
      


    
