import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTheme,Typography} from "@mui/material";
import { useState } from 'react';
import { submitCancellationRequest } from 'utils/StripeMethods';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OnBoardingContinueCard ({handleContinue,checkIfSubscribedThroughStripe,handleNavigateHome}) {
 
        const theme = useTheme();

        const [dialogDisplay,setDialogDisplay] = useState('Continue')
        const [switchMessage,setSwitchMessage] = useState('Please wait...')

        const  handleHelpClick = () => {
            window.open('mailto:help@budgeteven.com?subject=Help')
           
           }

async function startSwitchToManual(){
    setDialogDisplay('Process Switch to Manual')
  let method = await checkIfSubscribedThroughStripe();
    if(method === 'ios'){
        setSwitchMessage('It looks like you subscribed through Apple.  To cancel your subsription, go to the AppStore, tap on your profile icon, and then select subscriptions.')
    }
    if(method === 'android'){
        setSwitchMessage('It looks like you subscribed through the Google Play Store.  To cancel your subsription, you will need to do so through your Android device.')
    }
    if(method === 'web'){
        let cancel = await submitCancellationRequest();
        if(cancel){
            handleNavigateHome();
        }
    }
}
         
   
function determineContent(){
    switch(dialogDisplay){
        case 'Continue':
            return(
                <DialogContent style={{display:'flex', paddingTop:25, flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Looks like you still need to connect your financial accounts.</Typography>
                <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Tap below to continue setting up your budget.</Typography>
                <Button onClick={() => handleContinue()} variant='outlined' style={{backgroundColor: theme.palette.success.main, marginTop:25, width: 200, color:'white', fontWeight:'bold'}}    
>
Continue
</Button>
<DialogActions>







<Button onClick={() => setDialogDisplay('Trouble')} variant='text' style={{ marginTop:25, width: 200, color:'black'}}>    

Having trouble?
</Button>

</DialogActions>
</DialogContent>
            )

       case 'Trouble':
        return(
            <DialogContent style={{display:'flex', paddingTop:5, flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
      
            <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Select an option below.</Typography>

            <Button onClick={() => handleHelpClick()} variant='outlined' style={{backgroundColor: theme.palette.success.main, marginTop:25, width: 300, color:'white', fontWeight:'bold'}}>   

Contact Customer Support
</Button>
<Button onClick={() => setDialogDisplay('Switch to Manual')} variant='text' style={{ marginTop:15, width: 200, color:'black'}}>    

Switch to Manual
</Button>

<Button onClick={() => setDialogDisplay('Continue')} variant='text' style={{color:'grey',marginTop:25}}>   

Go Back
</Button>
<DialogActions>









</DialogActions>
</DialogContent>
        )

        case 'Switch to Manual':
            return(
                <DialogContent style={{display:'flex', paddingTop:25, flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Got it.  We'll cancel your subscription and switch you to the free version.</Typography>
                <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>Tap below to confirm.</Typography>
                <Button onClick={() => startSwitchToManual()} variant='outlined' style={{backgroundColor: theme.palette.success.main, marginTop:25, width: 200, color:'white', fontWeight:'bold'}}    
>
Confirm Switch
</Button>
<DialogActions>







<Button onClick={() => setDialogDisplay('Trouble')} variant='text' style={{ marginTop:25, width: 200, color:'grey'}}>    

Go Back
</Button>

</DialogActions>
</DialogContent>
            )

            case 'Process Switch to Manual':
                return(
                    <DialogContent style={{display:'flex', paddingTop:25, flexDirection: 'column', justifyContent:'center', alignItems:'center'}}>
                    <Typography style={{fontWeight:'bold',color:theme.palette.primary.fiverGrayText}}>{switchMessage}</Typography>
  
    </DialogContent>
                )
    }



}

  return (
    <div>

      <Dialog
        open={true}
        TransitionComponent={Transition}
     
      
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-title" 
                                    style={{textAlign:'center', overflowX:'hidden', overflowY:'hidden', 
                                    backgroundColor: theme.palette.primary.dialogBackground, color:'white', fontWeight:'bold', maxHeight:120}}>
                                              
                                    
            <Typography variant='h4' style={{fontWeight:'700'}}>Continue onboarding?</Typography>

            </DialogTitle>
          
           {determineContent()}

      </Dialog>
    </div>
  )



}