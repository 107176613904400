

import { getFirestore,getDocs,getDoc,doc,collection,query,where } from 'firebase/firestore';
import { getCurrentMonthAndYearString } from './Helpers';
import { getAuth } from 'firebase/auth';


export const getBudgetPodsMethod = async () => {
  const uid = getAuth().currentUser.uid
  const db = getFirestore();
    let hasBudgetForMonth = false
    let pods = []
    let monthsDoc = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
     let budgetMonthsArray = await getDoc(monthsDoc).then(function(doc){
       let theArray = doc.data().mArray;       
       return theArray;
     }).catch((error) => {
       //show alert and reload
      
       return [];
   });
   
   const monthToUse = getCurrentMonthAndYearString()

   
   let existingIndex = budgetMonthsArray.findIndex(obj => obj === monthToUse)
   
   
   if(existingIndex !== -1){
    hasBudgetForMonth = true
    let monthCollection = collection(db,'EuklesUsersData',uid,monthToUse)
   let podsArray = await getDocs(monthCollection).then(function(querySnapshot){
       if(querySnapshot.size <= 0){
         
           return false;
       }
       else if(querySnapshot.size > 0){
   
           let podArray = [];
          
           querySnapshot.forEach(function(doc){
              
               let localList = []
               let keys = Object.keys(doc.data());
               let fullData = doc.data();
            
        
               
               //*********LINE ITEM MAP********* */
               keys.map((name, i) => {
                 let p = Number(fullData[keys[i]].plannedValue)
                 p = Number(p.toFixed(2));
                 let s =  Number(fullData[keys[i]].spentValue)
                 s = Number(s.toFixed(2));
              
                   let lineItem = {
                       description: keys[i],
                       plannedValue: p,
                       spentValue: s,
                       podName: doc.id,
                       checked: false
                   }
                   localList.push(lineItem)
                   return null;
               })
            //************END LINE ITEM MAP************* */
   
            
               let budgetObject = {
                   title: doc.id,
                   data: localList,
                   checked: false
               }
               
                  
                       podArray.push(budgetObject)
           
   
           })
           return podArray;
       }
   }).catch((e) => {
     console.log('error ',e);
     return [];
   })
   
   pods = [...podsArray]
   }

 
return {podsArray: pods, hasBudgetForMonth: hasBudgetForMonth}


                                
    
}


export const calculateTotalsMethod = (localPodsArray,monthlySavingsGoal) => {
       
    let plannedIncome = 0.00;
    let plannedExpenses = 0.00;

    let actualIncome = 0.00;
    let actualExpenses = 0.00;

    let totalPlannedSavings = 0.00
    let totalActualSavings = 0.00

    for(let i = 0; i < localPodsArray.length; i++){
        let pod = localPodsArray[i];
       
        for(let b =0; b < pod.data.length; b++){
          if(pod.title !== 'Savings'){
            if(pod.title === 'Income'){
              plannedIncome = parseFloat(plannedIncome) + parseFloat(pod.data[b].plannedValue)
              actualIncome = parseFloat(actualIncome) + parseFloat(pod.data[b].spentValue)

          }
          else {
              plannedExpenses = parseFloat(plannedExpenses) + parseFloat(pod.data[b].plannedValue)
              actualExpenses = parseFloat(actualExpenses) + parseFloat(pod.data[b].spentValue)
          }
          }
          else {
              totalPlannedSavings = parseFloat(totalPlannedSavings) + parseFloat(pod.data[b].plannedValue)
              totalActualSavings = parseFloat(totalActualSavings) + parseFloat(pod.data[b].spentValue)
          }
      
           
        }


    }
    if(isNaN(plannedExpenses)){
      plannedExpenses = 0.00
    }

  let goalPercentage = ''
    if(!isNaN(totalActualSavings)){
       

        if(!isNaN(monthlySavingsGoal)){
          let goalPercentage = 0.00
          if(monthlySavingsGoal > 0){
              goalPercentage = parseFloat(totalActualSavings)/parseFloat(monthlySavingsGoal)      
            
               goalPercentage = goalPercentage
               
               
               
              
          }
          else {
         
          }
        }


    }
  
    //calculateOverSpent();

    return {plannedIncome: plannedIncome, actualIncome: actualIncome, plannedExpenses: Math.abs(plannedExpenses), actualExpenses: Math.abs(actualExpenses),
    totalActualSavings: totalActualSavings, monthlySavingsGoal:monthlySavingsGoal,goalPercentage:goalPercentage}
 


  }

export const  calculateOverSpentMethod = (localPodsArray) => {
    let localOverSpent = 0;
    let localItemsArray = [];
    for(let i = 0; i < localPodsArray.length; i++){
      let pod = localPodsArray[i];
      for(let w = 0; w < pod.data.length; w++){
        let net = parseFloat(pod.data[w].plannedValue) + parseFloat(pod.data[w].spentValue);
        if(net < 0 && pod.title !== 'Income' && pod.title !== 'Savings'){
         
          localItemsArray.push({podName: pod.title, overSpentAmount:net,
            lineItemDescription: pod.data[w].description, 
            plannedValue: pod.data[w].plannedValue, spentValue: pod.data[w].spentValue})
        
          localOverSpent++
        }
      }
    }
    
 return {overSpentCount: localOverSpent}


    
  }


  export const analyzeTransactionsForRecap = async () => {
    const monthToUse = getCurrentMonthAndYearString()
    let userUid = getAuth().currentUser.uid;
    const db = getFirestore();


    let collectionRef = collection(db,'EuklesUsersData',userUid,'Transactions')
    let q = query(collectionRef,where("trackingMonthYear","==",monthToUse),where("trackingStatus","==","Tracked"))

    let transactionsArray = await getDocs(q).then((querySnapshot) => {
         let localArray = [];
        if(querySnapshot.size > 0){
           
            querySnapshot.forEach((doc) => {
              if(doc.data().podName !== 'Savings' && doc.data().podName !== 'Income' && doc.data().podName !== 'Deleted'){
                let description = doc.data().description
                if(typeof doc.data().categorization !== 'undefined'){
                  if(typeof doc.data().categorization.normalizedPayeeName !== 'undefined'){
                    description = doc.data().categorization.normalizedPayeeName
                  }
                 
                }
                let obj = {description: description, podName: doc.data().podName, amount: doc.data().amount}
                localArray.push(obj);
              }
        
            })
        }
        return localArray;
    })

    let transactionsSortedArray = sortByDescriptionStepOne(transactionsArray)
    
    let topDescripton = transactionsSortedArray[0].description
    let topAmount = transactionsSortedArray[0].amount
    return {description: topDescripton, amount: topAmount}
  }




  function sortByDescriptionStepOne(transactionsArray){
    let descriptionNamesArray = [];

    for(let i = 0; i < transactionsArray.length; i++){
       descriptionNamesArray.push(transactionsArray[i].description)
    }
   
    let filteredArray = descriptionNamesArray.filter((c, index) => {
        return descriptionNamesArray.indexOf(c) === index;
    })

    let finalArray = [];
    for(let b = 0; b < filteredArray.length; b++){
        let obj = {description: filteredArray[b], transactions: 0, amount: 0.00};
        let localTransactionCount = 0;
        let localAmount = 0.00

        for(let r = 0; r < transactionsArray.length; r++){
            if(transactionsArray[r].description === filteredArray[b]){
           
                localAmount = parseFloat(localAmount) + parseFloat(transactionsArray[r].amount)
                localTransactionCount ++;


            }
        }
        obj.transactions = localTransactionCount;
        obj.amount = localAmount;
        //console.log('the obj line 130 ', obj);
       finalArray.push(obj);
    }

    
    finalArray = finalArray.filter(item => item.description !== 'Deleted')


    finalArray.sort((a,b) => (a.amount > b.amount) ? 1 : -1);

return finalArray
 
}


