import DashboardLayout from 'components/Layouts/DashboardLayout';
import LoadingScreen from 'pages/loading/LoadingScreen.js';
import { lazy, Suspense } from 'react';
import OnBoardingWelcome from 'pages/onboarding/FirstTwoSteps/OnBoardingWelcome.js';
import GetStarted from 'pages/onboarding/FirstTwoSteps/GetStarted.js';
import AutoFinicity from 'pages/onboarding/FinicitySteps/AutoFinicity.js';
import AutoFinicityLoading from 'pages/onboarding/FinicitySteps/AutoFinicityLoading.js';

import AutoSubmit from 'pages/onboarding/BudgetSteps/AutoSubmit.js';
import AutoSubmitLoadingScreen from 'pages/onboarding/BudgetSteps/AutoSubmitLoadingScreen.js';
import BudgetRecap from 'pages/onboarding/BudgetSteps/BudgetRecap.js';




import MainLoading from 'pages/mainpages/MainLoading.js';
import UpdateManageRules from 'pages/mainpages/transactions/UpdatedManageRules.js';
import EditSingleRule from 'pages/mainpages/transactions/EditSingleRule.js';
import AutoTransactionScanning from 'pages/mainpages/budgethome/autoscanning/AutoTransactionScanning.js';
import BudgetRecapAfterScan from 'pages/mainpages/budgethome/autoscanning/BudgetRecapAfterScan.js';
import NoBudgetPagePremium from 'pages/mainpages/budgethome/NoBudgetPremium.js';
import UpdatedAccounts from 'pages/mainpages/accountshome/UpdatedAccounts.js';
import UpdatedViewAccountTransactions from 'pages/mainpages/accountshome/UpdatedViewAccountTransactions.js';
import FinicityExistingUser from 'pages/finicity/FinicityExistingUser.js';

import FinicityExistingLoading from 'pages/finicity/FinicityExistingLoading.js';
import InsideAutoSubmit from 'pages/finicity/InsideAutoSubmit.js';
import InsideAutoSubmitLoading from 'pages/finicity/InsideAutoSubmitLoading.js';
import InsideBudgetRecap from 'pages/finicity/InsideBudgetRecap.js';
import FinicityPageLoading from 'pages/finicity/FinicityPageLoading.js';
import EditRuleForce from 'pages/mainpages/transactions/EditRuleForce.js';
import ErrorGetStarted from 'pages/onboarding/FirstTwoSteps/ErrorGetStarted.js';
import DeleteAccount from 'pages/deleteaccount/DeleteAccount.js';

import UpdatedPricingPage from 'pages/pricingpage/UpdatedPricingPage.js';
import UpdatedCheckOutInterior from 'pages/pricingpage/UpdatedCheckOutInterior.js';


import OnBoardCheckout from 'pages/onboarding/PricingAndCheckOut/OnBoardCheckout.js';
import FinicityAlreadyAutoWithBudget from 'pages/AlreadyAutoWithBudgetConClick/FinicityAlreadyAutoWithBudget.js';
import FinicityAlreadyAuthWithBudgetLoading from 'pages/AlreadyAutoWithBudgetConClick/FinicityAlreadyAutoWithBudgetLoading.js';
import FinicityAlreadySemiAutoWithBudget from 'pages/AlreadySemiAutoWithBudgetConClick/FinicityAlreadySemiAutoWithBudget.js';
import FinicityAlreadySemiAutoBudgetLoading from 'pages/AlreadySemiAutoWithBudgetConClick/FinicityAlreadySemiAutoBudgetLoading.js';

import UpgradeFromSemiAutoToAutoPage from 'pages/accountsettings/UpgradeFromSemiAutoToAutoPage.js';
import UpgradeFromManualToAutoPage from 'pages/accountsettings/UpgradeFromManualToAutoPage.js';
import HowToStartABudget from 'pages/blogpage/blogarticles/HowToStartABudget.js';
import HowToSaveMoney from 'pages/blogpage/blogarticles/HowToSaveMoney.js';
import BudgetingAnnualExpenses from 'pages/blogpage/blogarticles/BudgetingAnnualExpenses.js';

const Loadable = Component => props => <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>; // Landing page



const AnalysisHomePage = Loadable(lazy(() => import('./pages/mainpages/analysishome/AnalysisHomePage.js')))
const AccountSettings = Loadable(lazy(() => import('./pages/accountsettings/AccountSettings')));
const Blog = Loadable(lazy (() => import('./pages/blogpage/BlogPage.jsx')))
const CashFlowLarge = Loadable(lazy(() => import('./pages/mainpages/cashflowsection/CashFlowUpdate.jsx')))

const Error = Loadable(lazy(() => import('./pages/errorpage/404')));
const ForgetPassword = Loadable(lazy(() => import('./pages/loginandregistration/ForgetPassword.jsx')));
const FinicityPage =  Loadable(lazy(() => import('./pages/finicity/FinicityPage.js')))
const InvestmentHomePage = Loadable(lazy(() => import('./pages/mainpages/investmentshome/InvestmentHomePage.js')))
const LandingPage = Loadable(lazy(() => import('./pages/landingpage/LandingPage')));
const LoginPage = Loadable(lazy(() => import('./pages/loginandregistration/LoginPage.js')))
const ManageLineItemPage = Loadable(lazy(() => import('./pages/mainpages/cashflowsection/ManageLineItemPage.js')))
const OutsideTermsOfService =  Loadable(lazy(() => import('./pages/termsandprivacy/TermsOfService.js')))

const PrivacyPage = Loadable(lazy(() => import('./pages/termsandprivacy/Privacy')));

const Register = Loadable(lazy(() => import('./pages/loginandregistration/Register.jsx')));
const UpdateSubscription =  Loadable(lazy(() => import('./pages/paymentpage/UpdateSubscription.jsx')))


const BudgetHomePageAlt = Loadable(lazy(() => import('./pages/mainpages/budgethome/BudgetHomePageAlt.js')))



//Route Review
//<Register /> "register"  goes to <OnBoardingWelcome /> "welcome" goes to <GetStarted /> "getstarted"  goes to 
//if going manual '/dashboard/budgethome'  <BudgetHomePageAlt />
//if going semi or auto <OnBoardCheckOut /> "onboardcheckout" 
//if going semi  '/dashboard/budgethome'  <BudgetHomePageAlt />

//if going auto  path: 'finicityconnect',<AutoFinicity /> which implements the Finicity Connect 
//After <AutoFinicity /> goes to 'finicityloading',<AutoFinicityLoading /> which calls refreshAccounts and gathers all of the accounts the user connected.  Lists the accounts for the user to confirm and submit.
//Hitting submit on AutoFinicity goes to 'autosubmit',<AutoSubmit /> which calls getLastMonthTransactions and generateCashFlow, and allows the user to categorize all uncategorized transactions.
//Afaer <AutoFinicity /> goest to 'autosubmitloading' <AutoSubmitLoadingScreen /> which getsCurrentMonthTransactions, then tracking rules,then tracks everything for the current month then to BudgetRecap 'budgetrecap' then to home


//RULES
//managerules <UpdateManageRules />
//row tap goes to    navigate('/dashboard/editrule',{state:{selectedRule: rule}}) then goes to editrule <EditSingleRule />
    


//Manual user going to Semi Auto
//Goest to checkout, and then back to dashboard.  SemiAuto Connect click goest to dashboard/asawb


const routes = [{
  path: '/',

     element: <LandingPage />
 

}, 
{
  path:'mainloading',
element: <MainLoading />
},
{
  path:'welcome',
element: <OnBoardingWelcome />
},

{
  path: 'getstarted',
  element: <GetStarted />
 
}, 
// {
//   path: 'onboardpricing',
//   element: <PricingOnBoarding />
 
// }, 
{
  path: 'onboardcheckout',
  element: <OnBoardCheckout />
 
}, 
{
  path: 'errorgetstarted',
  element:  <ErrorGetStarted />
 
}, 

{
  path: 'finicityconnect',
  element: <AutoFinicity />
 
}, 
{
  path: 'finicityloading',
  element: <AutoFinicityLoading />
 
}, 
{
  path: 'autosubmit',
  element: <AutoSubmit />
 
}, 
{
  path: 'autosubmitloading',
  element: <AutoSubmitLoadingScreen />
 
}, 
{
  path: 'budgetrecap',
  element: <BudgetRecap />
 
}, 



{
  path:'autotransactionscanning',
  element: <AutoTransactionScanning />
},
{
  path:'budgetrecapafterscan',
  element: <BudgetRecapAfterScan />
},

{
  path:'nobudgetpremium',
  element: <NoBudgetPagePremium />
},





 



{
  path: 'login',
  element: <LoginPage />
 
}, 
{
  path: 'register',
  element: <Register />
      
}, {
  path: 'forget-password',
  element: <ForgetPassword />
   
}, 

{
  path:'privacypolicy',
  element: <PrivacyPage />
},
{
  path:'termsofservice',
  element: <OutsideTermsOfService />
},

{
  path:'deleteaccount',
  element: <DeleteAccount />
},

// {
//   path:'learn',
//   element: <Blog />,

// },

// {
//   path:'learn/howtostartabudget',
//   element: <HowToStartABudget />

// },

// {
//   path:'learn/howtosavemoney',
//   element: <HowToSaveMoney />

// },

// {
//   path:'learn/budgetingannualexpenses',
//   element: <BudgetingAnnualExpenses />

// },



{
  path: 'dashboard',
  element: <DashboardLayout />,

  children: [
  //   {
  //   path: 'budgethome',
  //   element: <DashboardSaaS />
  // }, 
  {path: 'budgethome',
  element: <BudgetHomePageAlt />
}, 
{
  path: 'managerules',
  element: <UpdateManageRules />
},
{
  path:'finicityexisting',
  element: <FinicityExistingUser />
},
{
  path:'finicityexistingloading',
  element: <FinicityExistingLoading />
},



{
  path: 'editrule',
  element: <EditSingleRule />
},
{
  path: 'ruleedit',
  element: <EditRuleForce />
},


  
  
  {
    path: 'balancesheet',
  
     element: <InvestmentHomePage />
  }, 

  {
    path:'cashflowlineitems',
    element: <ManageLineItemPage />
    
  },


  {
    path: 'transactions',
    // element: <DashboardProjectManagement />
    element: <AnalysisHomePage />
  },
  {
    path:'account-settings',
    element: <AccountSettings />
  },

  {
    path:'upgradefsatap',
    element: <UpgradeFromSemiAutoToAutoPage />
  },

  {
    path:'upgradefmtap',
    element: <UpgradeFromManualToAutoPage />
  },



  {
    path:'accountshome',
    element: <UpdatedAccounts />,
    
  },

  {path:'accounttransactions',
      element: <UpdatedViewAccountTransactions />
  },
  
  {
    path:'cashflow',
    element: <CashFlowLarge />
  },
  {
    path:'pricing',
    // element: <PricingsPage />
    element: <UpdatedPricingPage />
  },
  {
    path:'checkout',
    // element: <CheckOutPage />
    element: <UpdatedCheckOutInterior />
  },
  {
    path:'updatesubscription',
    element: <UpdateSubscription />
  },


  {
    path:'finicityaawb',
    element: <FinicityAlreadyAutoWithBudget />
  },

  {
    path:'finicityaawbl',
    element: <FinicityAlreadyAuthWithBudgetLoading />
  },


  {
    path:'finicityasawb',
    element: <FinicityAlreadySemiAutoWithBudget />
  },


  {
    path:'finicityasabl',
    element: <FinicityAlreadySemiAutoBudgetLoading />
  },




  {
    path:'finicity',
    element: <FinicityPage />
  },
  {
    path:'finicitypageloading',
    element: <FinicityPageLoading />
  },
  {
    path:'insideautosubmit',
    element: <InsideAutoSubmit />
  },
  {
    path:'insideautosubmitloading',
    element: <InsideAutoSubmitLoading />
  },
  {
    path:'insidebudgetrecap',
    element: <InsideBudgetRecap />
  },
 
 
 


]
}, {
  path: '*',
  element: <Error />
}];
export default routes;