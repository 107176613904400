
import AutoCard from "pages/onboarding/OptionsCards/AutoCard";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import BudgetEvenLogo from '../landingpage/landingimages/budgeteven.svg'
import { doc,getFirestore,updateDoc,  } from "firebase/firestore";
import { getAnalytics,logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { Grid,useTheme,Button} from '@mui/material';
import {H3,} from "components/uicomponents/Typography";
import React,{useState,useEffect,useContext} from 'react'
import SemiAutoCard from "pages/onboarding/OptionsCards/SemiAutoCard";
import { useNavigate,useLocation,Navigate} from "react-router";



  const db = getFirestore();
  

export default function UpdatedPricingPage(){

    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const {isAuthenticated} = useContext(BudgetContextTwo)

  
    const [value, setValue] = useState('Auto');

    useEffect(() => {
       
      updateAnalytics();

    }, []);

   
       


      function handleAutoSelect(){
            navigate('../checkout',{state:{comingFrom: location.state.comingFrom, planType:'Auto'}})
      }

      async function handleValue(value){
        let uid = getAuth().currentUser.uid
        setValue(value);
        let docRef = doc(db,'EuklesUsers',uid)
        if(value === 'Semi-Auto'){
          await updateDoc(docRef,{
            insideWebPricingTappedSemiWeb: true
          })
       
        }
        else if(value === 'Auto'){
          await updateDoc(docRef,{
            insideWebPricingTappedAutoWeb: true
          })
        }
  
        else if(value === 'Manual'){
          await updateDoc(docRef,{
            insideWEbPricingTappedManualWeb: true
          })
        }
     
      }

      function handleSemiAutoSelect(){
        navigate('../checkout',{state:{comingFrom: location.state.comingFrom, planType:'Semi-Auto'}})
  }

  async function updateAnalytics(){
    const analytics = getAnalytics();
    logEvent(analytics,'bb_webViewedInsidePricing')
  }



     if(isAuthenticated){
      return (
        <Grid container direction="column" justifyContent='center' alignContent='center' mb={25}>

         <Grid container direction='row' justifyContent='center'>
         <Grid item>
         <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
         </Grid>
         </Grid>
     

         <Grid container direction='row' justifyContent='center'>
         <Grid item>
         <H3 style={{maxWidth:400,marginBottom:50,fontWeight:'bold', textAlign:'center',color:theme.palette.primary.fiverGrayText}}>Select an Option to Continue</H3>
         </Grid>
         </Grid>

       
  
     
   
     
   
      
         <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
         <Button onClick={() => handleValue('Auto')} style={{fontWeight:'bold',width:150}} variant={value === 'Auto' ? 'contained' : 'outlined'}>Auto</Button>
               <Button onClick={() => handleValue('Semi-Auto')} style={{fontWeight:'bold',width:150}} variant={value === 'Semi-Auto' ? 'contained' : 'outlined'}>Semi-Auto</Button>
             
         </div>
  
       <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:15}}>
         
         {value === 'Auto' ? <AutoCard handlePurchase={handleAutoSelect}  /> : null}
         {value === 'Semi-Auto' ? <SemiAutoCard handlePurchase={handleSemiAutoSelect} /> : null}
    

       </div>
      


       <Grid container direction='row' justifyContent='center'>
         <Grid item>
         <H3 style={{maxWidth:400,marginBottom:50,fontWeight:'bold', textAlign:'center',color:theme.palette.primary.fiverGrayText}}></H3>
         </Grid>
         </Grid>

         <Grid container direction='row' justifyContent='center'>
         <Grid item>
         <H3 style={{maxWidth:400,marginBottom:50,fontWeight:'bold', textAlign:'center',color:theme.palette.primary.fiverGrayText}}></H3>
         </Grid>
         </Grid>
         
     </Grid>
     )
     }
     else if(!isAuthenticated){
      return(
        <Navigate to='/' replace={true} />
      )
    }

    
      
     
      
        
    
 
  
  
}