

import {H3} from "components/uicomponents/Typography";
import { Ellipsis} from 'react-spinners-css'
import { getFunctions, httpsCallable } from "firebase/functions";
import { Grid, Backdrop,useMediaQuery,Table, TableBody, TableCell, TableHead, TableRow,useTheme, Typography,Button} from '@mui/material';
import React,{useEffect,useState} from 'react'
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { getAuth} from "firebase/auth";
import { getCurrentMonthAndYearString,getTodaysEpoch,getLastMonthEndingDayEpoch,getCurrentDayDigit } from "utils/Helpers";
import { collection, doc, getFirestore, setDoc, getDoc, getDocs, updateDoc,deleteDoc,query,where } from "firebase/firestore";
import BudgetEvenLogo from '../landingpage/landingimages/budgeteven.svg'


const db = getFirestore();
const functions = getFunctions();
//const analytics = getAnalytics();

export default function FinicityPageLoading() {



    const theme = useTheme()
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    const uid = getAuth().currentUser.uid
    const navigate = useNavigate();
    const location = useLocation();
    let finCustomerId = location.state.finCustomerId
    let url = location.state.url

 
    const [allAccounts, setAllAccounts] = useState([])
    const [budgetAccounts, setBudgetAccounts] = useState([]) 
    const [checkingAccounts, setCheckingAccounts] = useState([])
    const [loading, setLoading] = useState(true)  
    const [primaryCheckingAccountId, setPrimaryCheckingAccountId] = useState('')
    const [primaryCheckingAccountBalance, setPrimaryCheckingAccountBalance] = useState(0.00)  
    const [loadingMessage, setLoadingMessage] = useState('Organizing your accounts...')


    const [showNoCheckingError, setShowNoCheckingError] = useState(false)
    const [showNoCardError, setShowNoCardError] = useState(false)
    const [showNoCheckingOrCreditCardError,setShowNoCheckingOrCreditCardError] = useState(false)
    const [showInfoAboutGoingManual, setShowInfoAboutGoingManual] = useState(false)

  
// useEffect(() => {
//   updateStep()
// },[])

// async function updateStep(){
//   let uid = getAuth().currentUser.uid
//   let docRef = doc(db,'EuklesUsers',uid)
//   await updateDoc(docRef,{currentStep: 'InsideFinicityLoading'})
// }

useEffect(() => {
 
 // getAccountsUpdated();
  //submitAnalytics();
checkBudgetType();
},[])



async function checkBudgetType(){ 
   let docRef = doc(db,'EuklesUsers',uid)
  let budgetType = await getDoc(docRef).then(doc => {
    let budgetMethod = 'Semi-Auto'
    if(typeof doc.data().budgetMethod !== 'undefined'){
      budgetMethod = doc.data().budgetMethod
    }
    return budgetMethod
  }).catch(e => {
    return 'Semi-Auto'
  })

  if(budgetType === 'Semi-Auto'){
    getSemiAutoAccounts();
  }
  else {
    getAccountsUpdated();
  }
}

async function getSemiAutoAccounts(){
  let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');

    let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
  
      return result.data.accounts;
    }).catch(function(error){
  
      return false;
    })

    for(let i = 0; i < finAccountsArray.length; i++){
      let getInstitutionName = httpsCallable(functions,'retrieveInstitutionName')
  
    let realName = await getInstitutionName({institutionId: finAccountsArray[i].institutionId}).then((res) => {
         
       
         return res.data.name[0]
     }).catch((error) => {
         console.log(error);
         return '';
     })

     finAccountsArray[i].institutionName = realName
    
  }

 

  for(let i = 0; i < finAccountsArray.length; i++){
    let myObject = {...finAccountsArray[i], isFinicity:true}
    let accountDocRef = doc(db,'EuklesUsersData',uid, 'Accounts',finAccountsArray[i].id)
    await setDoc(accountDocRef,myObject)
 
}

getCurrentMonthTransactions();

}

async function getCurrentMonthTransactions(){
  let endingEpoch = getTodaysEpoch()

  setLoadingMessage('Scanning for transactions...')
 
  let beginningEpoch = getLastMonthEndingDayEpoch()

 

  let getTransactions = httpsCallable(functions,'getMonthToDateTransactions');
  let rawTransactionArray = await getTransactions({customerId: finCustomerId,  beginningEpoch: beginningEpoch, endingEpoch: endingEpoch}).then((result) => {
         

       
         return result.data.newTransactionArray
     }).catch((error) => {
         console.log(error);
         return 'error';
     })

 
    getAlreadyTrackedTransactions(beginningEpoch,rawTransactionArray)
  }

  async function getAlreadyTrackedTransactions(beginningEpoch, rawTransactionArray){
    let uid = getAuth().currentUser.uid;
    let collectionRef = collection(db,'EuklesUsersData',uid,'Transactions')
    let q = query(collectionRef,where("trackingEpoch",">=",beginningEpoch))
   
    let trackedTransactions = await getDocs(q).then(querySnapshot => {
        let localArray = []
        querySnapshot.forEach(doc => {
            localArray.push({id: doc.id})
        })
        return localArray
    }).catch(e => {
        return []
    })

 
  

    let filteredTransactions = []
    for(let i = 0; i < rawTransactionArray.length; i++){
        let transaction = rawTransactionArray[i]
        let existingIndex = trackedTransactions.findIndex(obj => parseInt(obj.id) === parseInt(transaction.id))
        if(existingIndex === -1){
            filteredTransactions.push(transaction)
        }
    }



uploadNewTransactions(filteredTransactions)


}

async function uploadNewTransactions(transactions){
  let uid = getAuth().currentUser.uid
  for(let i = 0; i < transactions.length; i++){
      let obj = {...transactions[i],trackingStatus:'New'}
      let docRef = doc(db,'EuklesUsersData',uid,'Transactions',String(transactions[i].id))
      await setDoc(docRef,obj)
     
  }


   

let todaysEpoch = getTodaysEpoch();
let todaysDigit = getCurrentDayDigit();
let docRefAlt = doc(db,'EuklesUsers',uid)
await updateDoc(docRefAlt,{
  lastTransactionScanEpoch: todaysEpoch,lastScanDigit: todaysDigit
})
//   await firestore().collection('EuklesUsers').doc(userUid).update({lastTransactionScanEpoch: todaysEpoch,lastScanDigit: todaysDigit}).then(res => {
// return true;
// }).catch(e => {
// return false;
// })

navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
  
}



function camelCaseForce(title){

  let titleArray = title.split(" ")
  for(let i = 0; i < titleArray.length; i++){
    
    titleArray[i] = titleArray[i].toLowerCase()
    titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].substring(1)
  }

  titleArray = titleArray.join(" ")
  if(titleArray === 'Creditcard'){
    titleArray = 'Credit Card'
  }
  if(titleArray === 'Ira'){
    titleArray = 'IRA'
  }
  return titleArray
}





async function handleNoCheckingOrNoCardStepOne(){
   // setShowInfoAboutGoingManual(true)



}

async function localProcessCancellation(){
setLoading(true)
setLoadingMessage('')
setShowInfoAboutGoingManual(false)
let res = await processCancellationMethod()
if(res){
  // setLoading(false)
  // navigation.navigate('Main')
  handleStartBudgetFromScratch()
  
}
else {

}
}

async function processCancellationMethod(){
    let accountsCollection = collection(db,'EuklesUsersData',uid, 'Accounts')
    let accountsArray = await getDocs(accountsCollection).then(querySnapshot => {
        let localArray = []
        querySnapshot.forEach(doc => {
            localArray.push(doc.id)
        })
        return localArray
    }).catch(e => {
        return []
    })

    for(let i = 0; i < accountsArray.length; i++){
        let delDocRef = doc(db,'EuklesUsersData',uid,'Accounts',accountsArray[i])
        await deleteDoc(delDocRef)

       }

       let cashFlowCollection = collection(db,'EuklesUsersData',uid, 'CashFlowData')
       let cashFlowArray = await getDocs(cashFlowCollection).then(querySnapshot => {
        let localArray = []
        querySnapshot.forEach(doc => {
            localArray.push(doc.id)
        })
        return localArray
    }).catch(e => {
        return []
    })

    for(let i = 0; i < cashFlowArray.length; i++){
        let delDocRef = doc(db,'EuklesUsersData',uid,'CashFlowData',cashFlowArray[i])
        await deleteDoc(delDocRef)
   
        }

        let monthYear = getCurrentMonthAndYearString();

        let podsCollection = collection(db,'EuklesUsersData',uid, monthYear)
        let currentPods = await getDocs(podsCollection).then(querySnapshot => {
            let localArray = []
            querySnapshot.forEach(doc => {
                localArray.push(doc.id)
            })
            return localArray
        }).catch(e => {
            return []
        })

        for(let i = 0; i < currentPods.length; i++){
            let podsDocRef = doc(db,'EuklesUsersData',uid,monthYear,currentPods[i])
            await deleteDoc(podsDocRef)
     
            }

        let incomeRef = doc(db,'EuklesUsersData',uid,monthYear,'Income')

        await setDoc(incomeRef,{
            ['Paychecks']:{
                plannedValue:0.00,
                spentValue:0.00,
                podName:'Income'
            }
        
        })

        let savingsRef = doc(db,'EuklesUsersData',uid,monthYear,'Savings')

        await setDoc(savingsRef,{
            ['Savings']:{
                plannedValue:0.00,
                spentValue:0.00,
                podName:'Savings'
            }
        
        })

        let trackingRulesCollection = collection(db,'EuklesUsersData',uid, 'TrackingRules')
        let trackingRulesArray = await getDocs(trackingRulesCollection).then(querySnapshot => {
            let localArray = []
            querySnapshot.forEach(doc => {
                localArray.push(doc.id)
            })
            return localArray
        }).catch(e => {
            return []
        })

        for(let i = 0; i < trackingRulesArray.length; i++){
            let delRuleRef = doc(db,'EuklesUsersData',uid,'TrackingRules',trackingRulesArray[i])
            await deleteDoc(delRuleRef)
       
            }

        let updateUserDoc = doc(db,'EuklesUsers',uid)
        await updateDoc(updateUserDoc,{isPremiumUser:false,
            finCustomerId:'nil',
            noCashFlowSet: true})

        let deleteFin = httpsCallable(functions,'deleteFinCsutomer')

await deleteFin({finCustomerId: finCustomerId}).then(res => {
return true;
}).catch(e => {
return false
})

    return true;
}

async function handleStartBudgetFromScratch(){

let emptyBudgetMonth = getCurrentMonthAndYearString();
  setLoading(true)
  let docRef = doc(db,'EuklesUsersData',uid,emptyBudgetMonth,'Income')
  await setDoc(docRef,{'Paycheck':{plannedValue:0.00, spentValue:0.00, podName:'Income'}}).then(res => {
    return true;
  }).catch(e => {
    return false
  })

let savingsDoc = doc(db,'EuklesUsersData',uid,emptyBudgetMonth,'Savings')
await setDoc(savingsDoc,{'Savings': {
    plannedValue: 0.00,
    spentValue: 0.00,
    podName:'Savings'
}}).then(res => {
    return true;
  }).catch(e => {
    console.log('scratch error ', e);
    return false;
  })

    let mArray = [emptyBudgetMonth]
    let monthsRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
    await setDoc(monthsRef,{mArray})

 
  setLoading(false)
  navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
  //navigation.navigate('Main') navigate('/dashboard/budgethome',{state:{needsToRefresh: true}})
   }

async function getAccountsUpdated(){

    //let retrieveBalances = functions().httpsCallable('refreshFinAccounts');
    let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');
    //let retrieveBalances = httpsCallable(functions,'getFastFinAccounts')
    //let retrieveBalances = functions().httpsCallable('getFastFinAccounts');
      let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
    
        return result.data.accounts;
      }).catch(function(error){
    
        return false;
      })

    

      if(finAccountsArray === false || finAccountsArray.length === 0){
        //error
        console.log('an error has occurred')
      }
      else {

        let hasChecking = false
        let hasCreditCard = false

        for(let i = 0; i < finAccountsArray.length; i++){
          if(finAccountsArray[i].type === 'checking'){
            hasChecking = true
          }
          if(finAccountsArray[i].type === 'creditCard'){
            hasCreditCard = true
          }
        }


        if(!hasChecking || !hasCreditCard){

          if(!hasChecking && !hasCreditCard){
           setShowNoCheckingOrCreditCardError(true)
    
          }
          else if(!hasChecking && hasCreditCard){
            setShowNoCheckingError(true)
      
          }

          else if(hasChecking && !hasCreditCard){
            setShowNoCardError(true)
  
          }
 
        }

        for(let i = 0; i < finAccountsArray.length; i++){
            let getInstitutionName = httpsCallable(functions,'retrieveInstitutionName')
          //let getInstitutionName = functions().httpsCallable('retrieveInstitutionName');
          let realName = await getInstitutionName({institutionId: finAccountsArray[i].institutionId}).then((res) => {
               
             
               return res.data.name[0]
           }).catch((error) => {
               console.log(error);
               return '';
           })

           finAccountsArray[i].institutionName = realName
          
        }

        setAllAccounts(previousState => [...finAccountsArray])

        for(let i = 0; i < finAccountsArray.length; i++){
          let myObject = {...finAccountsArray[i], isFinicity:true}
          let accountDocRef = doc(db,'EuklesUsersData',uid, 'Accounts',finAccountsArray[i].id)
          await setDoc(accountDocRef,myObject)
       
      }

        let filteredBudgetAccounts = []

          let currentCheckingArray = checkingAccounts
          for(let i = 0; i < finAccountsArray.length; i++){
          
            if(finAccountsArray[i].type.toLowerCase() === 'checking'){
              let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
              accountTransactionsArray:[]}
              filteredBudgetAccounts.push(obj)
                currentCheckingArray.push(finAccountsArray[i])
                
            }
            if(finAccountsArray[i].type.toLowerCase() === 'creditcard'){
              let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
                accountTransactionsArray:[]}
                filteredBudgetAccounts.push(obj)
            }

            if(finAccountsArray[i].type.toLowerCase() === 'savings'){
              let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
                accountTransactionsArray:[]}
                filteredBudgetAccounts.push(obj)
            }
          }

          setCheckingAccounts(previousState => [...currentCheckingArray])
          if(currentCheckingArray.length === 1){
            setPrimaryCheckingAccountId(currentCheckingArray[0].id)
            setPrimaryCheckingAccountBalance(currentCheckingArray[0].balance)
          }
  setBudgetAccounts(previousState => [...filteredBudgetAccounts])          


          }

          
       
   
        setLoading(false)
     
      }

      
function handleSelectPrimaryCheckingAccount(account){

  setPrimaryCheckingAccountId(account.id)
  setPrimaryCheckingAccountBalance(account.balance)
}


    
if(loading){
  return(
  
<Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
   <Grid container direction='column'  justifyContent='center' alignItems='center'>
     <Grid item>
     <Ellipsis color={theme.palette.primary.main} />
     </Grid>
      <Grid item style={{paddingTop:5}}>
       <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>{loadingMessage}</Typography>
     </Grid> 
   </Grid>
  
  
 </Backdrop> 
  )
}
else {

    if(showNoCheckingError){
        return(
            <Alert variant="filled" severity="error"  action={<div style={{display:'flex',flexDirection:'row',paddingTop:25}}> 
            <Button onClick={() => handleNoCheckingOrNoCardStepOne()} color="inherit" size="small">
            Reset and Go Manual
           </Button>
           <Button onClick={() => navigate('../finicity',{state:{url:url,urlToUse:url,finCustomerId:finCustomerId}})} color="inherit" size="small">
             Connect my Checking
           </Button>
           </div>
          
           
         }>
        We didn't detect a checking account.  Budget Even works best with at least one checking account connected.
       </Alert>
        )
      
    }


    if(showNoCardError){
        return(
            <Alert variant="filled" severity="error"  action={<div style={{display:'flex',flexDirection:'row',paddingTop:25}}> 
            <Button onClick={() => handleNoCheckingOrNoCardStepOne()} color="inherit" size="small">
            Reset and Go Manual
           </Button>
           <Button onClick={() => navigate('../finicity',{state:{url:url,urlToUse:url,finCustomerId:finCustomerId}})} color="inherit" size="small">
             Connect Accounts
           </Button>
           </div>
          
           
         }>
        We didn't detect a connected credit card.  Budget Even works best with at least one credit card connected.
       </Alert>
        )
      
    }

    if(showNoCheckingOrCreditCardError){
        return (
            <Alert variant="filled" severity="error"  action={<div style={{display:'flex',flexDirection:'row',paddingTop:25}}> 
            <Button onClick={() => handleNoCheckingOrNoCardStepOne()} color="inherit" size="small">
            Reset and Go Manual
           </Button>
           <Button onClick={() => navigate('../finicity',{state:{url:url,urlToUse:url,finCustomerId:finCustomerId}})} color="inherit" size="small">
             Connect Accounts
           </Button>
           </div>
          
           
         }>
       We didn't detect a checking account or credit card.  Budget Even works best with at least one checking account and one credit card connected.
       </Alert>
        )
    }


    if(showInfoAboutGoingManual){
        return(
            <Alert onClick={() => localProcessCancellation()} style={{maxWidth:900}} variant="filled" severity="info" action={<div style={{display:'flex',flexDirection:'row',paddingTop:25}}> <Button onClick={() => handleNoCheckingOrNoCardStepOne()} style={{fontWeight:'bold'}} color="inherit" size="small">
            Go Manual
           </Button>
           <Button onClick={() => navigate('../finicity',{state:{url:url,urlToUse:url,finCustomerId:finCustomerId}})} style={{fontWeight:'bold'}}  color="inherit" size="small">
             Go Back
           </Button>
           </div>
          
           
         }>
             <AlertTitle>Budget Even Manual</AlertTitle>
       
             We will remove your connected accounts so you can still utilize Budget Even manually.  You can create a monthly budget, add transactions, and track your net worth by manually adding entries.  Switch to premium at any time.
       </Alert>
        )
    }

    else {
        return (
            <div style={{display:'flex',flexDirection:'column', flex:1, justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
                       <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
      
        
            <H3 style={{marginBottom:10,marginTop:25, textAlign:'center', color: theme.palette.primary.navy, fontWeight:'bold'}}>{budgetAccounts.length > 1 ?  'The accounts below will be used to create your budget, cash flow projection, and net worth balance sheet. ' : 'The following account will be used to create your budget, cash flow projection, and net worth balance sheet. '}  </H3>
          
    
            {checkingAccounts.length > 1 ? <H3 style={{marginBottom:10,marginTop:25, textAlign:'center', color: theme.palette.primary.navy, fontStyle:'italic', fontWeight:'bold'}}>Please select your primary checking account from the list below to generate your 12 month cash flow projection.</H3> : null  }
    
            <Table style={{width: isDownMedium ? '100%' : 'auto',maxWidth:500,alignSelf:'center',marginTop:15,  minWidth: isDownMedium ? 300 : 500,}}>
            <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
              <TableRow>
              <TableCell  style={{color:'white', fontWeight:'bold'}}>Institution</TableCell>
              <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Name</TableCell>
          
              </TableRow>
        
            </TableHead>
            <TableBody style={{backgroundColor:'white', }}>
            {allAccounts.map((item,i) => {
                        if(item.type === 'checking' && checkingAccounts.length > 1){
                          return(
                            <TableRow key={i.toString()} style={{backgroundColor: primaryCheckingAccountId === item.id ? theme.palette.primary.dialogBackground : 'white'}}
                            onClick={() => handleSelectPrimaryCheckingAccount(item)}>
                              <TableCell  >
                                <Typography style={{color: primaryCheckingAccountId === item.id ? 'white' : theme.palette.primary.blackcoral,  fontWeight:'600'}}>{camelCaseForce(item.institutionName)}</Typography>
                                </TableCell>
                                <TableCell  >
                                <Typography style={{color: primaryCheckingAccountId === item.id ? 'white' : theme.palette.primary.blackcoral, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
                                </TableCell>
                             
                             
                              
                            </TableRow>
                        )
                          }
                        else {
                          return(
                            <TableRow key={i.toString()}>
                              <TableCell  >
                                <Typography style={{color: theme.palette.primary.blackcoral,fontWeight:'600'}}>{camelCaseForce(item.institutionName)}</Typography>
                                </TableCell>
                                <TableCell  >
                                <Typography style={{color: theme.palette.primary.blackcoral, fontWeight:'600'}}>{camelCaseForce(item.accountNickname)}</Typography>
                                </TableCell>
                             
                             
                              
                            </TableRow>
                        )
                        
                        }
                      })}
            </TableBody>
            </Table>
    
    
    
        
                  <div style={{marginTop:25,alignSelf:'center'}}>
                  <Button variant='contained'style={{alignSelf:'center'}}
                  onClick={() => navigate('../insideautosubmit',{state:{budgetAccounts: budgetAccounts,allAccounts: allAccounts, primaryCheckingAccountBalance: primaryCheckingAccountBalance,
                  finCustomerId: finCustomerId,url:url, primaryCheckingAccountId: primaryCheckingAccountId}})}>Submit</Button>
                  </div>
             
                    <div style={{width:'100%', height:200}}>
                      <Typography style={{color:'transparent'}}>Hello</Typography>
                    </div>
            </div>
        )
    }

    
  

}
 
}