

import { BudgetContextTwo } from 'contexts/BudgetContextTwo';

import  BudgetEvenLogo from '../../pages/landingpage/landingimages/budgeteven.svg'
import { collection, doc, getFirestore, setDoc, getDoc, getDocs, updateDoc,arrayUnion} from "firebase/firestore";

import { generateRandomString } from 'utils/Helpers';
import { getAuth} from 'firebase/auth';
import { getCurrentDayDigit, getCurrentMonthAndYearString,getTodaysEpoch } from 'utils/Helpers';
import { getFunctions, httpsCallable } from "firebase/functions";
import OnBoardingContinueCard from 'pages/onboarding/ErrorCards/OnBoardingContinueCard';
import OnBoardingMenu from 'pages/onboarding/MenuPopover/OnBoardingMenu';
import React,{useEffect,useContext,useState,useRef} from 'react'
import RiveMainLoading from 'rivefiles/RiveMainLoading';
import { updatedGetLastMonthAndYearString } from 'utils/Helpers';
import { useNavigate } from 'react-router';
import { checkOnboardingStatusHelper, createFinCustomerIdHelper, updateUserDocument } from 'utils/UserMethods';
import { uploadError } from 'utils/FirebaseAnalyticsMethods';

const db = getFirestore();
const functions = getFunctions();



export default function MainLoading () {

  const topUserUid = getAuth().currentUser.uid
  const navigate = useNavigate();


  const {toggleIsPremiumUser,toggleFinicityId} = useContext(BudgetContextTwo)


  const localFinicityId = useRef('nil')

  const [showOnBoardingContinueCard,setShowOnBoardingContinueCard] = useState(false)



    useEffect(() => {
        checkOnboardingStatus();    
        },[])

    useEffect(() => {
      document.body.style.backgroundColor = 'white'
    },[])
    

        async function checkOnboardingStatus(){

              try{
                let onBoardingResult = await checkOnboardingStatusHelper();
                
                if(onBoardingResult !== false){
                  if(onBoardingResult.isPremiumUser){
                    toggleIsPremiumUser(true)
                    toggleFinicityId(onBoardingResult.finicityId)
                    localFinicityId.current = onBoardingResult.finicityId
                  }
                 
  
                  if(onBoardingResult.currentStep === -47){
                    handleCurrent47Step(onBoardingResult,onBoardingResult.isPremiumUser)
                  }
                  else {
                    handleNon47Step(onBoardingResult,onBoardingResult.isPremiumUser)
                  }
                
                }
              }catch(e){
                uploadError(e.message,'MainLoading, checkOnboardingStatus')
              }
           
     
            }

            function handleCurrent47Step(onBoardingResult,isPremiumUser){
              
              try{
                if(isPremiumUser){
  
                  if(typeof onBoardingResult.lastTransactionScanEpoch !== 'undefined'){
                    if(onBoardingResult.lastTransactionScanEpoch !== ''){
                
                 
                      let scanBasedUponDigit = false
                      if(onBoardingResult.lastScanDigit !== 'nil'){
                        let today = getCurrentDayDigit();
                     
                        if(String(today) !== String(onBoardingResult.lastScanDigit)){
                          scanBasedUponDigit = true
                        }
                      }
                    
                   
                      if(scanBasedUponDigit){
                      
               
                        navigate('/autotransactionscanning',{state:{lastScanDigit: onBoardingResult.lastScanDigit, lastTransactionScanEpoch:onBoardingResult.lastTransactionScanEpoch,
                          finCustomerId: onBoardingResult.finicityId,budgetMethod: onBoardingResult.budgetMethod}})
                      }
                      else {
                  
                        checkIfBudgetMonthExists();
                      }
                    }
                    else{
                      navigate('/autotransactionscanning',{state:{lastScanDigit: onBoardingResult.lastScanDigit, finCustomerId: onBoardingResult.finicityId,budgetMethod: onBoardingResult.budgetMethod}})
                    }
                  }
              }

            else if(!isPremiumUser) {
            
              navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
          
            }
              
              }catch(e){
                uploadError(e.message,'MainLoading.js, handleCurrent47Step')
              }
           

            }

            function handleNon47Step(onBoardingResult){

                let currentStep = onBoardingResult.currentStep
                let id = onBoardingResult.finicityId
               localFinicityId.current = id;
            
                if(onBoardingResult.budgetMethod === 'Auto'){
                  determineAutoNavigation(currentStep,id,onBoardingResult.autoCurrentStep)
                }
                else if(onBoardingResult.budgetMethod === 'Semi-Auto'){
                  determineAutoNavigation(currentStep,id)
                }
  
             
               
                else {
                
                    switch(currentStep){
                                          case 0:
                                          
                                            navigate('/welcome')
                                            break;
  
                                            case 1:
                                      
                                          
                                              navigate('/getstarted')
                                              break;
  
                                              case 2:
                                          
                                            
                                                navigate('/getstarted')
                                                break;
  
                                              case 3:
                                            
                                           
                                                navigate('/getstarted')
                                                break;
  
  
  
                                                case 4:
                                              
                                         
                                                  navigate('/getstarted')
                                              
                                                  break;
  
                                                  case 5:
                                              
                                              
  
                                                      navigate('/getstarted')
                                                  break;
  
                                                  case 6:
                                              
                                            
                                                  navigate('/getstarted')
                                                  break;
  
                                                  case 7:
                                              
                                                  navigate('/budgetrecap',{state:{finCustomerId:id}})
                                                  break;
  
                                                  case 8:
                                              
                                                  navigate('/autopricing',{state:{finCustomerId:id}})
                                                  break;
  
                                                  case 'InsideSubscribedNoIdYet':
                                                  createIdAndUrl();
                                                  break;
  
                                                  case 'InsideFinicityPage':
                                                    getFinUrl(id,'Finicity')
                                                    break;
  
                                                  case 'InsideFinicityLoading':
                                                    getFinUrl(id,'FinicityLoading')
                                                    break;
  
                                                    case 'InsideAutoSubmit':
                                                      getFinUrl(id,'FinicityLoading')
                                                      break;
  
                                                      case 'InsideAutoSubmitLoadingScreen':
                                                        getFinUrl(id,'FinicityLoading')
                                                        break;
  
                                                    case 'InsideBudgetRecap':
                                                 
                                                      navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                                                      break;
  
                                                  case -47:
                                                 
                                                    navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                                                    break;
  
  
                                                  
  
                                                    default:
                                                    
                                                      navigate('/')
                                        }
                }
                 
              
            }


            function determineAutoNavigation(currentStep,finicityId,autoCurrentStep){
              let id = finicityId;
              if(autoCurrentStep === 'nil'){
                //rely on current step
                switch(currentStep){
                  case 0:
                  
                    navigate('/welcome')
                    break;

                    case 1:
              
                  
                      navigate('/getstarted')
                      break;

                      case 2:
                  
                    
                        navigate('/getstarted')
                        break;

                      case 3:
                    
                   
                        navigate('/getstarted')
                        break;



                        case 4:
                      
                 
                          navigate('/getstarted')
                      
                          break;

                          case 5:
                      
                      

                              navigate('/getstarted')
                          break;

                          case 6:
                      
                    
                          navigate('/getstarted')
                          break;

                          case 7:
                      
                          navigate('/budgetrecap',{state:{finCustomerId:id}})
                          break;

                          case 8:
                      
                          navigate('/autopricing',{state:{finCustomerId:id}})
                          break;

                          case 'InsideSubscribedNoIdYet':
                          createIdAndUrl();
                          break;

                          case 'InsideFinicityPage':
                            getFinUrl(id,'Finicity')
                            break;

                          case 'InsideFinicityLoading':
                            getFinUrl(id,'FinicityLoading')
                            break;

                            case 'InsideAutoSubmit':
                              getFinUrl(id,'FinicityLoading')
                              break;

                              case 'InsideAutoSubmitLoadingScreen':
                                getFinUrl(id,'FinicityLoading')
                                break;

                            case 'InsideBudgetRecap':
                         
                              navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                              break;

                          case -47:
                         
                            navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                            break;

                            case 'FinicityPortal':
                 
                            setShowOnBoardingContinueCard(true)
                            break;
        
                            case 'autoFinicityLoading':
                            
                            getFinUrl(finicityId,'finicityLoading')
                              break;
        
                              case 'autoSubmit':
                             
                              getFinUrl(finicityId,'autoSubmit')
                                break;
        
                                case 'autoSubmitLoading':
                              
                                getFinUrl(finicityId,'autoSubmitLoading')
                                  break;
                          

                            default:
                            
                              navigate('/')
                }
              }

              else if( currentStep === -47){
                navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
              }
              else{


                switch(autoCurrentStep){
                  case 'NeedFinicityId':
                    needFinicityId();
                    break;

                    case 'IdSetGettingFinUrl':
                 
                      setShowOnBoardingContinueCard(true)
                      break;

                    case 'FinicityPortal':
                 
                    setShowOnBoardingContinueCard(true)
                    break;

                    case 'autoFinicityLoading':
                    
                    getFinUrl(finicityId,'finicityLoading')
                      break;

                      case 'autoSubmit':
                     
                      getFinUrl(finicityId,'autoSubmit')
                        break;

                        case 'autoSubmitLoading':
                      
                        getFinUrl(finicityId,'autoSubmitLoading')
                          break;

                          case 'UpgradeFromSemiAutoToAutoFirstStep':
                
                              setShowOnBoardingContinueCard(true)
                              break;



               
                          default: 
                          navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
                }
              }
          } 
            


            async function createIdAndUrl(){

              let customerId = await createFinCustomerIdHelper();
       
              if(customerId === 'error' || customerId === null){
                //report error
              }
              else {
                let userDoc = await updateUserDocument({isPremiumUser:true,finCustomerId:customerId, subscriptionMethod: 'web', newSubNeedsSetUp:true})
                
                if(userDoc){
                  let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
                  let urlResult = await retrieveUrl({customerId: customerId}).then(function(result){
  
                      return result;
                  }).catch(function(error){
                   
                      return false;
                  })
                  navigate('/autofinicity',{state:{url:urlResult.data.theUrl,finCustomerId: customerId}})
                }else {
                  //report error
                }
                   
                   

             
                   
              }
      
         
            
            }

     

          async function finicityPortal(){
            setShowOnBoardingContinueCard(false);
            let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
            let urlResult = await retrieveUrl({customerId: localFinicityId.current}).then(function(result){
       
     
                return result;
            }).catch(function(error){
            
                return false;
            })

            navigate('/finicityconnect',{state:{url:urlResult.data.theUrl,finCustomerId: localFinicityId.current}})
          }

          async function getAccountsUpdated(finCustomerId,url,route){

            
            let retrieveBalances = httpsCallable(functions,'getFastFinAccounts');
          
              let finAccountsArray = await retrieveBalances({customerId: finCustomerId}).then(function(result) {
            
                return result.data.accounts;
              }).catch(function(error){
            
                return false;
              })
          
          
              if(finAccountsArray === false || finAccountsArray.length === 0){
                //error
           
              }
              else {
          
                let hasChecking = false
                let hasCreditCard = false
          
                for(let i = 0; i < finAccountsArray.length; i++){
                  if(finAccountsArray[i].type === 'checking'){
                    hasChecking = true
                  }
                  if(finAccountsArray[i].type === 'creditCard'){
                    hasCreditCard = true
                  }
                }
          
          
          
                for(let i = 0; i < finAccountsArray.length; i++){
                    let getInstitutionName = httpsCallable(functions,'retrieveInstitutionName')
              
                  let realName = await getInstitutionName({institutionId: finAccountsArray[i].institutionId}).then((res) => {
                       
                     
                       return res.data.name[0]
                   }).catch((error) => {
                 
                       return '';
                   })
          
                   finAccountsArray[i].institutionName = realName
                  
                }
          
               
                const uid = getAuth().currentUser.uid;
                for(let i = 0; i < finAccountsArray.length; i++){
                  let myObject = {...finAccountsArray[i], isFinicity:true}
                  let accountDocRef = doc(db,'EuklesUsersData',uid, 'Accounts',String(finAccountsArray[i].id))
                  await setDoc(accountDocRef,myObject)
               
              }
          
                let filteredBudgetAccounts = []
          
                  let currentCheckingArray = []
                  for(let i = 0; i < finAccountsArray.length; i++){
                  
                    if(finAccountsArray[i].type.toLowerCase() === 'checking'){
                      let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
                      accountTransactionsArray:[]}
                      filteredBudgetAccounts.push(obj)
                        currentCheckingArray.push(finAccountsArray[i])
                        
                    }
                    if(finAccountsArray[i].type.toLowerCase() === 'creditcard'){
                      let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
                        accountTransactionsArray:[]}
                        filteredBudgetAccounts.push(obj)
                    }
          
                    if(finAccountsArray[i].type.toLowerCase() === 'savings'){
                      let obj = {...finAccountsArray[i], errorRetrievingTransactions:false, isLoadingTransactions: true, numberOfTransactions: 0,
                        accountTransactionsArray:[]}
                        filteredBudgetAccounts.push(obj)
                    }
                  }
          
           
                  let primaryCheckingAccountId = ''
                  let primaryCheckingAccountBalance = 50
                  if(currentCheckingArray.length === 1){
                    primaryCheckingAccountId = currentCheckingArray[0].id
                    primaryCheckingAccountBalance = currentCheckingArray[0].balance
          
                  }
          
          
          
                  if(route === 'autoSubmit'){
                    navigate('/autosubmit',{state:{budgetAccounts: filteredBudgetAccounts,allAccounts: finAccountsArray, primaryCheckingAccountBalance: primaryCheckingAccountBalance,
                      finCustomerId: finCustomerId,url:url, primaryCheckingAccountId: primaryCheckingAccountId}})
                  }
                  else if(route === 'autoSubmitLoading'){
             //do same thing for AutoSubmitLoadingScreen
             const userUid = getAuth().currentUser.uid;
             let todayEpoch = getTodaysEpoch()
             let trackingCollection = collection(db,'EuklesUsersData',userUid,'TrackingRules')
             let trackingRules = await getDocs(trackingCollection).then((querySnapshot) => {
          
          
                 let localArray = []
                 querySnapshot.forEach((doc) => {
                   let myObject = {docId: doc.id, andLineItem: doc.data().andLineItem, thenTrackToPod: doc.data().thenTrackToPod, transactionBestRep: doc.data().transactionBestRep, transactionCategory: doc.data().transactionCategory,
                   transactionPayeeName: doc.data().transactionPayeeName}
                 
                   localArray.push(myObject)
                 })
                 return localArray
               
              
             }).catch(e => {
               return []
             })
                      navigate('/autosubmitloading',{state:{trackingRules:trackingRules, localBudgetAccounts: filteredBudgetAccounts, finCustomerId: finCustomerId}})
                  }
          
             
                  }
          
                  
               
           
            
              }

          async function getFinUrl (finCustomerId,routeName){
     
             
        
           
            let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
            let urlResult = await retrieveUrl({customerId: finCustomerId}).then(function(result){
       
     
                return result;
            }).catch(function(error){
            
                return false;
            })
       
            if(urlResult.data.theUrl === 'error getting url'){
                
              
            }
            else {
       
               
                switch(routeName){
                  case 'finicityLoading':
                    navigate('/finicityloading',{state:{comingFrom: 'MainLoading',url: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                    break;
                    case 'autoSubmit':
                      //navigate('/autosubmit',{state:{comingFrom: 'MainLoading',url: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                      getAccountsUpdated(finCustomerId,urlResult.data.theUrl,'autoSubmit')
                      break;
                      case 'autoSubmitLoading':
                        //navigate('/autosubmit',{state:{comingFrom: 'MainLoading',url: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                        getAccountsUpdated(finCustomerId,urlResult.data.theUrl,'autoSubmitLoading')
                        break;
                  case 'InsideFinicityPage':
                    navigate('/dashboard/finicity',{state:{comingFrom: 'MainLoading',urlToUse: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                    break;
                    case 'InsideFinicityLoading':
                    navigate('/dashboard/finicity',{state:{comingFrom: 'MainLoading',urlToUse: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                    break;

                    case 'InsideAutoSubmit':
                      navigate('/dashboard/finicity',{state:{comingFrom: 'MainLoading',urlToUse: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                      break;


                    case 'InsideAutoSubmitLoadingScreen':
                      navigate('/dashboard/finicity',{state:{comingFrom: 'MainLoading',urlToUse: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                      break;


                      case 'UpgradeFromSemiAutoToAutoFirstStep':
                        navigate('/dashboard/upgradefsatap',{state:{comingFrom: 'MainLoading',urlToUse: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                        break;
                      //'./upgradefsatap'

                    default:
                      navigate('/dashboard/finicity',{state:{comingFrom: 'MainLoading',urlToUse: urlResult.data.theUrl,finCustomerId:finCustomerId}});
                      break;


                }
             
                
            }
       
       
        }

            function handleNavigateHome(){
              navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
            }

 
            async function needFinicityId(){
              let createFinMethod = httpsCallable(functions,'createFinCustomer');
           
      
              let userName = generateRandomString(12)
               
                  
              
              let customerId = await createFinMethod({username: userName}).then((res) => {
                  
                  return res.data.customerId;
              }).catch((err) => {
             
                  return 'error';
              });
          
        
              if(customerId === 'error' || customerId === null){
                
        
              }
              else {

                let userDoc = doc(db,'EuklesUsers',topUserUid)
                await updateDoc(userDoc,{
                    isPremiumUser: true,
                    finCustomerId: customerId, 
                    subscriptionMethod: 'web',
                    newSubNeedsSetUp:true,
                    autoCurrentStep:'IdSetGettingFinUrl'
                })
             
                   
                      let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl');
                      let urlResult = await retrieveUrl({customerId: customerId}).then(function(result){
                 
               
                          return result;
                      }).catch(function(error){
                       
                          return false;
                      })

             
                    navigate('/autofinicity',{state:{url:urlResult.data.theUrl,finCustomerId: customerId}})
              }
      
            }


            async function checkIfBudgetMonthExists(){
       
              let monthsDoc = doc(db,'EuklesUsersData',topUserUid,'MonthsWithBudgets','months')
              let budgetMonthsArray = await getDoc(monthsDoc).then(function(doc){
                  let theArray = doc.data().mArray;
                  
                  return theArray;
                }).catch((error) => {
                  //show alert and reload
                 
                  return [];
              });
              
              let currentMonth = getCurrentMonthAndYearString();
              let existingIndex = budgetMonthsArray.findIndex(obj => obj === currentMonth)
              if(existingIndex === -1){
    
                  
                  let currentMonth = getCurrentMonthAndYearString();
                  let lastMonth =  updatedGetLastMonthAndYearString();
                  let monthToCopy = lastMonth
                 // let currentMonthIndex = budgetMonthsArrays.findIndex(obj => obj === currentMonth)
                  let lastMonthIndex = budgetMonthsArray.findIndex(obj => obj === lastMonth)
                  if(lastMonthIndex === -1){
                      monthToCopy = budgetMonthsArray[budgetMonthsArray.length - 1];
                  }
                             
                       
                          let copyMonth = httpsCallable(functions,'copyBudgetFromAnotherMonth');
                      
                          let copyResult = await copyMonth({monthToCopy: monthToCopy, newMonth: currentMonth}).then(function(result) {
                          
                          return result;
                          }).catch(function(error){
                      
                          return error;
                          })
                          
                      
                          if(copyResult !== false){
                      
                            let altDoc = doc(db,'EuklesUsersData',topUserUid,'MonthsWithBudgets','months')
                            await updateDoc(altDoc,{mArray: arrayUnion(currentMonth)}).then(res => {
                                return true;
                                }).catch(e => {
                                return false;
                                })
    
                    
                            let userDoc = doc(db,'EuklesUsers',topUserUid)
                            await updateDoc(userDoc,{queryMonth: currentMonth})
                    
              }
    
              navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
          }
          else {
            navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
          }
      }
    
            async function checkIfSubscribedThroughStripe(){
              let docRef = doc(db,'EuklesUsers',topUserUid)
              let method = await getDoc(docRef).then(doc => {
                  return doc.data().subscriptionMethod
              })
              return method
            }
    

    
          
      if(showOnBoardingContinueCard){
        return(
          <div style={{marginTop:25,display:'flex',flexDirection:'row', alignSelf:'center',justifyContent:'center',alignContent:'center'}}>
            <OnBoardingContinueCard handleNavigateHome={handleNavigateHome} handleContinue={finicityPortal} checkIfSubscribedThroughStripe={checkIfSubscribedThroughStripe} />
          </div>
        )
      }
      else {
        return(
          <div style={{display:'flex',flexDirection:'column', flex:1, justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
              <div style={{position:'absolute',top:15,right:25}}>
          <OnBoardingMenu navigate={navigate} />
        </div>
          <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
<RiveMainLoading />

</div>
        
     
      )
      }
    


}

