
import { Ellipsis} from 'react-spinners-css'
import { getFunctions, httpsCallable } from "firebase/functions";
import { Grid, Backdrop, Typography,useTheme} from '@mui/material';
import React,{useEffect, useState,useRef} from 'react'
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";

import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth} from "firebase/auth";
import { getCurrentMonthAndYearString,getTodaysEpoch} from "utils/Helpers";
import { collection, doc, getFirestore, setDoc, getDoc, updateDoc, addDoc,increment } from "firebase/firestore";

import { getLastMonthEndingDayEpoch } from "utils/Helpers";
import { getMonthAndYearStringFromEpoch } from "utils/Helpers";

const db = getFirestore();
const functions = getFunctions();

export default function InsideAutoSubmitLoading () {

    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const finCustomerId = location.state.finCustomerId
    const currentMonth = getCurrentMonthAndYearString()
    const userUid = getAuth().currentUser.uid
   
  

    const trackingRulesRef = useRef(location.state.trackingRules)
    const newTrackingRulesRef = useRef([])


    const [incomePod, setIncomePod] = useState({title:'Income',transactionsArray:[],data:[{description:'Paychecks',plannedValue:0.00, spentValue:0.00, podName:'Income'},
{description:'Interest',plannedValue:0.00, spentValue:0.00, podName:'Income'},
{description:'Credits',plannedValue:0.00, spentValue:0.00, podName:'Income'},
{description:'Deposits',plannedValue:0.00, spentValue:0.00, podName:'Income'},
{description:'Checks',plannedValue:0.00, spentValue:0.00, podName:'Income'},

]})

     //SAVINGS
     const [savingsPod, setSavingPod] = useState({title:'Savings',transactionsArray:[],data:[{description:'Savings',plannedValue:0.00, spentValue:0.00}]})

    
    //ENTERTAINMENT
const [entertainmentPod, setEntertainmentPod] = useState({title:'Entertainment',transactionsArray:[], data:[{description:'Alcohol and Bars',plannedValue:0.00, spentValue:0.00, podName:'Entertainment'},
{description:'Media',plannedValue:0.00, spentValue:0.00, podName:'Entertainment'},
{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Entertainment'},
]})

//EDUCATION
const [educationPod, setEducationPod] = useState({title:'Education',transactionsArray:[],data:[{description:'Tuition',plannedValue:0.00, spentValue:0.00, podName:'Education'},
{description:'Books and Supplies',plannedValue:0.00, spentValue:0.00, podName:'Education'},
{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Education'},
]})


//FINANCIAL
const [financialPod, setFinancialPod] = useState({title:'Financial',transactionsArray:[],data:[{description:'Fees and Charges',plannedValue:0.00, spentValue:0.00, podName:'Financial'},
{description:'Taxes',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
{description:'ATM',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
{description:'Life Insurance',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
{description:'Loans',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
{description:'Checks',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
{description:'Transfers',plannedValue:0.00, spentValue:0.00, podName:'Financial'} ]})

//FOOD AND DRINK
const [foodAndDrinkPod, setFoodAndDrinkPod] = useState({title:'Food and Drink',transactionsArray:[],data:[{description:'Groceries',plannedValue:0.00, spentValue:0.00, podName:'Food and Drink'},
{description:'Coffee',plannedValue:0.00, spentValue:0.00, podName:'Food and Drink'},
{description:'Dining Out',plannedValue:0.00, spentValue:0.00, podName:'Food and Drink'},

]})

//GIFTS AND CHARITY
const [giftsAndCharityPod, setGiftsAndCharityPod] = useState({title:'Gifts and Charity',transactionsArray:[],data:[{description:'Donations',plannedValue:0.00, spentValue:0.00, podName:'Gifts and Charity'},
{description:'Gifts',plannedValue:0.00, spentValue:0.00, podName:'Gifts and Charity'}]})

//HOUSING
const [housingPod, setHousingPod] = useState({title:'Housing',transactionsArray:[],data:[{description:'Mortgage or Rent',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
{description:'Internet and Phone',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
{description:'Property Tax',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
{description:'Maintenance',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
{description:'Utilities',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
{description:'Insurance',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
]})




//KIDS
const [kidsPod, setKidsPod] = useState({title:'Kids',transactionsArray:[],data:[{description:'Child Care',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
{description:'Child Support',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
{description:'Toys',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
]})


//MEDICAL
const [medicalPod, setMedicalPod] = useState({title:'Medical',transactionsArray:[],data:[{description:'Dentist',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Doctor',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Eyecare',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Health Insurance',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Pharmacy',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
]})




const [mobilePaymentsPod, setMobilePaymentsPod] = useState({title:'Medical',transactionsArray:[],data:[{description:'Apple Cash',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Cash App',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Paypal',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Venmo',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
{description:'Zelle',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
]})




//PETS
const [petsPod, setPetsPod] = useState({title:'Pets',transactionsArray:[],data:[{description:'Food and Care',plannedValue:0.00, spentValue:0.00, podName:'Pets'},
{description:'Veterinary',plannedValue:0.00, spentValue:0.00, podName:'Pets'},
{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Pets'},

]})

//PESONAL
const [personalPod, setPersonalPod] = useState({title:'Personal',transactionsArray:[],data:[{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
{description:'Health and Gym',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
{description:'Shopping',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
{description:'Phone',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
{description:'Clothing',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
{description:'Tuition',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
]})


//TRANSPORTATION
const [transportationPod, setTransportationPod] = useState({title:'Transportation',transactionsArray:[],data:[{description:'Gas',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
{description:'Parking',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
{description:'Insurance',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
{description:'Auto Payment',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
{description:'Maintenance',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
{description:'Other',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
]})

//TRAVEL
const [travelPod, setTravelPod] = useState({title:'Travel',transactionsArray:[],data:[{description:'Air Travel', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
{description:'Hotel', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
{description:'Vacation', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
{description:'Other', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
   ]})



//UNCATEGORIZED
const [uncategorizedPod, setUncategorizedPod] = useState({title:'Uncategorized',transactionsArray:[],data:[{
    description:'Uncategorized',plannedValue:0.00, spentValue:0.00, podName:'Uncategorized'
}]})



const [trackingRules, setTrackingRules] = useState(location.state.trackingRules)


    useEffect(() => {
    
        
   
            getCurrentMonthTransactions()

          
           // submitAnalytics();
            updateStep(6)
        updateAnalytics();
       
        },[])

        async function updateStep(step){
            let uid = getAuth().currentUser.uid
            let docRef = doc(db,'EuklesUsers',uid)
            await updateDoc(docRef,{currentStep: 'InsideAutoSubmitLoadingScreen'})
          }
    

        // async function submitAnalytics(){
        //     let plat = Platform.OS === 'ios' ? 'ios' : 'android'
        //     await analytics().logEvent('aa_userOnAutoSubmitLoadingScreen',{
        //         platform: plat,
                
        //     })
        // }
 
        async function updateAnalytics(){
            const analytics = getAnalytics();
            logEvent(analytics,'bb_web_auto_submit_loading_screen')
          }
          

  


async function getCurrentMonthTransactions(){
    let endingEpoch = getTodaysEpoch()

    //if it doesn't exist then create planned and spent, otherwise just updated spent
   
    let beginningEpoch = getLastMonthEndingDayEpoch()

   // getMonthToDateTransactions({customerId:'5023103839',beginningEpoch:beginningEpoch, endingEpoch: endingEpoch})

    let getTransactions = httpsCallable(functions,'getMonthToDateTransactions');
    let rawTransactionArray = await getTransactions({customerId: finCustomerId,  beginningEpoch: beginningEpoch, endingEpoch: endingEpoch}).then((result) => {
           
  
         
           return result.data.newTransactionArray
       }).catch((error) => {
           console.log(error);
           return 'error';
       })

       let localBudgetAccounts = [...location.state.localBudgetAccounts]
      



       let refinedArray = []
       for(let i = 0; i < rawTransactionArray.length; i++){
            let existingIndex = localBudgetAccounts.findIndex(obj => String(obj.id) === String(rawTransactionArray[i].accountId))
            if(existingIndex !== -1){
             
                refinedArray.push(rawTransactionArray[i])
            }
       }




      getTrackingRules(refinedArray)
    }

    //get rules from parameters when live
    async function getTrackingRules(rawTransactionArray){
        
 

        let todayEpoch = getTodaysEpoch()


        

        let matchArray = []
        let submitTransactionsArray =[]
        let updateSpentValuesArray = []
        let uncategorizedArray = []
 
    for(let i = 0; i < rawTransactionArray.length; i++){  
              let localRules = location.state.trackingRules
               
              let transaction = rawTransactionArray[i]
                if(transaction.categorization.category === 'Streaming Services'){
                   
                }
              let transCategory = ''
              let transBestRep = ''
              let transPayee = ''

              if(typeof transaction.categorization !== 'undefined'){
                if(typeof transaction.categorization.category !== 'undefined'){
                  transCategory  = transaction.categorization.category
                }
                if(typeof transaction.categorization.bestRepresentation !== 'undefined'){
                  transBestRep = transaction.categorization.bestRepresentation
                }
                if(typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
                  transPayee = transaction.categorization.normalizedPayeeName
                  if(transPayee === 'Netflix'){
                   
                  }
                }
              }

              
              let matchingCategoryObjects = localRules.filter(obj => obj.transactionCategory === transCategory)
      

              if(matchingCategoryObjects.length >= 1){

                let matchingBestRepIndex = matchingCategoryObjects.findIndex(obj => obj.transactionBestRep === transBestRep)

                if(matchingBestRepIndex === -1){
                  let matchingPayeeIndex = matchingCategoryObjects.findIndex(obj => obj.transactionPayeeName === transPayee)
                        if(matchingPayeeIndex !== -1){
                          matchArray.push(transaction)


                          submitTransactionsArray.push({transaction:transaction, podName: matchingCategoryObjects[matchingPayeeIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingPayeeIndex].andLineItem,
                          todayEpoch: todayEpoch, userUid: userUid })
                      
                          updateSpentValuesArray.push({transaction:transaction, podName: matchingCategoryObjects[matchingPayeeIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingPayeeIndex].andLineItem,
                            userUid: userUid })



                         
                        
                        }
                        else {
                            if(transPayee === 'Netflix'){
                             
                            }
                          uncategorizedArray.push(transaction)
                        }
                }


                else {
                  matchArray.push({transaction})
                  submitTransactionsArray.push({transaction:transaction, podName: matchingCategoryObjects[matchingBestRepIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingBestRepIndex].andLineItem,
                    todayEpoch: todayEpoch, userUid: userUid })

                
                  updateSpentValuesArray.push({transaction:transaction, podName: matchingCategoryObjects[matchingBestRepIndex].thenTrackToPod, lineItem: matchingCategoryObjects[matchingBestRepIndex].andLineItem,
                    userUid: userUid })



      
                
                }


              }
              else {
            
                uncategorizedArray.push(transaction)
              }


             
            }

           
            uploadCategorizedTransactions(submitTransactionsArray, uncategorizedArray)


    }
    
    async function uploadCategorizedTransactions(transactionsWithDetailsArray, uncategorizedArray){


   
        let todayEpoch = getTodaysEpoch()
       for(let i = 0; i < transactionsWithDetailsArray.length; i++){
        let transaction = transactionsWithDetailsArray[i].transaction
    
        let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
        let podName = transactionsWithDetailsArray[i].podName
        let lineItemDescription = transactionsWithDetailsArray[i].lineItem
        let trackingStatus = podName === 'Delete' ? 'Deleted' : 'Tracked'
  
       

         let myObject = {...transaction,podName: podName, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}
            let docRef = doc(db,'EuklesUsersData',userUid,'Transactions',String(transaction.id))
            await setDoc(docRef,myObject)


       

        
       }

       updateSpentValues(transactionsWithDetailsArray, uncategorizedArray)

    }


    async function updateSpentValues(spentValuesArray, uncategorizedArray){
      
     
        for(let i = 0; i < spentValuesArray.length; i++){
            let transaction = spentValuesArray[i].transaction
            let podName = spentValuesArray[i].podName
            let lineItemDescription = spentValuesArray[i].lineItem

            let monthYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
            let docRef = doc(db,'EuklesUsersData',userUid,monthYear,podName)

            let docCheck = await getDoc(docRef).then(doc => {
              if(doc.exists){
                      let docObjectArray = Object.keys(doc.data())
          
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                      return {docExists:true, fieldExists:false}
                    } else {
                      return {docExists:true, fieldExists:true}
                    }
              
              }
              else {
                return {docExists: false, fieldExists:false}
              }
             
            }).catch(e => {
              return {errorInDocCheck: true, errorMessage: e}
            })
        
           
        
            if(docCheck.docExists && docCheck.fieldExists){
          
              //const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(monthYear).doc(podName)

              let altDoc = doc(db,'EuklesUsersData',userUid,monthYear,podName)

                
              const fieldToUpdate = lineItemDescription.concat('.spentValue')
                
              await updateDoc(altDoc,{[fieldToUpdate]:increment(transaction.amount)})

            
        //    let updatedSpent =  await docRef.update({
        //         [fieldToUpdate]: firestore.FieldValue.increment(transaction.amount)
        //       }).then(res => {
        //         return true
        //       }).catch(e => {
        //         return {errorInDocRefUpdate: true, errorMessage: e}
        //       })
          
             
            }
          
            else if(docCheck.docExists && !docCheck.fieldExists){
                let subDoc = doc(db,'EuklesUsersData',userUid,monthYear,podName)
                await updateDoc(subDoc,{ [lineItemDescription]: {
                    plannedValue: parseFloat(0.00),
                    spentValue: parseFloat(transaction.amount),
                    podName: podName
                   }})

            //   await firestore().collection('EuklesUsersData').doc(userUid).collection(monthYear).doc(podName).update({
            //    [lineItemDescription]: {
            //     plannedValue: parseFloat(0.00),
            //     spentValue: parseFloat(transaction.amount),
            //     podName: podName
            //    }
               
            //   }).then(res => {
            //     return true;
            //   }).catch(e => {
            //     return {errorLine7404: true, errorMessage: e}
            //   })
            }
        }
       
       
       
     uncategorizedCreateSampleBudget(uncategorizedArray)
      
    }



    function uncategorizedCreateSampleBudget(transactions){
        //last month
    
      
      
    
        let deleteArray = []
        //delete array: buy, credit card payment
    
        let atmFeeArray = []
        let advertisingArray = []
        let airTravelArray = []
        let alcoholAndBarsArray = []
        let allowanceArray = []
        let amusementArray = []
        let artsArray = []
        let autoAndTransportArray = []
        let autoInsuranceArray = []
        let autoPaymentArray = []
    
        let babySuppliesArray = []
        let babysitterAndDaycareArray = []
        let bankFeeArray =[]
        let billsAndUtilitiesArray = []
        let bonusArray = []
        let booksArray = []
        let booksAndSuppliesArray = []
        let businessServicesArray = []
      
    
        let cashAndAtmArray = []
        let charityArray = []
        let checkArrayPositive = []
        let checkArrayNegative = []
        let childSupportArray = []
        let clothingArray = []
        let coffeShopsArray = []
    
        let dentistArray = []
        let depositArray =[]
        let doctorArray = []
    
        let educationArray = []
        let electronicsAndSoftwareArray = []
        let entertainmentArray = []
        let eyecareArray = []
    
        let fastFoodArray = []
        let federalTaxArray = []
        let feesAndChargesArray = []
        let financeChargeArray = []
        let financialArray = []
        let financialAdvisorArray = []
        let foodAndDiningArray = []
        let furnishingsArray = []
    
        let gasAndFuelArray = []
        let giftArray = []
        let giftsAndDonationsArray = []
        let groceriesArray = []
        let gymArray = []
    
        let hairArray = []
        let healthAndFitnessArray = []
        let hobbiesArray = []
        let homeArray = []
        let homeImprovementArray = []
        let homeInsuranceArray = []
        let homePhoneArray = []
        let homeServicesArray = []
        let homeSuppliesArray = []
        let hotelArray = []
    
        let incomeArray =[]
        let interestArray = []
        let internetArray = []
        let investmentsArray = []
    
        let kidsArray = []
        let kidsActivitiesArray = []
        // "Late Fee","Laundry","Lawn & Garden","Legal","Life Insurance",
        // "Loan Fees and Charges","Loan Insurance","Loan Interest","Loan Payment","Loan Principal","Loans","Local Tax","Low Balance",
        let lateFeeArray = []
        let laundryArray = []
        let lawnAndGardenArray = []
        let legalArray = []
        let lifeInsuranceArray = []
        let loanFeesAndChargesArray = []
        let loanInsuranceArray = []
        let loanInterestArray = []
        let loanPaymentArray = []
        let loanPrincipalArray = []
        let loansArray = []
        let localTaxArray = []
        let lowBalanceArray = []
    
        // "Mobile Phone","Mortgage & Rent","Movies & DVDs","Music",
        let mobilePhoneArray = []
        let mortgageAndRentArray = []
        let moviesAndDvdsArray = []
        let musicArray = []
    
        //"Newspapers & Magazines",
        let newspapersAndMagazinesArray = []
    
        //O 
        let officeSuppliesArray = []
    
        //P "Parking","Paycheck","Personal Care","Pet Food & Supplies","Pet Grooming","Pets","Pharmacy","Printing","Property Tax","Public Transportation",
        let parkingArray = []
        let paycheckArray = []
        let personalCareArray = []
        let petFoodAndSuppliesArray = []
        let petGroomingArray = []
        let petsArray = []
        let pharmacyArray = []
        let printingArray = []
        let propertyTaxArray = []
        let publicTransportationArray = []
    
        //R "Reimbursement","Rental Car & Taxi", "Restaurants",
        let reimbursementArray = []
        let rentalCarAndTaxiArray = []
        let restaurantsArray = []
    
        //S "Sales Tax","Sell","Services & Parts","Service Fee","Shipping","Shopping","Spa & Massage","Sporting Goods","Sports","State Tax","Streaming Services","Student Loan",
        let salesTaxArray = []
        let sellArray = []
        let servicesAndPartsArray = []
        let serviceFeeArray = []
        let shippingArray = []
        let shoppingArray = []
        let spaAndMassageArray = []
        let sportingGoodsArray = []
        let sportsArray = []
        let stateTaxArray = []
        let streamingServicesArray = []
        let studentLoanArray = []
    
        //T   "Taxes","Television","Toys","Trade Commissions","Transfer","Transfer for Cash Spending","Travel","Tuition",
        let taxesArray = []
        let televisionArray = []
        let toysArray = []
        let tradeCommissionsArray = []
        let transferArray = []
        let transferForCashSpendingArray = []
        let travelArray = []
        let tuitionArray = []
    
        //U "Uncategorized","Utilities","Vacation","Veterinary"
        let uncategorizedArray = []
        let uncategorizedCreditsArray = []
        let uncategorizedQuestionsArray = []
        let utilitiesArray = []
        let vacationArray = []
        let veterinaryArray = []
    
    
        let localQuestionsArray = []
        
    
       for(let i = 0; i < transactions.length; i++){
    
            if(typeof transactions[i].categorization !== 'undefined'){
                if(typeof transactions[i].categorization.category !== 'undefined'){
                    let transaction = transactions[i]
                    let category = transaction.categorization.category
              
                    //A
                        switch(category){
                            case "ATM Fee":
                                atmFeeArray.push(transaction)
                                break;
                            
                            case "Advertising":
                                advertisingArray.push(transaction)
                                break;
                            
                            case "Air Travel":
                                airTravelArray.push(transaction)
                                break;
                            
                            case "Alcohol & Bars":
                                alcoholAndBarsArray.push(transaction)
                                break;
                            
                            case "Allowance":
                                if(transaction.amount > 0){
                                    allowanceArray.push(transaction)
                                }
                               
                                break;
    
                            case "Amusement":
                                amusementArray.push(transaction)
                                break;
                            
                            case "Arts":
                                artsArray.push(transaction)
                                break;
                            
                            case "Auto & Transport":
                                autoAndTransportArray.push(transaction)
                                break;
                            
                            case "Auto Insurance":
                                autoInsuranceArray.push(transaction)
                                break;
    
                            case "Auto Payment":
                                autoPaymentArray.push(transaction)
                                break;
                            
                            default:
                               // console.log('')
                                break;
    
                            
                        }
    
    
                    //B
                    // "Baby Supplies","Babysitter & Daycare","Bank Fee","Bills & Utilities","Bonus","Books","Books & Supplies","Business Services","Buy",
                    //deleting buy,busines services
                        switch(category){
                            case 'Baby Supplies':
                                babySuppliesArray.push(transaction)
                                break;
    
                            case 'Babysitter & Daycare':
                                babysitterAndDaycareArray.push(transaction)
                                break;
    
                            case 'Bank Fee':
                                bankFeeArray.push(transaction)
                                break;
    
                            case 'Bills & Utilities':
                                // case 'Electronics & Software':
                                //     electronicsAndSoftwareArray.push(transaction);
                                //     break;
    
                                if(typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
                                    if(transaction.categorization.normalizedPayeeName === 'Apple'){
                                        electronicsAndSoftwareArray.push(transaction)
                                    }
                                    else {
                                        billsAndUtilitiesArray.push(transaction)
                                    }
                                }
                              
                                break;
    
                            case 'Bonus':
                                if(transaction.amount > 0){
                                    bonusArray.push(transaction)
                                }
                       
                                break;
    
                            case 'Books':
                                booksArray.push(transaction)
                                break;
    
                            case 'Books & Supplies':
                                booksAndSuppliesArray.push(transaction)
                                break;
    
                            case 'Business Services':
                                businessServicesArray.push(transaction)
                                break;
                            
                            case 'Buy':
                                //businessServicesArray.push(transaction)
                                deleteArray.push(transaction)
                                break;
                            
                            default:
                                break;
                        }
    
                    //C  "Cash & ATM","Charity","Check","Child Support","Clothing","Coffee Shops","Credit Card Payment",
                    //deleting credit cardy payment
                    switch(category){
                        case 'Cash & ATM':
                            cashAndAtmArray.push(transaction)
                            break;
                        
                        case 'Charity':
                            charityArray.push(transaction)
                            break;
                        
                        case 'Check':
                            //checkArray.push(transaction)
                            if(transaction.amount > 0){
                                checkArrayPositive.push(transaction)
                            } else {
                                checkArrayNegative.push(transaction)
                            }
                          
                        
                            break;
    
                        case 'Child Support':
                            childSupportArray.push(transaction)
                            break;
                        
                        case 'Clothing':
                            clothingArray.push(transaction)
                            break;
    
                        case 'Coffee Shops':
                            coffeShopsArray.push(transaction)
                            break;
    
                        case 'Credit Card Payment':
                            deleteArray.push(transaction)
                            break;
    
                        default:
                            break;
                    }
    
                    //D  "Dentist","Deposit","Dividend & Cap Gains", "Doctor",
                    //deleting dividend and cap gains
                    switch(category){
                        case 'Dentist':
                            dentistArray.push(transaction)
                            break;
                        
                        case 'Deposit':
                            depositArray.push(transaction)
                            break;
    
                        case 'Dividend & Cap Gains':
                            deleteArray.push(transaction)
                            break;
    
                        case 'Doctor':
                            doctorArray.push(transaction)
                            break;
                        
                        default:
                            break;
                    
    
                    }
    
                    //E   "Education","Electronics & Software","Entertainment","Eyecare",
    
                    switch(category){
                        case 'Education':
                            educationArray.push(transaction);
                            break;
                        
                        case 'Electronics & Software':
                            electronicsAndSoftwareArray.push(transaction);
                            break;
    
                        case 'Entertainment':
                            entertainmentArray.push(transaction);
                            break;
                        
                        case 'Eyecare':
                            eyecareArray.push(transaction);
                            break;
                        
                        default:
                            break;
    
                    }
    
                    //F    "Fast Food","Federal Tax","Fees & Charges","Finance Charge","Financial","Financial Advisor","Food & Dining","Furnishings",
    
                    switch(category){
                        case 'Fast Food':
                            fastFoodArray.push(transaction);
                            break;
    
                        case 'Federal Tax':
                            federalTaxArray.push(transaction)
                            break;
                        
                        case 'Fees & Charges':
                            feesAndChargesArray.push(transaction)
                            break;
    
                        case 'Finance Charge':
                            financeChargeArray.push(transaction)
                            break;
    
                        case 'Financial':
                            ///financialArray.push(transaction)
                            localQuestionsArray.push({category:'Financial',transaction: transaction})
                            break;
    
                        case 'Financial Advisor':
                            financialAdvisorArray.push(transaction)
                            break;
    
                        case 'Food & Dining':
                            foodAndDiningArray.push(transaction)
                            break;
    
                        case 'Furnishings':
                            furnishingsArray.push(transaction)
                            break;
                        
                        default:
                            break;
    
                    }
    
                    //G  "Gas & Fuel","Gift","Gifts & Donations","Groceries","Gym",
    
                    switch(category){
                        case 'Gas & Fuel':
                            gasAndFuelArray.push(transaction)
                            break;
    
                        case 'Gift':
                            if(transaction.amount < 0){
                                giftArray.push(transaction)
                            }
                            
                            break;
                        
                        case 'Gifts & Donations':
                            giftsAndDonationsArray.push(transaction)
                            break;
    
                        case 'Groceries':
                            groceriesArray.push(transaction)
                            break;
    
                        case 'Gym':
                            gymArray.push(transaction)
                            break;
    
                        default:
                            break;
                        
    
                    }
    
                    //H    "Hair","Health & Fitness","Hobbies","Home","Home Improvement","Home Insurance","Home Phone","Home Services","Home Supplies","Hotel",
                    
                    switch(category){
                        case 'Hair':
                            hairArray.push(transaction)
                            break;
                        
                        case 'Health & Fitness':
                            healthAndFitnessArray.push(transaction)
                            break;
                        
                        case 'Hobbies':
                            hobbiesArray.push(transaction)
                            break;
    
                        case 'Home':
                            homeArray.push(transaction)
                            break;
    
                        case 'Home Improvement':
                            homeImprovementArray.push(transaction)
                            break;
    
                        case 'Home Insurance':
                            homeInsuranceArray.push(transaction)
                            break;
    
                        case 'Home Phone':
                            homePhoneArray.push(transaction)
                            break;
    
                        case 'Home Services':
                            homeServicesArray.push(transaction)
                            break;
    
                        case 'Home Supplies':
                            homeSuppliesArray.push(transaction)
                            break;
    
                        case 'Hotel':
                            hotelArray.push(transaction)
                            break;
    
                        default:
                            break;
                    }
    
                    //I   "Income","Interest Income","Internet","Investments",
                    //deleting "Investments"
    
                    switch(category){
                        case 'Income':
                            incomeArray.push(transaction)
                            break;
    
                        case 'Interest Income':
                            interestArray.push(transaction)
                            break;
                        
                        case 'Internet':
                            internetArray.push(transaction)
                            break;
    
                        case 'Investments':
                            deleteArray.push(transaction)
                            break;
                        
                        default:
                            break;
    
                    }
    
                    //K "Kids","Kids Activities",
                    switch(category){
                        case 'Kids':
                            kidsArray.push(transaction)
                            break;
                        
                        case 'Kids Activities':
                            kidsActivitiesArray.push(transaction)
                            break;
                        
                        default:
                            break;
                    }
    
                    //L    "Late Fee","Laundry","Lawn & Garden","Legal","Life Insurance","Loan Fees and Charges","Loan Insurance","Loan Interest","Loan Payment","Loan Principal","Loans","Local Tax","Low Balance",
                    switch(category){
                        case 'Late Fee':
                            lateFeeArray.push(transaction)
                            break;
                        
                        case 'Laundry':
                            laundryArray.push(transaction)
                            break;
    
                        case 'Lawn & Garden':
                            lawnAndGardenArray.push(transaction)
                            break;
    
                        case 'Legal':
                            legalArray.push(transaction)
                            break;
                        
                        case 'Life Insurance':
                            lifeInsuranceArray.push(transaction)
                            break;
    
                        case 'Loan Fees and Charges':
                            loanFeesAndChargesArray.push(transaction)
                            break;
    
                        case 'Loan Insurance':
                            loanInsuranceArray.push(transaction)
                            break;
    
                        case 'Loan Interest':
                            loanInterestArray.push(transaction)
                            break;
    
                        case 'Loan Payment':
                            loanPaymentArray.push(transaction)
                            break;
    
                        case 'Loan Principal':
                            loanPrincipalArray.push(transaction)
                            break;
    
                        case 'Loans':
                            loansArray.push(transaction)
                            break;
    
                        case 'Local Tax':
                            localTaxArray.push(transaction)
                            break;
    
                        case 'Low Balance':
                            lowBalanceArray.push(transaction)
                            break;
    
                        default:
                            break;
                    }
    
                    //M  "Mobile Phone","Mortgage & Rent","Movies & DVDs","Music",
                    switch(category){
                        case 'Mobile Phone':
                            mobilePhoneArray.push(transaction)
                            break;
                        
                        case 'Mortgage & Rent':
                            mortgageAndRentArray.push(transaction)
                            break;
                        
                        case 'Movies & DVDs':
                            moviesAndDvdsArray.push(transaction)
                            break;
    
                        case 'Music':
                            musicArray.push(transaction)
                            break;
                        
                        default:
                            break;
                    }
    
                    //N "Newspapers & Magazines",
                    switch(category){
                        case 'Newspapers & Magazines':
                            newspapersAndMagazinesArray.push(transaction)
                            break;
                        
                        default:
                            break;
                    }
    
                    //O  "Office Supplies",
                    switch(category){
                        case 'Office Supplies':
                            officeSuppliesArray.push(transaction)
                            break;
    
                        default:
                            break;
    
    
                        
                    }
    
                    //P "Parking","Paycheck","Personal Care","Pet Food & Supplies","Pet Grooming","Pets","Pharmacy","Printing","Property Tax","Public Transportation",
                    switch(category){
                        case 'Parking':
                            parkingArray.push(transaction)
                            break;
    
                        case 'Paycheck':
                            paycheckArray.push(transaction)
                            break;
    
                        case 'Personal Care':
                            personalCareArray.push(transaction)
                            break;
    
                        case 'Pet Food & Supplies':
                            petFoodAndSuppliesArray.push(transaction)
                            break;
    
                        case 'Pet Grooming':
                            petGroomingArray.push(transaction)
                            break;
    
                        case 'Pets':
                            petsArray.push(transaction)
                            break;
    
                        case 'Pharmacy':
                            pharmacyArray.push(transaction)
                            break;
    
                        case 'Printing':
                            printingArray.push(transaction)
                            break;
    
                        case 'Property Tax':
                            propertyTaxArray.push(transaction)
                            break;
    
                        case 'Public Transportation':
                            publicTransportationArray.push(transaction)
                            break;
    
                        default:
                        break;
                    }
    
                   //R "Reimbursement","Rental Car & Taxi", "Restaurants",
                     switch(category){
                        case 'Reimbursement':
                            reimbursementArray.push(transaction)
                            break;
    
                        case 'Rental Car & Taxi':
                            rentalCarAndTaxiArray.push(transaction)
                            break;
    
                        case 'Restaurants':
                            restaurantsArray.push(transaction)
                            break;
    
                        default:
                        break;
                        
                     }
    
                    //S "Sales Tax","Sell","Services & Parts","Service Fee","Shipping","Shopping","Spa & Massage","Sporting Goods","Sports","State Tax","Streaming Services","Student Loan",
                    //deleting sell
                    switch(category){
                        case 'Sales Tax':
                            salesTaxArray.push(transaction)
                            break;
    
                        case 'Sell':
                            deleteArray.push(transaction)
                            break;
    
                        case 'Services & Parts':
                            servicesAndPartsArray.push(transaction)
                            break;
    
                        case 'Service Fee':
                            serviceFeeArray.push(transaction)
                            break;
    
                        case 'Shipping':
                            shippingArray.push(transaction)
                            break;
    
                        case 'Shopping':
                            shoppingArray.push(transaction)
                            break;
    
                        case 'Spa & Massage':
                            spaAndMassageArray.push(transaction)
                            break;
    
                        case 'Sporting Goods':
                            sportingGoodsArray.push(transaction)
                            break;
    
                        case 'Sports':
                            sportsArray.push(transaction)
                            break;
    
                        case 'State Tax':
                            stateTaxArray.push(transaction)
                            break;
    
                        case 'Streaming Services':
                            streamingServicesArray.push(transaction)
                            break;
    
                        case 'Student Loan':
                            studentLoanArray.push(transaction)
                            break;
                        
                        default:
                            break;
    
    
    
                    }
    
                    //T  "Taxes","Television","Toys","Trade Commissions","Transfer","Transfer for Cash Spending","Travel","Tuition",
                    switch(category){
                        case 'Taxes':
                            taxesArray.push(transaction)
                            break;
    
                        case 'Television':
                            televisionArray.push(transaction)
                            break;
    
                        case 'Toys':
                            toysArray.push(transaction)
                            break;
    
                        case 'Trade Commissions':
                            deleteArray.push(transaction)
                            break;
    
                        case 'Transfer':
                          
                            transferArray.push(transaction)
                            //localQuestionsArray.push({category:'Transfer',transaction: transaction})
                            break;
    
                        case 'Transfer for Cash Spending':
                            //transferArray.push(transaction)
                            deleteArray.push(transaction)
                            break;
    
                        case 'Travel':
                            travelArray.push(transaction)
                            break;
    
                        case 'Tuition':
                            tuitionArray.push(transaction)
                            break;
    
                        default:
                            break;
                        
                    }
    
                    //U and V "Uncategorized","Utilities","Vacation","Veterinary"
                    switch(category){
                        case 'Uncategorized':
                           
                            if(transaction.amount > 0){
                                uncategorizedCreditsArray.push(transaction)
                            }
                            else {
                                //uncategorizedArray.push(transaction)
                                uncategorizedQuestionsArray.push(transaction)
                                localQuestionsArray.push({category:'Uncategorized',transaction: transaction})
                            }
                           
                   
                            break;
    
                        case 'Utilities':
                            utilitiesArray.push(transaction)
                            break;
    
                        case 'Vacation':
                            vacationArray.push(transaction)
                            break;
    
                        case 'Veterinary':
                            veterinaryArray.push(transaction)
                            break;
    
                        default:
                            break;
                    }
    
    
    
    
    
    
    
                }
            }
       }
       
    
       //sort transfers
       //description
       //best representation
       //see what's left over 
    
       let cashAppArrayNegative = []
       let cashAppArrayPositive = []
       let appleCashArrayNegative = []
       let appleCashArrayPositive = []
       let paypalArrayNegative = []
       let paypalArrayPositive = []
       let venmoArrayNegative = []
       let venmoArrayPositive = []
       let zelleArrayNegative = []
       let zelleArrayPositive = []
       let unclassifiedTransferPositive = []
    
       
       for(let i = 0; i < transferArray.length;  i++){
        
        let classified = false
        let transaction = transferArray[i]
        let descriptionIncludesZelle = transaction.description.includes('Zelle') ? true : false
        let bestRepIncludesZelle = transaction.description.includes('Zelle') ? true : false
            if(descriptionIncludesZelle || bestRepIncludesZelle){
                classified = true
                if(transaction.amount > 0){
                    zelleArrayPositive.push(transaction)
                }
                else {
                    zelleArrayNegative.push(transaction)
                }
            } 
        let descriptionIncludesCashApp = transaction.description.includes('CASH APP') ? true : false
        let bestRepIncludesCashApp = transaction.description.includes('CASH APP') ? true : false
    
        if(descriptionIncludesCashApp || bestRepIncludesCashApp){
            classified = true
            if(transaction.amount > 0){
                cashAppArrayPositive.push(transaction)
            }else {
                cashAppArrayNegative.push(transaction)
            }
           
        }
       
    
    
        let descriptionIncludesAppleCash = transaction.description.includes('APPLE CASH') ? true : false
        let bestRepIncludesAppleCash = transaction.description.includes('APPLE CASH') ? true : false
    
        if(descriptionIncludesAppleCash || bestRepIncludesAppleCash){
            classified = true
            if(transaction.amount > 0){
                appleCashArrayPositive.push(transaction)
            }else {
                appleCashArrayNegative.push(transaction)
            }
           
        }
      
    
        let descriptionIncludesPayPal= transaction.description.includes('PAYPAL') ? true : false
        let bestRepIncludesPayPal = transaction.description.includes('PAYPAL') ? true : false
    
        if(descriptionIncludesPayPal || bestRepIncludesPayPal){
            classified = true
            if(transaction.amount > 0){
                paypalArrayPositive.push(transaction)
            }else {
                paypalArrayNegative.push(transaction)
            }
           
        }
       
    
        let descriptionIncludesVenmo= transaction.description.toLocaleLowerCase().includes('venmo') ? true : false
        let bestRepIncludesVenmo = transaction.description.toLocaleLowerCase().includes('venmo') ? true : false
    
        if(descriptionIncludesVenmo || bestRepIncludesVenmo){
            classified = true
            if(transaction.amount > 0){
                venmoArrayPositive.push(transaction)
            }else {
               venmoArrayNegative.push(transaction)
            }
           
        }
       
        if(!classified){
            //console.log('unclassified transaction ', transaction)
            //localQuestionsArray.push({category:'Transfer',transaction: transaction})
            if(transaction.amount > 0){
                unclassifiedTransferPositive.push(transaction)
            }
            else {
              localQuestionsArray.push({category:'Transfer',transaction: transaction})
            }
           
        }
    
    
       }
    
      
    
    //    for(let i = 0; i < uncategorizedQuestionsArray.length; i++){
    
    //    }
    
     
    
    
       //Skipping for now checkArray,transferArray,
      
       
       //analyzeCheckArray(checkArray)
       //analyzeDeposits(depositArray)
       //analyzeTransferArray(transferArray)
       //analyzeUncategorizedArray(uncategorizedArary)
    
       //BUSINESS
        //addToBusinessPod(advertisingArray,'Business','Advertising','Advertising')
    
        //EDUCATION
        addToEducationPod(booksArray,'Education','Books and Supplies','Books & Supplies')
       // addToEducationPod(booksAndSuppliesArray,'Education','Books and Supplies','Books & Supplies')
        addToEducationPod(booksAndSuppliesArray,'Education','Misc','Education')
        addToEducationPod(officeSuppliesArray,'Education','Misc','Office Supplies')
        addToEducationPod(educationArray,'Education','Misc','Education')
        addToEducationPod(tuitionArray,'Education','Tuition','Tuition')
    
        //ENTERTAINMENT
        addToEntertainmentPod(alcoholAndBarsArray,'Entertainment','Alcohol and Bars','Alcohol & Bars')
        addToEntertainmentPod(amusementArray,'Entertainment','Misc','Amusement')
        addToEntertainmentPod(artsArray,'Entertainment','Misc','Arts')
        addToEntertainmentPod(entertainmentArray,'Entertainment','Misc','Entertainment')
        addToEntertainmentPod(moviesAndDvdsArray,'Entertainment','Media','Movies & DVDs')
        addToEntertainmentPod(musicArray,'Entertainment','Media','Music')
        addToEntertainmentPod(newspapersAndMagazinesArray,'Entertainment','Media','Newspapers & Magazines')
        addToEntertainmentPod(streamingServicesArray,'Entertainment','Media','Streaming Services')
        addToEntertainmentPod(televisionArray,'Entertainment','Media','Television')
    
        
    
        //FINANCIAL
        addToFinancialPod(atmFeeArray,'Financial','Fees and Charges','ATM Fee')
        addToFinancialPod(bankFeeArray,'Financial','Fees and Charges','Bank Fee')
        addToFinancialPod(cashAndAtmArray,'Financial','ATM','Cash & ATM')
        addToFinancialPod(checkArrayNegative,'Financial','Checks','Check')
        addToFinancialPod(federalTaxArray,'Financial','Taxes','Federal Tax')
        addToFinancialPod(feesAndChargesArray,'Financial','Fees and Charges','Fees & Charges')
        addToFinancialPod(financeChargeArray,'Financial','Fees and Charges','Finance Charge')
        addToFinancialPod(financialArray,'Financial','Fees and Charges','Financial')
        addToFinancialPod(financialAdvisorArray,'Financial','Fees and Charges','Financial Advisor')
        addToFinancialPod(atmFeeArray,'Financial','Fees and Charges','ATM Fee')
        addToFinancialPod(cashAndAtmArray,'Financial','ATM','Cash & ATM')
        addToFinancialPod(lateFeeArray,'Financial','Fees and Charges','Late Fee')
        addToFinancialPod(lifeInsuranceArray,'Financial','Life Insurance','Life Insurance')
        addToFinancialPod(loanFeesAndChargesArray,'Financial','Loans','Loan Fees and Charges')
        addToFinancialPod(loanInsuranceArray,'Financial','Loans','Loan Insurance')
        addToFinancialPod(loanInterestArray,'Financial','Loans','Loan Interest')
        addToFinancialPod(loanPaymentArray,'Financial','Loans','Loan Payment')
        addToFinancialPod(loanPrincipalArray,'Financial','Loans','Loan Principal')
        addToFinancialPod(loansArray,'Financial','Loans','Loans')
        addToFinancialPod(localTaxArray,'Financial','Taxes','Local Tax')
        addToFinancialPod(lowBalanceArray,'Financial','Fees and Charges','Low Balance')
        addToFinancialPod(salesTaxArray,'Financial','Taxes','Sales Tax')
        addToFinancialPod(serviceFeeArray,'Financial','Fees and Charges','Service Fee')
        addToFinancialPod(stateTaxArray,'Financial','Taxes','State Tax')
        addToFinancialPod(studentLoanArray,'Financial','Loans','Loans')
        addToFinancialPod(taxesArray,'Financial','Taxes','Taxes')
        addToFinancialPod(transferArray,'Financial','Transfers','Transfer')
     
    
    
        //FOOD AND DRINK
        addToFoodAndDrinkPod(fastFoodArray,'Food and Drink','Dining Out', 'Fast Food')
        addToFoodAndDrinkPod(coffeShopsArray,'Food and Drink','Coffee','Coffee Shops')
        addToFoodAndDrinkPod(foodAndDiningArray,'Food and Drink','Dining Out','Food & Dining')
        addToFoodAndDrinkPod(groceriesArray,'Food and Drink','Groceries','Groceries')
        addToFoodAndDrinkPod(restaurantsArray,'Food and Drink','Dining Out','Restaurants')
    
    
        //GIFTS AND CHARITY
        addToGiftsAndCharityPod(charityArray,'Gifts and Charity','Donations', 'Charity')
        addToGiftsAndCharityPod(giftArray,'Gifts and Charity','Gifts', 'Gift')
        addToGiftsAndCharityPod(giftsAndDonationsArray,'Gifts and Charity','Donations', 'Gifts & Donations')
    
     
    
    
       //HOUSING...create rule about Apple
       addToHousingPod(billsAndUtilitiesArray,'Housing','Utilities','Bills & Utilities')
       addToHousingPod(furnishingsArray,'Housing','Misc','Furnishings')
       addToHousingPod(homeArray,'Housing','Misc','Home')
       addToHousingPod(homeImprovementArray,'Housing','Maintenance','Home Improvement')
       addToHousingPod(homeInsuranceArray,'Housing','Insurance','Home Insurance')
       addToHousingPod(homePhoneArray,'Housing','Internet and Phone','Home Phone')
       addToHousingPod(homeServicesArray,'Housing','Maintenance','Home Services')
       addToHousingPod(homeSuppliesArray,'Housing','Maintenance','Home Supplies')
       addToHousingPod(internetArray,'Housing','Internet and Phone','Internet')
       addToHousingPod(laundryArray,'Housing','Misc','Laundry')
       addToHousingPod(lawnAndGardenArray,'Housing','Maintenance','Lawn & Garden')
       addToHousingPod(mobilePhoneArray,'Housing','Internet and Phone','Mobile Phone')
       addToHousingPod(mortgageAndRentArray,'Housing','Mortgage or Rent','Mortgage & Rent')
       
       addToHousingPod(propertyTaxArray,'Housig','Property Tax','Property Tax')
       addToHousingPod(utilitiesArray,'Housing','Utilities','Utilities')
       
       //INCOME
       addToIncomePod(allowanceArray,'Income','Credits','Allowance')
       addToIncomePod(bonusArray,'Income','Credits','Bonus')
       addToIncomePod(checkArrayPositive,'Income','Checks','Check')
       addToIncomePod(depositArray,'Income','Deposits','Deposit')
       addToIncomePod(incomeArray,'Income','Credits','Income')
       addToIncomePod(interestArray,'Income','Interest','Interest Income')
       addToIncomePod(paycheckArray,'Income','Paychecks','Paycheck')
       addToIncomePod(reimbursementArray,'Income','Credits','Reimbursement')
    
       addToIncomePod(cashAppArrayPositive,'Income','Credits','Transfer')
       addToIncomePod(appleCashArrayPositive,'Income','Credits','Transfer')
       addToIncomePod(paypalArrayPositive,'Income','Credits','Transfer')
       addToIncomePod(venmoArrayPositive,'Income','Credits','Transfer')
       addToIncomePod(zelleArrayPositive,'Income','Credits','Transfer')
       addToIncomePod(uncategorizedCreditsArray,'Income','Credits','Uncategorized')
       addToIncomePod(unclassifiedTransferPositive,'Income','Credits','Transfer')
        
    
       
       //KIDS
        addToKidsPod(babySuppliesArray,'Kids','Misc','Baby Supplies')
        addToKidsPod(babysitterAndDaycareArray,'Kids','Child Care','Babysitter & Daycare')
        addToKidsPod(childSupportArray,'Kids','Child Support','Child Support')
        addToKidsPod(kidsArray,'Kids','Misc','Kids')
        addToKidsPod(kidsActivitiesArray,'Kids','Misc','Kids Activities')
        addToKidsPod(toysArray,'Kids','Misc','Toys')
    
    
    
        //MEDICAL
        addToMedicalPod(dentistArray,'Medical','Dentist','Dentist')
        addToMedicalPod(doctorArray,'Medical','Doctor','Doctor')
       // addToMedicalPod(doctorArray,'Medical','Eyecare','Eyecare')
        addToMedicalPod(pharmacyArray,'Medical','Pharmacy','Pharmacy')
    
    
        //MOBILE PAYMENTS
         addToMobilePaymentsPod(appleCashArrayNegative,'Mobile Payments','Apple Cash','Transfer')
         addToMobilePaymentsPod(cashAppArrayNegative,'Mobile Payments','Cash App','Transfer')
         addToMobilePaymentsPod(paypalArrayNegative,'Mobile Payments','Paypal','Transfer')
         addToMobilePaymentsPod(venmoArrayNegative,'Mobile Payments','Venmo','Transfer')
         addToMobilePaymentsPod(zelleArrayNegative,'Mobile Payments','Zelle','Transfer')
    
    
    
    
    
    
    
        //PERSONAL
        addToPersonalPod(clothingArray,'Personal','Clothing','Clothing')
        addToPersonalPod(electronicsAndSoftwareArray,'Personal','Shopping','Electronics & Software')
        addToPersonalPod(gymArray,'Personal','Health and Gym','Gym')
        addToPersonalPod(hairArray,'Personal','Misc','Hair')
        addToPersonalPod(healthAndFitnessArray,'Personal','Health and Gym','Health & Fitness')
        addToPersonalPod(hobbiesArray,'Personal','Misc','Hobbies')
        addToPersonalPod(legalArray,'Personal','Misc','Legal')
        addToPersonalPod(personalCareArray,'Personal','Misc','Personal Care')
        addToPersonalPod(printingArray,'Personal','Misc','Printing')
        addToPersonalPod(shippingArray,'Personal','Shopping','Shipping')
        addToPersonalPod(shoppingArray,'Personal','Shopping','Shopping')
        addToPersonalPod(spaAndMassageArray,'Personal','Misc','Spa & Massage')
        addToPersonalPod(sportingGoodsArray,'Personal','Shopping','Sporting Goods')
        addToPersonalPod(sportsArray,'Personal','Shopping','Sports')
        
        addToPersonalPod(advertisingArray,'Personal','Misc','Advertising')
    
    
    
        //PETS
        addToPetsPod(petFoodAndSuppliesArray,'Pets','Food and Care','Pet Food & Supplies')
        addToPetsPod(petGroomingArray,'Pets','Food and Care','Pet Grooming')
        addToPetsPod(petsArray,'Pets','Food and Care','Pets')
        addToPetsPod(veterinaryArray,'Pets','Veterinary','Veterinary')
    
    
    
        //TRANSPORTATION
        addToTransportationPod(autoAndTransportArray,'Transportation','Other','Auto & Transport')
        addToTransportationPod(autoInsuranceArray,'Transportation','Insurance','Auto Insurance')
        addToTransportationPod(autoPaymentArray,'Transportation','Auto Payment','Auto Payment')
        addToTransportationPod(gasAndFuelArray,'Transportation','Gas','Gas & Fuel')
        addToTransportationPod(parkingArray,'Transportation','Parking','Parking')
        addToTransportationPod(publicTransportationArray,'Transportation','Other','Public Transportation')
        addToTransportationPod(rentalCarAndTaxiArray,'Transportation','Other','Rental Car & Taxi')
        addToTransportationPod(servicesAndPartsArray,'Transportation','Other','Services & Parts')
     
    
    
        //TRAVEL
        addToTravelPod(airTravelArray,'Travel','Air Travel','Travel')
        addToTravelPod(hotelArray,'Travel','Hotel','Hotel')
        addToTravelPod(travelArray,'Travel','Other','Travel')
        addToTravelPod(vacationArray,'Travel','Vacation','Vacation')
    
        addToUncategorizedPod(uncategorizedArray,'Uncategorized','Uncategorized','Uncategorized')
       
    
    //   setTimeout(() => {
    //     //trackCreatedBudget()
    //     uploadTrackingRules()
    //   }, 2000);
    
 

       uploadUncategorizedTransactions(localQuestionsArray)
  
        uploadIncomePod()
        uploadSavingsPod()
        uploadEntertainmentPod()
        uploadEducationPod()
         uploadFoodAndDrinkPod()
         uploadFinancialPod()
         uploadGiftsAndCharityPod()
         uploadHousingPod()
         uploadKidsPod()
         uploadMedicalPod()
         uploadMobilePaymentsPod()
         uploadPetsPod()
         uploadPersonalPod()
         uploadTransportationPod()
         uploadTravelPod()
         uploadTrackingRules()
           //handleNavigateToMain()
    
    
    }

    async function uploadUncategorizedTransactions(localQuestionsArray){
        
        for(let i = 0; i < localQuestionsArray.length; i++){
            let transaction = localQuestionsArray[i].transaction
            let myObject = {...transaction, trackingStatus: 'New'}
            let docRef = doc(db,'EuklesUsersData',userUid,'Transactions',String(transaction.id))
            await setDoc(docRef,myObject)
            // await firestore().collection('EuklesUsersData').doc(userUid).collection('Transactions').doc(String(transaction.id)).set(myObject).then(res => {
            //     return true
            // }).catch(e => {
    
            //     console.log('error ', transaction)
            // })
        }
        
       

    }
    

    async function addTransactionToDatabase(transaction, podName, lineItemDescription){
        let todayEpoch = getTodaysEpoch()

        let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
    
       
        let trackingStatus = podName === 'Delete' ? 'Deleted' : 'Tracked'
        
         let myObject = {...transaction,podName: podName, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}
       
         let docRef = doc(db,'EuklesUsersData',userUid,'Transactions',String(transaction.id))
         await setDoc(docRef,myObject)
        //  await firestore().collection('EuklesUsersData').doc(userUid).collection('Transactions').doc(String(transaction.id)).set(myObject).then(res => {
        //     return true
        // }).catch(e => {

        //     console.log('error ', transaction)
        // })
    }

    //EDUCATION
function addToEducationPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...educationPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
    setEducationPod(currentPod)


}

//ENTERTAINMENT
function addToEntertainmentPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...entertainmentPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setEntertainmentPod(currentPod)


}

    //FINANCIAL
function addToFinancialPod(transactions,podTitle, lineItemDescription, transactionCategory){

        let currentPod = {...financialPod}
        let currentPodData = [...currentPod.data]
        let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
        let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue) * -1

        let currentTransactionsArray = [...currentPod.transactionsArray]

        for(let i = 0; i < transactions.length; i++){
            currentTransactionsArray.push(transactions[i])
            currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
            currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount) * -1
            addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
            addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
        }
        currentPodData[lineItemIndex].plannedValue = currentPlannedValue
        currentPod.data = currentPodData
        currentPod.transactionsArray = currentTransactionsArray
       
        setFinancialPod(currentPod)

    
    }

//FOOD AND DRINK
function addToFoodAndDrinkPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...foodAndDrinkPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setFoodAndDrinkPod(currentPod)


}

//GIFTS AND CHARITY
function addToGiftsAndCharityPod(transactions,podTitle, lineItemDescription, transactionCategory){
  
    let currentPod = {...giftsAndCharityPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setGiftsAndCharityPod(currentPod)


}

//HOUSING
function addToHousingPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...housingPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setHousingPod(currentPod)


}

//INCOME
function addToIncomePod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...incomePod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
       
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setIncomePod(currentPod)


}

//KIDS
function addToKidsPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...kidsPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setKidsPod(currentPod)


}

//MEDICAL
function addToMedicalPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...medicalPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setMedicalPod(currentPod)


}


//MOBILE PAYMENTS
function addToMobilePaymentsPod(transactions,podTitle, lineItemDescription, transactionCategory){
    
    let currentPod = {...mobilePaymentsPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setMobilePaymentsPod(currentPod)


}

//PERSONAL
function addToPersonalPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...personalPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)



    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setPersonalPod(currentPod)


}

   //PETS
   function addToPetsPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...petsPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
   setPetsPod(currentPod)


}


//TRANSPORTATION
function addToTransportationPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...transportationPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setTransportationPod(currentPod)


}

//TRAVEL
function addToTravelPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...travelPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setTravelPod(currentPod)


}

  //TRAVEL
  function addToUncategorizedPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...uncategorizedPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
    let currentSpentValue = Math.abs(currentPodData[lineItemIndex].spentValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        currentSpentValue = parseFloat(currentSpentValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        addTransactionToDatabase(transactions[i], podTitle, lineItemDescription)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setUncategorizedPod(currentPod)


}








// function addTrackingRule(transaction,podTitle, lineItemDescription, transactionCategory){
//     console.log('adding tracking rule for transaction ', transaction)
//     if(typeof transaction.categorization.bestRepresentation !== 'undefined'){
      
//         let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
//             transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
//         let currentArray = trackingRules


//         let matchingCategoryObjects = currentArray.filter(obj => obj.transactionCategory === trackingRule.transactionCategory)

//         if(matchingCategoryObjects.length >= 1){


//             let matchingBestRepresentationIndex = matchingCategoryObjects.findIndex(obj => obj.transactionBestRep === trackingRule.transactionBestRep)
            
            
//                 //*******MATCHING BEST REPRESENTATION SO CHECK FOR PAYEE */
//             if(matchingBestRepresentationIndex !== -1){

//                 let matchingPayeeIndex = matchingCategoryObjects.findIndex(obj => obj.transactionPayeeName === trackingRule.transactionPayeeName)

//                 if(matchingPayeeIndex !== -1){
                   
//                 }
//                 else {
//                     currentArray.push(trackingRule)
//                     setTrackingRules(previousState => [...currentArray])
//                 }


//             }
//                 //*************************************************** */



//             //*******NO MATCHING BEST REPRESENTATION SO ADD THE TRACKING RULE */
//             else {
                
//                 currentArray.push(trackingRule)
//                 setTrackingRules(previousState => [...currentArray])
//             }
//             //*************************************************** */


//         }




//  //*******NO MATCHING CATEGORY SO ADD THE TRACKING RULE */
//         else {
//             currentArray.push(trackingRule)
//             setTrackingRules(previousState => [...currentArray])
//         }
//     //*************************************************** */
            

     
//     }
    
// }


async function addTrackingRule(transaction,podTitle,lineItemDescription){
    let currentTrackingRules = trackingRulesRef.current
    let newCurrentTrackingRules = newTrackingRulesRef.current

    let transactionPayee = ''
    let transactionBestRep = ''
    let transactionCategory = ''

    if(typeof transaction.categorization !== 'undefined'){
        if(typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
            transactionPayee = transaction.categorization.normalizedPayeeName
        }

        if(typeof transaction.categorization.bestRepresentation !== 'undefined'){
            transactionBestRep = transaction.categorization.bestRepresentation
        }
        

        if(typeof transaction.categorization.category !== 'undefined'){
            transactionCategory = transaction.categorization.category
        }
        

    }

    if(currentTrackingRules.length >= 1){
        let matchingCategoryObjects = currentTrackingRules.filter(obj => obj.transactionCategory === transactionCategory)

        if(matchingCategoryObjects.length >= 1){

          let matchingBestRepIndex = matchingCategoryObjects.findIndex(obj => obj.transactionBestRep === transactionBestRep)

          if(matchingBestRepIndex === -1){
            let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
                transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
                //currentTrackingRules.push(trackingRule)

                newCurrentTrackingRules.push(trackingRule)
                newTrackingRulesRef.current = newCurrentTrackingRules




               // trackingRulesRef.current = currentTrackingRules
            // let matchingPayeeIndex = matchingCategoryObjects.findIndex(obj => obj.transactionPayeeName === transactionPayee)
            //       if(matchingPayeeIndex !== -1){
            //             console.log('THE RULE ALREADY EXISTS FOR ',transactionPayee)
            //       }
            //       else {
            //         let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
            //             transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
            //             currentTrackingRules.push(trackingRule)
            
            //             trackingRulesRef.current = currentTrackingRules
            //       }
          }


          else {
        
          
          }


        }
        else {
            //first of its category
     
            let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
                transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
                currentTrackingRules.push(trackingRule)
    
                trackingRulesRef.current = currentTrackingRules
        }
     

    }








    else {
        //tracking rules are at zero so add the first one
  
        let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
            transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
            currentTrackingRules.push(trackingRule)

            trackingRulesRef.current = currentTrackingRules
    }

       
    let todayEpoch = getTodaysEpoch()
    let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
    let trackingStatus = podTitle === 'Delete' ? 'Deleted' : 'Tracked'
    let myObject = {...transaction,podName: podTitle, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}

        let docRef = doc(db,'EuklesUsersData',userUid, 'Transactions',String(transaction.id))
        await setDoc(docRef,myObject)
      //let userUid = auth().currentUser.uid;
    //  await firestore().collection('EuklesUsersData').doc(userUid).collection('Transactions').doc(String(transaction.id)).set(myObject).then(res => {
    //      return true
    //  }).catch(e => {

         
    //  })
}

async function uploadIncomePod(){
    let podName = 'Income'
    let thePod = {...incomePod}
    let podData =   [...thePod.data]
    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)

   

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        let lineItemDescription = lineItem.description
        let plannedValue = lineItem.plannedValue
        let spentValue = lineItem.spentValue

        let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
        let docCheck = await getDoc(docCheckRef).then(doc => {
            if(doc.exists){
                let docObjectArray = Object.keys(doc.data())
                let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                if(existingIndex === -1){
                    return {docExists: true, fieldExists: false}
                }
                else {
                    return {docExists: true, fieldExists: true}
                }

            }
            else {
                return {docExists: false, fieldExists:false}
            }
        }).catch(e => {
            return {errorInDocCheck: true, errorMessage: e}
        })


        if(docCheck.docExists && docCheck.fieldExists){

            let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            const fieldToUpdate = lineItemDescription.concat('.spentValue')
            await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
            // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
            
            
            
            //  await docRef.update({
            //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
            //   }).then(res => {
            //     return true
            //   }).catch(e => {
            //     return {errorInDocRefUpdate: true, errorMessage: e}
            //   })
        }
        else if(docCheck.docExists && !docCheck.fieldExists){
            let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            await updateDoc(docNext,{   [lineItemDescription]: {
                plannedValue: parseFloat(plannedValue),
                spentValue: parseFloat(spentValue),
                podName: podName
               }})

            // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
            //     [lineItemDescription]: {
            //      plannedValue: parseFloat(plannedValue),
            //      spentValue: parseFloat(spentValue),
            //      podName: podName
            //     }
                
            //    }).then(res => {
            //      return true;
            //    }).catch(e => {
            //      return {errorLine7404: true, errorMessage: e}
            //    })
        }
        else if(!docCheck.docExists && !docCheck.fieldExists){

            let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            await setDoc(docNextNext,{   [lineItemDescription]: {
                plannedValue: parseFloat(plannedValue),
                spentValue: parseFloat(spentValue),
             podName: podName
            }})

            // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
            //     [lineItemDescription]: {
            //         plannedValue: parseFloat(plannedValue),
            //         spentValue: parseFloat(spentValue),
            //      podName: podName
            //     }
                
            //    }).then(res => {
            //      return true;
            //    }).catch(e => {
            //      return {errorLine7404: true, errorMessage: e}
            //    })
        }


        
    }
}

async function uploadSavingsPod(){
    let podName = 'Savings'
    let thePod = {...savingsPod}
    let podData =   [...thePod.data]
    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
}

async function uploadEducationPod(){
    let podName = 'Education'
    let thePod = {...educationPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
    

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}


async function uploadEntertainmentPod(){
    let podName = 'Entertainment'
    let thePod = {...entertainmentPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
 

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadFinancialPod(){
    let podName = 'Financial'
    let thePod = {...financialPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}



async function uploadFoodAndDrinkPod(){
    let podName = 'Food and Drink'
    let thePod = {...foodAndDrinkPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)


    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadGiftsAndCharityPod(){
    let podName = 'Gifts and Charity'
    let thePod = {...giftsAndCharityPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadHousingPod(){
    let podName = 'Housing'
    let thePod = {...housingPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadKidsPod(){
    let podName = 'Kids'
    let thePod = {...kidsPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadMedicalPod(){
    let podName = 'Medical'
    let thePod = {...medicalPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)


    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadMobilePaymentsPod(){
    let podName = 'Mobile Payments'
    let thePod = {...mobilePaymentsPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadPetsPod(){
    let podName = 'Pets'
    let thePod = {...petsPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadPersonalPod(){
    let podName = 'Personal'
    let thePod = {...personalPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadTransportationPod(){
    let podName = 'Transportation'
    let thePod = {...transportationPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   
 
    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadTravelPod(){
    let podName = 'Travel'
    let thePod = {...travelPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    if(filteredPod.length >= 1){
        for(let i = 0; i < filteredPod.length; i++){
            let lineItem = filteredPod[i]
            let lineItemDescription = lineItem.description
            let plannedValue = lineItem.plannedValue
            let spentValue = lineItem.spentValue
    
            let docCheckRef = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
            let docCheck = await getDoc(docCheckRef).then(doc => {
                if(doc.exists){
                    let docObjectArray = Object.keys(doc.data())
                    let existingIndex = docObjectArray.findIndex(obj => obj === lineItemDescription)
                    if(existingIndex === -1){
                        return {docExists: true, fieldExists: false}
                    }
                    else {
                        return {docExists: true, fieldExists: true}
                    }
    
                }
                else {
                    return {docExists: false, fieldExists:false}
                }
            }).catch(e => {
                return {errorInDocCheck: true, errorMessage: e}
            })
    
    
            if(docCheck.docExists && docCheck.fieldExists){
    
                let docRefAlt = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                const fieldToUpdate = lineItemDescription.concat('.spentValue')
                await updateDoc(docRefAlt,{[fieldToUpdate]: increment(spentValue)})
                // const docRef = firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName)
                
                
                
                //  await docRef.update({
                //     [fieldToUpdate]: firestore.FieldValue.increment(spentValue)
                //   }).then(res => {
                //     return true
                //   }).catch(e => {
                //     return {errorInDocRefUpdate: true, errorMessage: e}
                //   })
            }
            else if(docCheck.docExists && !docCheck.fieldExists){
                let docNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await updateDoc(docNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                    podName: podName
                   }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).update({
                //     [lineItemDescription]: {
                //      plannedValue: parseFloat(plannedValue),
                //      spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
            else if(!docCheck.docExists && !docCheck.fieldExists){
    
                let docNextNext = doc(db,'EuklesUsersData',userUid,currentMonth,podName)
                await setDoc(docNextNext,{   [lineItemDescription]: {
                    plannedValue: parseFloat(plannedValue),
                    spentValue: parseFloat(spentValue),
                 podName: podName
                }})
    
                // await firestore().collection('EuklesUsersData').doc(userUid).collection(currentMonth).doc(podName).set({
                //     [lineItemDescription]: {
                //         plannedValue: parseFloat(plannedValue),
                //         spentValue: parseFloat(spentValue),
                //      podName: podName
                //     }
                    
                //    }).then(res => {
                //      return true;
                //    }).catch(e => {
                //      return {errorLine7404: true, errorMessage: e}
                //    })
            }
    
    
            
        }
    }
 
}

async function uploadTrackingRules(){

    let currentRules = newTrackingRulesRef.current
    
   

    for(let i = 0; i < currentRules.length; i++){
       let trackingRef = collection(db,'EuklesUsersData',userUid,'TrackingRules')
       await addDoc(trackingRef,currentRules[i])
        // await firestore().collection('EuklesUsersData').doc(userUid).collection('TrackingRules').add(currentRules[i]).catch(e => {
            
        // })
    }

 handleNavigateToMain();
}

async function handleNavigateToMain(){

    let mArray = [currentMonth]
    let monthDoc = doc(db,'EuklesUsersData',userUid,'MonthsWithBudgets','months')
    await setDoc(monthDoc,{mArray})
    // await firestore().collection('EuklesUsersData').doc(userUid).collection('MonthsWithBudgets').doc('months').set({mArray}).then(res => {
    //   return true
    // }).catch(e => {
    //   console.log('error line 4200')
    // })

    let userDoc = doc(db,'EuklesUsers',userUid)
    await updateDoc(userDoc,{queryMonth: currentMonth})
    // await firestore().collection('EuklesUsers').doc(userUid).update({queryMonth: currentMonth, monthlySavingsGoal: 0.00}).then(res => {
    //   return true;
    // }).catch(e => {
    //   console.log('error 4205')
    // })


    //maybe just go to a loading page to call this function
    

  

   
    //navigation.navigate('Main')
    //navigation.navigate('BudgetRecap',{finCustomerId: route.params.finCustomerId})
    navigate('../insidebudgetrecap',{state:{finCustomerId:finCustomerId}})
}




          

    return(
        <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
        <Grid container direction='column'  justifyContent='center' alignItems='center'>
          <Grid item>
          <Ellipsis color={theme.palette.primary.main} />
          </Grid>
         <Grid item style={{paddingTop:5}}>
            <Typography style={{color: theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>Creating your budget...</Typography>
          </Grid> 
        </Grid>
       
       
      </Backdrop> 
    )
}

