
import { Grid, Backdrop, Typography} from '@mui/material';
import React,{useEffect,useState} from 'react'
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";





export default function FinicityExistingUser (){

  const location = useLocation();
  const navigate = useNavigate();
 


    let finCustomerId = location.state.finCustomerId
    let topUrl = location.state.url

    useEffect(() => {
        let urlToUse = location.state.urlToUse

        const script = document.createElement('script');
      
  
        script.src = "https://connect2.finicity.com/assets/sdk/finicity-connect.min.js"
        script.async = true;
    
    
      document.body.append(script);

  
        script.onload = function() {
     
                window.finicityConnect.launch(urlToUse,{
                    selector:'#connect-container',
                    overlay:'rgba(255,255,255,0)',
                     
                  
                    
                    success:(event) =>{
                      navigate('../finicityexistingloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
                     //handleGetRefreshedAccounts(finCustomerId);
        
                    },
                    done: (event) => {
                      navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
                    },
                    cancel:(event) => {
                      navigate('/finicityloading',{state: {finCustomerId: finCustomerId, url:topUrl}})
                    },
                    error:(event) => {
                  
                    },
                    loaded: () => {
              
                        script.focus();
                        
                    },
                    route: (event) => {
              
                    
                    //   if(event.data.screen === 'sign-in'){
                         
                    //      retrieveInstitutionName(event.data.params.institutionId)
                    //   }
                   
                    },
                    user: (event) => {
                
                    //   if(event.data.action === 'End'){
                    //     handleGetRefreshedAccounts();
                    //   }
                    //   if(event.data.action === 'RemoveConnectedInstitutionSuccess'){
                    //     //process removal
                    //     const institutionLoginId = event.data.institutionLoginId
                    //     const institutionId = event.data.institutionId
                    //     handleResetAccounts(institutionLoginId, institutionId)
                    //   }
                       
                    }
                })
            }

    },[location.state.urlToUse])



    // async function handleGetRefreshedAccounts(theFinCustomerId){
  
    //   setLoading(true);
  
    //     let docRef = doc(db,'EuklesUsers', userUid);
          
    //     let finId = await getDoc(docRef).then((doc) => {
    //       return doc.data().finCustomerId;
    //     }).catch((e) => {
    //       return 'nil';
    //     })
    //     let retrieveBalances = httpsCallable(functions,'refreshFinAccountsRevised');
     
    //        await retrieveBalances({customerId: finId, userUid: userUid}).then(function(result) {
           
    //       return {error: false}
    //     }).catch(function(error){
  
    //       return {error: true}
    //     })
   
    //     let route = location.state.comingFrom;
  
    //     navigate('../budgethome',{state:{needsToRefresh: true}})

    //     try{
    //       logEvent(analytics, 'web_connected_account',{
    //           plat: 'web'
             
    //       })
    //   }catch(e){
    //   } 

    //     // switch(route){
    //     //   case 'budgethome':
    //     //     setLoading(false);
    //     //       navigate('../budgethome',{state:{needsToRefresh: true}})
    //     //       break;
    //     //   case 'cashflow':
    //     //       navigate('../cashflow');
    //     //       break;
    //     //   case 'accountshome':
    //     //     navigate('../accountshome');
    //     //     break;
    //     //   case 'balancesheet':
    //     //     handleResetAccounts();
    //     //     setTimeout(() => {
    //     //       navigate('../balancesheet',{state:{needsToRefresh: true}})
    //     //     }, 2000);
          
           
    //     //       break;
    //     //   default:
    //     //       navigate('../budgethome');
    //     // }
    
  
  
  
    //    }

    return(
       <div>
     
      
        
             <Backdrop sx={{backgroundColor:'white'}} open={false} style={{zIndex:1400}}>
          <Grid container direction='column'  justifyContent='center' alignItems='center'>
           
            <Grid item style={{paddingTop:5}}>
              <Typography style={{color:'white', fontWeight:'bold', textAlign:'center'}}>Refreshing your account data...</Typography>
              <Typography style={{color:'white', fontWeight:'bold', textAlign:'center'}}>This can take up to a minute...</Typography>
            </Grid>
          </Grid>
        
        
        </Backdrop> 
       </div>
    )
}