import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {H2 } from "components/uicomponents/Typography";
import './onboardingstyles.css'
import React,{useState,useEffect} from 'react'
import { useNavigate } from "react-router";





const db = getFirestore();


export default function OnBoardingWelcome (){


    const [isVisible, setIsVisible] = useState(false);
    const [secondVisible, setSecondVisible] = useState(false)
    const [thirdVisible, setThirdVisible] = useState(false)


    let navigate = useNavigate();

    useEffect(() => {
       
        setIsVisible(true);
        
        animateText();
        updateStep();
      }, []);

      async function updateStep(){
        let uid = getAuth().currentUser.uid
        let docRef = doc(db,'EuklesUsers',uid)
        await updateDoc(docRef,{currentStep: 1})
      }


      function animateText(){
        setTimeout(() => {
            setIsVisible(false)
            
           
        },3000)

        setTimeout(() => {
            setSecondVisible(true)
            
           
        },4000)

        setTimeout(() => {
            setSecondVisible(false)
            
           
        },7000)

        setTimeout(() => {
           setThirdVisible(true)
            
           
        },8000)


        setTimeout(() => {
            setThirdVisible(false)
            
           
        },12000)

        setTimeout(() => {
       
          navigate('/getstarted')
           
        },13000)

   
      }

  

        return (
            <div className="center-container">
        <div className={`fade-in-container ${isVisible ? 'visible' : ''}`}>
          <H2 style={{textAlign:'center'}}>Hi!</H2>
        </div>
        <div className={`fade-in-container ${secondVisible ? 'visible' : ''}`}>
          <H2 style={{marginTop:-42,textAlign:'center'}}>Welcome to Budget Even!</H2>
        </div>
        <div className={`fade-in-container ${thirdVisible ? 'visible' : ''}`}>
          <H2 style={{marginTop:-84,textAlign:'center', maxWidth:700}}>We're here to help you increase your monthly savings through effective planning, detailed expense tracking, and timely budget tips.</H2>
        </div>

  
          </div>
        )
      
     
      
        
    
 
  
  
}