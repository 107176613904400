
import { Card, CardHeader, CardContent,Grid,Typography, Button,useTheme } from '@mui/material'
import React from 'react'

import YouTube from 'react-youtube';

export default function SemiAutoCard({handlePurchase}) {
    const theme = useTheme();


  return (
    <Card >
    <CardHeader titleTypographyProps={{fontWeight:'bold',color:theme.palette.primary.dialogBackground}} 
    title="Semi-Automate Your Budget" 
    subheader="Only $2.49 per month"
    subheaderTypographyProps={{fontWeight:'bold',color: theme.palette.primary.main}}
    
    />
    <CardContent>

    <Grid container direction='row' style={{display:'flex', flexWrap:'nowrap', justifyContent:'center'}}>
 <Grid item >
<YouTube videoId='xvdzIagdic0'  opts={{width:480, height:270}} />
</Grid> 
</Grid>

<div style={{display:'flex',flexDirection:'column',textAlign:'center',marginBottom:10}}>
<Typography style={{fontWeight:'bold'}}>Semi-Auto Mode includes: </Typography>
      <Typography>⦿ Financial account connection</Typography>
      <Typography>⦿ Auto transaction downloads</Typography>
      <Typography>⦿ Cash flow projection</Typography>
      <Typography>⦿ Net Worth Tracking</Typography>
      </div>

    </CardContent>
    <div style={{ textAlign: 'center', padding: '16px' }}>
      <Button onClick={() => handlePurchase()} variant="contained" color="primary">
        Select this Option
      </Button>
    </div>
  </Card>
  )
}