import React,{useEffect} from 'react'
import { Backdrop,Grid,useTheme,Typography } from '@mui/material'
import { Ellipsis } from 'react-spinners-css'
import { getFunctions,httpsCallable } from 'firebase/functions'
import { useNavigate,useLocation } from 'react-router'

const functions = getFunctions()

export default function FinicityExistingLoading() {
    
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
refreshFinAccounts();
    },)

    async function refreshFinAccounts(){
        let finCustomerId = location.state.finCustomerId
        let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');
  
          await retrieveBalances({customerId: finCustomerId}).then(function(result) {
        
            return result.data.accounts;
          }).catch(function(error){
        
            return false;
          })

          navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
    }

    return(
  
        <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
           <Grid container direction='column'  justifyContent='center' alignItems='center'>
             <Grid item>
             <Ellipsis color={theme.palette.primary.main} />
             </Grid>
             <Grid item style={{paddingTop:5}}>
               <Typography style={{color:'white', fontWeight:'bold'}}>Organizing your accounts...</Typography>
             </Grid> 
           </Grid>
          
          
         </Backdrop> 
          )
}
