
import { Card, CardHeader, CardContent,Grid, Button,useTheme, Typography } from '@mui/material'
import React from 'react'
import YouTube from 'react-youtube';

export default function AutoCard({handlePurchase}) {
    const theme = useTheme();
  return (
    <Card>
    <CardHeader titleTypographyProps={{fontWeight:'bold',color:theme.palette.primary.dialogBackground}} 
    title="Automate Your Budget" 
    subheader="Only $4.99 per month"
    subheaderTypographyProps={{fontWeight:'bold',color: theme.palette.primary.main}}
    
    />
    <CardContent>

    <Grid container direction='row' style={{display:'flex', flexWrap:'nowrap', justifyContent:'center'}}>
 <Grid item >
<YouTube videoId='onm7uwazhIA' opts={{width:480, height:270}} />
</Grid> 
</Grid>

<div style={{display:'flex',flexDirection:'column',textAlign:'center',marginBottom:10}}>
      <Typography style={{fontWeight:'bold'}}>Auto-Mode includes: </Typography>
      <Typography>⦿ Financial account connection</Typography>
      <Typography>⦿ Auto generated budget</Typography>
      <Typography>⦿ Auto transaction tracking</Typography>
      <Typography>⦿ Cash Flow projection</Typography>
      <Typography>⦿ Net Worth tracking</Typography>
      </div>

    </CardContent>
    <div style={{ textAlign: 'center', padding: '16px' }}>
      <Button onClick={() => handlePurchase()} variant="contained" color="primary">
        Select this Option
      </Button>
    </div>
  </Card>
  )
}