
import React, {useEffect,useState} from 'react'
import { Card, CardContent,useTheme,Typography,CardActions,Button } from '@mui/material';
import BudgetEvenLogo from '../landingpage/landingimages/budgeteven.svg'

import { useNavigate,useLocation } from 'react-router';
import { getAuth } from 'firebase/auth';
import { getFirestore,doc,updateDoc } from 'firebase/firestore';

const db = getFirestore();

export default function InsideBudgetRecap() {

    
    const theme = useTheme();
    const navigate = useNavigate();
 
    const [currentStep, setCurrentStep] = useState(1)






    useEffect(() => {
        updateStep()
    
       
    },[])

    async function updateStep(){
        let uid = getAuth().currentUser.uid
        let docRef = doc(db,'EuklesUsers',uid)
        await updateDoc(docRef,{currentStep:'InsideBudgetRecap'})
    }

//     async function submitAnalytics(){
//       let plat = Platform.OS === 'ios' ? 'ios' : 'android'
//       await analytics().logEvent('aa_userOnBudgetRecap',{
//           platform: plat,
          
//       })
//   }


    const firstText = 'Your monthly budget, cash flow projection, and net worth balance sheet have been created.'
    //const savingsText = 'Savings tip...Consider removing '
    
    const secondText = 'Login daily to have Budget Even scan and track your transactions, update your cash flow, and balance sheet.'
    
    const thirdText = 'Your monthly savings goal will be set to 25% of your planned income for the month. '

    const fourthText = '50% of your budget should go towards "needs" like food and housing, and the remaining 25% can go towards "wants" like entertainment.'

    function handleNextStep(){
     if(currentStep === 1){
       return setCurrentStep(2)
     }
     else if(currentStep === 2){
      return setCurrentStep(3)
     }
     else if(currentStep === 3){
      return setCurrentStep(4)
     }
     else if(currentStep === 4){
     
     return navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
     }

    }


        return (

            <div className="center-container">
          <Card sx={{ minWidth: 400 }}>
      <CardContent>
      <img style={{alignSelf:'center',width:200}} src={BudgetEvenLogo}  alt="Logo" />
        <Typography variant="h5" component="div">
        {currentStep === 1 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}} component="div">{firstText}</Typography> : null}
      {currentStep === 2 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{secondText}</Typography> : null}
      {currentStep === 3 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{thirdText}</Typography> : null}
      {currentStep === 4 ?   <Typography style={{fontWeight:'bold', color: theme.palette.primary.fiverGrayText,maxWidth:400}}  component="div">{fourthText}</Typography> : null}
        </Typography>
       
      </CardContent>
      <CardActions>
        <Button style={{marginLeft:'auto'}} onClick={() => handleNextStep()} size="small">Next</Button>
      </CardActions>
    </Card>
    </div>
          )


}