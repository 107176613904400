import React,{useEffect} from 'react'
import { Backdrop,Grid,useTheme,Typography } from '@mui/material'
import { Ellipsis } from 'react-spinners-css'
import { getFunctions,httpsCallable } from 'firebase/functions'
import { useNavigate,useLocation } from 'react-router'
import { getFirestore,doc,updateDoc,setDoc,collection,where,query,getDocs } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const functions = getFunctions()
const db = getFirestore();

export default function FinicityAlreadyAuthWithBudgetLoading() {
    
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
refreshFinAccounts();
    },[])

    async function refreshFinAccounts(){
        let finCustomerId = location.state.finCustomerId
        let retrieveBalances = httpsCallable(functions,'refreshFinAccounts');
  
        let accounts =  await retrieveBalances({customerId: finCustomerId}).then(function(result) {
        
            return result.data.accounts;
          }).catch(function(error){
        
            return false;
          })

       
          uploadAccounts(accounts);
    }

    async function uploadAccounts(finAccountsArray){
        const uid = getAuth().currentUser.uid;

        let cashCollectionRef = collection(db,'EuklesUsersData',uid,'Accounts')
        let q = query(cashCollectionRef,where("isCashFlowAccount","==",true))

        let cashFlowAccountId = await getDocs(q).then(querySnapshot => {
          let accountId = ''
          querySnapshot.forEach(doc => {
            accountId = doc.data().id
          })

          return accountId
        }).catch(e => {
          return ''
        })


        for(let i = 0; i < finAccountsArray.length; i++){
          if(String(finAccountsArray[i].id) === String(cashFlowAccountId)){
            let myObjectAlt = {...finAccountsArray[i], isFinicity:true, isCashFlowAccount:true}
            let accountsDocRef = doc(db,'EuklesUsersData',uid,'Accounts',String(finAccountsArray[i].id))
            await setDoc(accountsDocRef,myObjectAlt)
            await uploadBranding(finAccountsArray[i])
          }
          else {
            let myObject = {...finAccountsArray[i], isFinicity:true}
            let accountDocRef = doc(db,'EuklesUsersData',uid, 'Accounts',String(finAccountsArray[i].id))
            await setDoc(accountDocRef,myObject)
         
            await uploadBranding(finAccountsArray[i])
          }
           
        }
        
        navigate('/dashboard/budgethome',{state:{needsToRefresh:false}})
    }

    async function uploadBranding(account){
      
        const uid = getAuth().currentUser.uid;
        let logo = 'nil'
        let icon = 'nil'
        let tile = 'nil'
    
        let getBranding = httpsCallable(functions,'retrieveInstitutionBranding')
      
        let brandingObj = await getBranding({institutionId: account.institutionId}).then((res) => {
                 
          
             return res.data.branding;
         }).catch((error) => {
          
             return false;
         })
      
        
      
         if(brandingObj !== false){
          if(typeof brandingObj.logo !== 'undefined'){
            logo = brandingObj.logo
          }
      
          if(typeof brandingObj.icon !== 'undefined'){
           icon = brandingObj.icon
          }
      
          if(typeof brandingObj.tile !== 'undefined'){
            tile = brandingObj.tile
          }
    
    
       let accountsRef = doc(db,'EuklesUsersData',uid,'Accounts',String(account.id))
          
       await updateDoc(accountsRef,{logo:logo,icon:icon,tile:tile})



         }

     
      }



    return(
  
        <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
           <Grid container direction='column'  justifyContent='center' alignItems='center'>
             <Grid item>
             <Ellipsis color={theme.palette.primary.main} />
             </Grid>
             <Grid item style={{paddingTop:5}}>
               <Typography style={{color:theme.palette.primary.fiverGrayText,  fontWeight:'bold'}}>Organizing your accounts...</Typography>
             </Grid> 
           </Grid>
          
          
         </Backdrop> 
          )
}
