import AppTextField from "components/uicomponents/AppTextField";
import Axios from 'axios';
import BottomSpacer from "components/uicomponents/BottomSpacer";
import { Box,Grid,Divider, Backdrop, Typography,useTheme } from "@mui/material";
import { BudgetContextTwo } from "contexts/BudgetContextTwo";
import BudgetEvenLogo from '../../landingpage/landingimages/budgeteven.svg'
import {CardElement, useStripe, useElements,} from '@stripe/react-stripe-js';
import { doc, getFirestore, updateDoc,setDoc,getDoc } from "firebase/firestore";
import { Ellipsis} from 'react-spinners-css'
import FlexBetween from "components/uicomponents/FlexBetween";
import { generateRandomString,getCurrentMonthAndYearString } from "utils/Helpers";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { H3} from "components/uicomponents/Typography";
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate} from "react-router-dom";
import OnBoardingMenu from "../MenuPopover/OnBoardingMenu";
import React,{useState, useContext, useEffect} from 'react'
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { uploadError } from "utils/FirebaseAnalyticsMethods";
import { testMode } from "utils/GlobalConstans";


const db = getFirestore();
const functions = getFunctions();



const OnBoardCheckout = () => {

const{userUid, userEmail, updateIsPremiumUser, isAuthenticated} = useContext(BudgetContextTwo)
const theme = useTheme();
const elements= useElements();
const location = useLocation();
const navigate = useNavigate();
const stripe = useStripe();

const [canSubmit,setCanSubmit] = useState(false)
const [firstName,setFirstName] = useState('')
const [firstNameError,setFirstNameError] = useState(false)
const [loading, setLoading] = useState(false)
const [stripeErrorMessage,setStripeErrorMessage] = useState('')
const [stripeLoading,setStripeLoading] = useState(false)
const [zip,setZip] = useState('')
const [zipError,setZipError] = useState(false)


useEffect(() => {
  document.body.style.backgroundColor = 'white'
},[])




useEffect(() => {
  if(zip !== '' && firstName !== '' && stripe && elements){
    setCanSubmit(true)
  }
},[zip,firstName])



function checkFields(){
  if(firstName === ''){
    return setFirstNameError(true)
  }


  if(zip === ''){
    return setZipError(true)
  }

  else {
    createStripePaymentMethod()
  }


}


async function createFinicityCustomerSemiAuto(){
 
  try{
    let url = testMode ? 'createFinCustomerTest' : 'createFinCustomer'

    let createFinMethod = httpsCallable(functions,url);
      
    let userName = generateRandomString(10)
    
    
    let customerId = await createFinMethod({username: userName}).then((res) => {
            
        return res.data.customerId;
    }).catch((err) => {
        
        return false;
    });
    
    
   
    if(customerId !== false){
    
  
      uploadUserInfo(customerId)
   
    }
    else {
      setLoading(false);
      toast.error('There was an error processing your request.')
    }
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js function is createFinicityCustomerSemiAuto')
  }

  
 
 
  
  } 

  async function createFinicityCustomerAuto(){
 
    try{
      let url = testMode ? 'createFinCustomerTest' : 'createFinCustomer'

      let createFinMethod = httpsCallable(functions,url);
        
      let userName = generateRandomString(10)
      
      
      let customerId = await createFinMethod({username: userName}).then((res) => {
              
          return res.data.customerId;
      }).catch((err) => {
          
          return false;
      });
      
      
     
      if(customerId !== false){
      
      
        //uploadUserInfo(customerId)
        let uid = getAuth().currentUser.uid;
        let monthYear = getCurrentMonthAndYearString();
        let userDocRef = doc(db,'EuklesUsers',uid)
        await updateDoc(userDocRef,{
          onBoardingSelection: 'Auto',
          budgetMethod: 'Auto',
          isPremiumUser:true,
          finCustomerId:customerId,
          noCashFlowSet: true,
          queryMonth: monthYear,
          autoCurrentStep:'IdSetGettingFinUrl'
         
        })
        //handleConnectClick(customerId)
        getFinUrl(customerId)
      }
      else {
        setLoading(false);
        toast.error('There was an error processing your request.')
      }
      
    }catch(e){

    }
 
   
   
    
    } 

async function createStripePaymentMethod () {

  try{
    setStripeLoading(true)
    setStripeErrorMessage('')
     
      if(!stripe || !elements){
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type:'card',
        card: cardElement,
      });
 
      if(error){
       
       setLoading(false);
      setStripeLoading(false);
       toast.error(`There was an error processing your request. ${error.message}  `)
    
      }
      else {
      
        const paymentMethodId = paymentMethod.id;
        
        const data = {paymentId: paymentMethodId, email: userEmail, uid: userUid, name:firstName, zip: zip}
       createStripeCustomer(data);
     
      
     
      }
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js, createStripePaymentMethod')
  }

   }

   async function createStripeCustomer(data){

    try{
      let uid = getAuth().currentUser.uid
      let userDocRef = doc(db,'EuklesUsers',uid)
  
      let existingId = await getDoc(userDocRef).then(doc => {
        let id = 'nil'
        if(typeof doc.data().stripeCustomerId !== 'undefined'){
          id = doc.data().stripeCustomerId
        }
        return id
      }).catch(e => {
        return 'nil'
      })
  
      if(existingId !== 'nil' && existingId !== ''){
        if(location.state.planType === 'Auto'){
          createStripeSubscriptionAutoMethod(existingId)
        }
        else {
          //semi
          createStripeSubscriptionSemiAuto(existingId)
        }
      }
  
  else {
  
  
      let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomerTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeCustomer'
  
       let createCustomerResult = await Axios.post(url, data).then(res => {
    
         
    
               return res.data;
                 
             }).catch(error => {
            
               return error;
               })
  
             
        
               if(!createCustomerResult.encounteredError){
                //{location.state.planType === 'Monthly' ? '$4.99' : '$49.99'}
                if(location.state.planType === 'Auto'){
                  createStripeSubscriptionAutoMethod(createCustomerResult.customerId)
                }
                else {
                  //semi
                  createStripeSubscriptionSemiAuto(createCustomerResult.customerId)
                }
             
               }
               else if(createCustomerResult.encounteredError){
                setLoading(false);
                setStripeLoading(false);
                toast.error('There was an error processing your request.')
                setStripeErrorMessage(createCustomerResult.errorMessage)
          
               }
  
              }
    }catch(e){
      uploadError(e.message,'OnBoardCheckout.js createStripeCustomer')
    }
  
           
    }

 
    async function createStripeSubscriptionAutoMethod(customerId){
    
      try{
        let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscription'
        setStripeLoading(false);
        setLoading(true)
       const customerData = {customerId: customerId, uid: userUid}
             let stripeSubscription = await Axios.post(url, customerData ).then(res => {
   
              return res.data;
              }).catch(error => {
           
                return error;
              })
            
   
              if(stripeSubscription.encounteredError){
             
                setLoading(false);
                setStripeLoading(false);
                setStripeErrorMessage(stripeSubscription.errorMessage)
                toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
              }
              else if(!stripeSubscription.encounteredError) {
                
                //toast.success('Payment successful!')
            
                updateIsPremiumUser(customerId)
                updateAutoUserDocBudgetMethodandStripeDetails(stripeSubscription.subscriptionId,'Auto');
                createFinicityCustomerAuto();
              
   
                
              }
      }catch(e){
        uploadError(e.message,'OnBoardCheckout.js,createStripeSubscriptionAutoMethod')
      }

 
      
 }

 async function createStripeSubscriptionSemiAuto(customerId){

  try{
    let url = testMode ? 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionTest' : 'https://us-central1-budgetbettereukles.cloudfunctions.net/createStripeSubscriptionAnnualPayment'
    const customerData = {customerId: customerId, uid: userUid}
          let stripeSubscription = await Axios.post(url, customerData ).then(res => {
    
           return res.data;
           }).catch(error => {
            console.log('error creating subscription')
             return error;
           })
    
           if(stripeSubscription.encounteredError){
          
             setLoading(false);
             setStripeLoading(false);
             setStripeErrorMessage(stripeSubscription.errorMessage)
             toast.error(`There was an error processing your request. ${stripeSubscription.errorMessage}`)
           }
           else if(!stripeSubscription.encounteredError) {
             
             updateStripeSubscriptionIdSemiAuto(stripeSubscription.subscriptionId,'Semi-Auto');
             createFinicityCustomerSemiAuto();
           
    
             
           }
    
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js, createStripeSubscriptionSemiAuto')
  }

 
}


    async function getFinUrl (finCustomerId){
     
      try{
        let retrieveUrl = httpsCallable(functions,'refreshPromiseCustomUrl')
        let urlResult = await retrieveUrl({customerId: finCustomerId}).then(function(result){
            return result;
        }).catch(function(error){
            console.log('the error ', error);
            return false;
        })
    
        if(urlResult.data.theUrl === 'error getting url' || urlResult === false){
            setLoading(false)
  
        }
        else {
        
            navigate('/finicityconnect',{state:{url: urlResult.data.theUrl, finCustomerId: finCustomerId}})
   
        }
      }catch(e){
        uploadError(e.message,'OnBoardCheckout.js, getFinUrl')
      }
 
  
  
  }


    async function uploadUserInfo(finCustomerId){
    
      try{
        let uid = getAuth().currentUser.uid;
     
        let monthYear = getCurrentMonthAndYearString();
  
        let mArray = [monthYear]
        let monthDocRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
        await setDoc(monthDocRef,{mArray})
  
        let userDocRef = doc(db,'EuklesUsers',uid)
        await updateDoc(userDocRef,{
          onBoardingSelection: 'Semi-Auto',
          budgetMethod: 'Semi-Auto',
          isPremiumUser:true,
          finCustomerId:finCustomerId,
          noCashFlowSet: true,
          queryMonth: monthYear,
          subscriptionMethod: 'web'
        })
      
  
        let incomeDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Income')
        await setDoc(incomeDocRef,{
          ['Paychecks']: {
            plannedValue:0.00,
            spentValue:0.00,
            podName:'Income'
        }
        })
  
        let savingsDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Savings')
        await setDoc(savingsDocRef,{
          ['Savings']: {
            plannedValue:0.00,
            spentValue:0.00,
            podName:'Savings'
        }
        })
  
        let foodDocRef = doc(db,'EuklesUsersData',uid,monthYear,'Food')
     
          await setDoc(foodDocRef,{
            ['Groceries']: {
              plannedValue:0.00,
              spentValue:0.00,
              podName:'Food'
          },
          ['Dining Out']: {
            plannedValue:0.00,
            spentValue:0.00,
            podName:'Food'
        }
          })
        
          
  
         
          navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
        
      }catch(e){
        uploadError(e.message,'OnBoardCheckout.js, uploadUserInfo')
      }
   
    }
 
    

async function updateStripeSubscriptionIdSemiAuto(subscriptionId){

  try{
    let docRef = doc(db,'EuklesUsers', userUid);
    await updateDoc(docRef,{stripeSubscriptionId: subscriptionId,budgetMethod:'Semi-Auto',
     isPremiumUser:true,subscriptionMethod:'web'}).then(res => {
     return true;
   }).catch(err => {
     return false;
   })
 
   
   let uidUpdate = doc(db,'EuklesUsersData',userUid)
   await updateDoc(uidUpdate,{userUid:userUid})
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js, updateStripeSubscriptinIdSemiAuto')
  }


}

 

async function updateAutoUserDocBudgetMethodandStripeDetails(subscriptionId,budgetMethod){
  
  try{
    let docRef = doc(db,'EuklesUsers', userUid);
    let update = await updateDoc(docRef,{stripeSubscriptionId: subscriptionId,budgetMethod:budgetMethod,autoCurrentStep:'NeedFinicityId',
      isPremiumUser:true,subscriptionMethod:'web'}).then(res => {
      return true;
    }).catch(err => {
      return false;
    })
  
    let uidUpdate = doc(db,'EuklesUsersData',userUid)
    await updateDoc(uidUpdate,{userUid:userUid})
  
    return update;
  }catch(e){
    uploadError(e.message,'OnBoardCheckout.js,updateAutoUserDocBudgetMethodandStripeDetails')
  }
 
}





if(isAuthenticated){
  if(loading){
    return (
      <div>
     
      
        
     <Backdrop open={loading} style={{zIndex:1400,backgroundColor:'white'}}>
            <Grid container direction='column'  justifyContent='center' alignItems='center'>
              <Grid item>
              <Ellipsis color={theme.palette.primary.main} />
              </Grid>
         <Grid item style={{paddingTop:5}}>
            <Typography style={{color:theme.palette.primary.fiverGrayText, fontWeight:'bold'}}>Please wait...</Typography>
          </Grid> 
          
            </Grid>
            </Backdrop> 
           
</div>
    )
  }
  else {


  return (
    <Box pt={2} pb={4} >
     
     <div style={{position:'absolute',top:15,right:25}}>
            <OnBoardingMenu navigate={navigate} />
          </div>
          <Grid container direction='row' justifyContent='center' mt={5}>
        <Grid item>
        <img style={{alignSelf:'center',width:200,height:'auto'}} src={BudgetEvenLogo}  alt="Logo" />
        </Grid>
        </Grid>

        <Grid container style={{justifyContent:'center',marginTop:25,alignSelf:'center'}}>
          <div style={{width:550, height:750,backgroundColor:'white',borderRadius:5,border: '1px solid grey'}}>  
              <div style={{width:550,height:100,alignContent:'center',backgroundColor: theme.palette.primary.main,marginBottom:15}}>

                    <Typography variant='h5' style={{textAlign:'center',color:'white',fontWeight:'bold'}}>{location.state.planType === 'Auto' ? 'Budget Even: Auto' : 'Budget Even: Semi-Auto'}</Typography>
              </div>

          
              <Typography style={{color:'red',fontStyle:'italic'}}>{stripeErrorMessage}</Typography>
              <div style={{marginLeft:10,marginRight:10,marginTop:25}}>
              <Typography style={{fontWeight:500}}>Card information</Typography>
              <CardElement />
              </div>

              <div style={{margin:10}}>
              <Typography style={{fontWeight:500}}>Name on card</Typography>

             
              <AppTextField  onChange={(e) => setFirstName(e.target.value)}
            fullWidth error={firstNameError} helperText='' />

     
               </div>

               <div style={{margin:10}}>
              <Typography style={{fontWeight:500}}>Zip</Typography>

             
              <AppTextField  onChange={(e) => setZip(e.target.value)}
            error={zipError}  />

     
               </div>

               <Divider style={{marginTop:25}} />
               <Typography style={{marginLeft:10,marginRight:10,fontWeight:500,marginTop:25}}>Order Summary</Typography>


  <Typography style={{marginLeft:10,marginRight:10,marginBottom:10}}>By clicking the subscribe button below, you agree to subscribe to the Budget Even Auto mode for a monthly payment of $4.99.
  You may cancel your subscription at any time.</Typography>





  <Box paddingX={3}>
    <FlexBetween my={2}>
      <H3>Today's Total</H3>
      <H3 color="primary.main">{location.state.planType === 'Auto' ? '$4.99' : '$2.49'}</H3>
    </FlexBetween>

    <LoadingButton disabled={canSubmit ? false : true} fullWidth  onClick={() => checkFields()}  loading={stripeLoading}  variant="contained">
        Subscribe
      </LoadingButton>

  </Box>



           
            

          </div>
    
        </Grid>



    
    
     
 
<BottomSpacer />

    </Box>
  )
  
}
 
}
else {
  return(
    <Navigate to='/' replace={true} />
  )
}


};

export default OnBoardCheckout;
