

import { Small,} from "components/uicomponents/Typography";
import { Ellipsis} from 'react-spinners-css'
import { getFunctions, httpsCallable } from "firebase/functions";
import { Grid, Backdrop,useMediaQuery,Table, TableBody, TableCell, TableHead, TableRow,MenuItem,Select,useTheme,Divider, Typography,Button} from '@mui/material';
import React,{useEffect,useRef, useState} from 'react'
import { useLocation } from 'react-router';
import {  useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';

import { getAuth} from "firebase/auth";
import { getCurrentMonthAndYearString,getTodaysEpoch } from "utils/Helpers";
import { collection, doc, getFirestore, setDoc, getDocs, updateDoc,deleteDoc,  addDoc } from "firebase/firestore";
import BudgetEvenLogo from '../landingpage/landingimages/budgeteven.svg'
import { getLastMonthStartAndEndEpoch } from "utils/Helpers";
import { getMonthAndYearStringFromEpoch } from "utils/Helpers";
import { handleSaveAndGenerateCashFlow } from "utils/CashFlowGeneration";
import AlertTitle from '@mui/material/AlertTitle';
import LightTextField from "components/uicomponents/LightTextField";
import { formatTransactionDate } from "utils/Helpers";

const db = getFirestore();
const functions = getFunctions();

export default function InsideAutoSubmit() {


    const navigate = useNavigate();
    const theme = useTheme()
    const isDownMedium = useMediaQuery(theme.breakpoints.down('md'));
    const currentMonth = getCurrentMonthAndYearString()
    const location = useLocation();
    const finCustomerId = location.state.finCustomerId
    const url = location.state.url
   // const theWidth = Dimensions.get('window').width
    const uid = getAuth().currentUser.uid  
    //openLineItemMenu
    


    const [canSaveGroupAndLineItem,setCanSaveGroupAndLineItem] = useState(true)
    //const [editLineDescription, setEditLineDescription] = useState('')
    const [editLineDescription, setEditLineDescription] = useState('')

    const [groupName, setGroupName] = useState('')
    const [showAddLineItemOnly, setShowAddLineItemOnly] = useState(false)
    const [showAddBudgetGroupAndLineItem, setShowAddBudgetGroupandLineItem] = useState(false)


   useEffect(() => {

   },[groupName,])


    const [customPodsArray,setCustomPodsArray] = useState([])                 
    const [loading, setLoading] = useState(false);
  
    const [localBudgetAccounts, setLocalBudgetAccounts] = useState(location.state.budgetAccounts)
  

 
    const [selectedPodTitle, setSelectedPodTitle] = useState('Select a Budget Group')
    const [selectedlineItemDescription, setSelectedLineItemDescription] = useState('Select a Line Item')
    const [showTransactionDetails, setShowTransactionDetails] = useState(false)
    const [transactionQuestionsArray, setTransactionQuestionsArray] = useState([])



  
    const [showMinimumTransactionError,setShowMinimumTransactionError] = useState(false)
    const [showInfoAboutGoingManual, setShowInfoAboutGoingManual] = useState(false)


   
    const trackingRulesRef = useRef([])
         const podTitles = ['Create a Category','Income','Savings','Education','Entertainment','Financial','Food and Drink','Gifts and Charity','Housing','Kids','Medical',
        'Mobile Payments','Pets','Personal','Transportation','Travel']

            //INCOME
        const [incomePod, setIncomePod] = useState({title:'Income',transactionsArray:[],data:[{description:'Paychecks',plannedValue:0.00, spentValue:0.00, podName:'Income'},
        {description:'Interest',plannedValue:0.00, spentValue:0.00, podName:'Income'},
        {description:'Credits',plannedValue:0.00, spentValue:0.00, podName:'Income'},
        {description:'Deposits',plannedValue:0.00, spentValue:0.00, podName:'Income'},
        {description:'Checks',plannedValue:0.00, spentValue:0.00, podName:'Income'},

        ]})

     //SAVINGS
     const [savingsPod, setSavingPod] = useState({title:'Savings',transactionsArray:[],data:[{description:'Savings',plannedValue:0.00, spentValue:0.00}]})

    
            //ENTERTAINMENT
        const [entertainmentPod, setEntertainmentPod] = useState({title:'Entertainment',transactionsArray:[], data:[{description:'Alcohol and Bars',plannedValue:0.00, spentValue:0.00, podName:'Entertainment'},
        {description:'Media',plannedValue:0.00, spentValue:0.00, podName:'Entertainment'},
        {description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Entertainment'},
        ]})

        //EDUCATION
        const [educationPod, setEducationPod] = useState({title:'Education',transactionsArray:[],data:[{description:'Tuition',plannedValue:0.00, spentValue:0.00, podName:'Education'},
        {description:'Books and Supplies',plannedValue:0.00, spentValue:0.00, podName:'Education'},
        {description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Education'},
        ]})


        //FINANCIAL
        const [financialPod, setFinancialPod] = useState({title:'Financial',transactionsArray:[],data:[{description:'Fees and Charges',plannedValue:0.00, spentValue:0.00, podName:'Financial'},
        {description:'Taxes',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
        {description:'ATM',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
        {description:'Life Insurance',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
        {description:'Loans',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
        {description:'Checks',plannedValue:0.00, spentValue:0.00, podName:'Financial'}, 
        {description:'Transfers',plannedValue:0.00, spentValue:0.00, podName:'Financial'} ]})

        //FOOD AND DRINK
        const [foodAndDrinkPod, setFoodAndDrinkPod] = useState({title:'Food and Drink',transactionsArray:[],data:[{description:'Groceries',plannedValue:0.00, spentValue:0.00, podName:'Food and Drink'},
        {description:'Coffee',plannedValue:0.00, spentValue:0.00, podName:'Food and Drink'},
        {description:'Dining Out',plannedValue:0.00, spentValue:0.00, podName:'Food and Drink'},

        ]})

        //GIFTS AND CHARITY
        const [giftsAndCharityPod, setGiftsAndCharityPod] = useState({title:'Gifts and Charity',transactionsArray:[],data:[{description:'Donations',plannedValue:0.00, spentValue:0.00, podName:'Gifts and Charity'},
        {description:'Gifts',plannedValue:0.00, spentValue:0.00, podName:'Gifts and Charity'}]})

        //HOUSING
        const [housingPod, setHousingPod] = useState({title:'Housing',transactionsArray:[],data:[{description:'Mortgage or Rent',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        {description:'Internet and Phone',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        {description:'Property Tax',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        {description:'Maintenance',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        {description:'Utilities',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        {description:'Insurance',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        {description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Housing'},
        ]})




        //KIDS
        const [kidsPod, setKidsPod] = useState({title:'Kids',transactionsArray:[],data:[{description:'Child Care',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
        {description:'Child Support',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
        {description:'Toys',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
        {description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Kids'},
        ]})


        //MEDICAL
        const [medicalPod, setMedicalPod] = useState({title:'Medical',transactionsArray:[],data:[{description:'Dentist',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Doctor',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Eyecare',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Health Insurance',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Pharmacy',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        ]})




        const [mobilePaymentsPod, setMobilePaymentsPod] = useState({title:'Medical',transactionsArray:[],data:[{description:'Apple Cash',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Cash App',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Paypal',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Venmo',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        {description:'Zelle',plannedValue:0.00, spentValue:0.00, podName:'Medical'},
        ]})




        //PETS
        const [petsPod, setPetsPod] = useState({title:'Pets',transactionsArray:[],data:[{description:'Food and Care',plannedValue:0.00, spentValue:0.00, podName:'Pets'},
        {description:'Veterinary',plannedValue:0.00, spentValue:0.00, podName:'Pets'},
        {description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Pets'},

        ]})

        //PESONAL
        const [personalPod, setPersonalPod] = useState({title:'Personal',transactionsArray:[],data:[{description:'Misc',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
        {description:'Health and Gym',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
        {description:'Shopping',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
        {description:'Phone',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
        {description:'Clothing',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
        {description:'Tuition',plannedValue:0.00, spentValue:0.00, podName:'Personal'},
        ]})


        //TRANSPORTATION
        const [transportationPod, setTransportationPod] = useState({title:'Transportation',transactionsArray:[],data:[{description:'Gas',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
        {description:'Parking',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
        {description:'Insurance',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
        {description:'Auto Payment',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
        {description:'Maintenance',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
        {description:'Other',plannedValue:0.00, spentValue:0.00, podName:'Transportation'},
        ]})

        //TRAVEL
        const [travelPod, setTravelPod] = useState({title:'Travel',transactionsArray:[],data:[{description:'Air Travel', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
        {description:'Hotel', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
        {description:'Vacation', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
        {description:'Other', plannedValue:0.00, spentValue:0.00, podName:'Travel'},
        ]})



        //UNCATEGORIZED
        const [uncategorizedPod, setUncategorizedPod] = useState({title:'Uncategorized',transactionsArray:[],data:[{
            description:'Uncategorized',plannedValue:0.00, spentValue:0.00, podName:'Uncategorized'
        }]})








useEffect(() => {
 
    getLastMonthsTransactions()
    generateCashFlowLocal()
    updateStep()
    //submitAnalytics();
  
},[])

async function updateStep(){
    let docRef = doc(db,'EuklesUsers',uid)
    await updateDoc(docRef,{currentStep:'InsideAutoSubmit'})
}

useEffect(() => {
    document.body.style.backgroundColor = 'white';
},[])

// async function submitAnalytics(){
//     let plat = Platform.OS === 'ios' ? 'ios' : 'android'
//     await analytics().logEvent('aa_userOnAutoSubmitRevised',{
//         platform: plat,
        
//     })
// }




async function getLastMonthsTransactions(){
 
   
    const data  = getLastMonthStartAndEndEpoch()
 
    let retrieveFinTransactions = httpsCallable(functions,'getFinTransactionsSingleAccount')
    //let retrieveFinTransactions = functions().httpsCallable('getFinTransactionsSingleAccount');

    let currentArray = localBudgetAccounts;
   
  


    let allTransactions = []
   
  for(let i = 0; i < currentArray.length; i++){

    let newTransactionArray = await retrieveFinTransactions({customerId: finCustomerId, accountId: currentArray[i].id, uid: uid, monthStartEpoch: data.monthStart, monthEndEpoch: data.monthEnd}).then(function(result){

      return result.data.newTransactionArray;
      }).catch(function(error){
  
          return false;
      });

      
    
     
      if(!newTransactionArray){
       
        currentArray[i].errorRetrievingTransactions = true
        currentArray[i].isLoadingTransactions = false;
      }
      else {
    
        currentArray[i].numberOfTransactions = newTransactionArray[0].length;
        currentArray[i].accountTransactionsArray = newTransactionArray[0]
        currentArray[i].isLoadingTransactions = false;
   
        
        for(let b = 0; b < newTransactionArray[0].length; b++){

           let transaction = newTransactionArray[0][b]
        
           allTransactions.push(transaction)
        }
      }
      setLocalBudgetAccounts(previousState => [...currentArray])
  }

 

if(allTransactions.length > 15){
    createSampleBudget(allTransactions)
}
else {
    setShowMinimumTransactionError(true)
 
}
  


}

async function handleNoCheckingOrNoCardStepOne(){
    setShowInfoAboutGoingManual(true)



}

async function localProcessCancellation(){
setLoading(true)

setShowInfoAboutGoingManual(false)
let res = await processCancellationMethod()
if(res){
  // setLoading(false)
  // navigation.navigate('Main')
  handleStartBudgetFromScratch()
  
}
else {

}
}

async function processCancellationMethod(){
    let accountsCollection = collection(db,'EuklesUsersData',uid, 'Accounts')
    let accountsArray = await getDocs(accountsCollection).then(querySnapshot => {
        let localArray = []
        querySnapshot.forEach(doc => {
            localArray.push(doc.id)
        })
        return localArray
    }).catch(e => {
        return []
    })

    for(let i = 0; i < accountsArray.length; i++){
        let delDocRef = doc(db,'EuklesUsersData',uid,'Accounts',accountsArray[i])
        await deleteDoc(delDocRef)

       }

       let cashFlowCollection = collection(db,'EuklesUsersData',uid, 'CashFlowData')
       let cashFlowArray = await getDocs(cashFlowCollection).then(querySnapshot => {
        let localArray = []
        querySnapshot.forEach(doc => {
            localArray.push(doc.id)
        })
        return localArray
    }).catch(e => {
        return []
    })

    for(let i = 0; i < cashFlowArray.length; i++){
        let delDocRef = doc(db,'EuklesUsersData',uid,'CashFlowData',cashFlowArray[i])
        await deleteDoc(delDocRef)
   
        }

        let monthYear = getCurrentMonthAndYearString();

        let podsCollection = collection(db,'EuklesUsersData',uid, monthYear)
        let currentPods = await getDocs(podsCollection).then(querySnapshot => {
            let localArray = []
            querySnapshot.forEach(doc => {
                localArray.push(doc.id)
            })
            return localArray
        }).catch(e => {
            return []
        })

        for(let i = 0; i < currentPods.length; i++){
            let podsDocRef = doc(db,'EuklesUsersData',uid,monthYear,currentPods[i])
            await deleteDoc(podsDocRef)
     
            }

        let incomeRef = doc(db,'EuklesUsersData',uid,monthYear,'Income')

        await setDoc(incomeRef,{
            ['Paychecks']:{
                plannedValue:0.00,
                spentValue:0.00,
                podName:'Income'
            }
        
        })

        let savingsRef = doc(db,'EuklesUsersData',uid,monthYear,'Savings')

        await setDoc(savingsRef,{
            ['Savings']:{
                plannedValue:0.00,
                spentValue:0.00,
                podName:'Savings'
            }
        
        })

        let trackingRulesCollection = collection(db,'EuklesUsersData',uid, 'TrackingRules')
        let trackingRulesArray = await getDocs(trackingRulesCollection).then(querySnapshot => {
            let localArray = []
            querySnapshot.forEach(doc => {
                localArray.push(doc.id)
            })
            return localArray
        }).catch(e => {
            return []
        })

        for(let i = 0; i < trackingRulesArray.length; i++){
            let delRuleRef = doc(db,'EuklesUsersData',uid,'TrackingRules',trackingRulesArray[i])
            await deleteDoc(delRuleRef)
       
            }

        let updateUserDoc = doc(db,'EuklesUsers',uid)
        await updateDoc(updateUserDoc,{isPremiumUser:false,
            finCustomerId:'nil',
            noCashFlowSet: true})

        let deleteFin = httpsCallable(functions,'deleteFinCsutomer')

await deleteFin({finCustomerId: finCustomerId}).then(res => {
return true;
}).catch(e => {
return false
})

    return true;
}

async function handleStartBudgetFromScratch(){

let emptyBudgetMonth = getCurrentMonthAndYearString();
  setLoading(true)
  let docRef = doc(db,'EuklesUsersData',uid,emptyBudgetMonth,'Income')
  await setDoc(docRef,{'Paycheck':{plannedValue:0.00, spentValue:0.00, podName:'Income'}}).then(res => {
    return true;
  }).catch(e => {
    return false
  })

let savingsDoc = doc(db,'EuklesUsersData',uid,emptyBudgetMonth,'Savings')
await setDoc(savingsDoc,{'Savings': {
    plannedValue: 0.00,
    spentValue: 0.00,
    podName:'Savings'
}}).then(res => {
    return true;
  }).catch(e => {
  
    return false;
  })

    let mArray = [emptyBudgetMonth]
    let monthsRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
    await setDoc(monthsRef,{mArray})

 
  setLoading(false)
  navigate('/dashboard/budgethome',{state:{needsToRefresh: false}})
  //navigation.navigate('Main') navigate('/dashboard/budgethome',{state:{needsToRefresh: true}})
   }




function createSampleBudget(transactions){
    //last month



    let deleteArray = []
    //delete array: buy, credit card payment

    let atmFeeArray = []
    let advertisingArray = []
    let airTravelArray = []
    let alcoholAndBarsArray = []
    let allowanceArray = []
    let amusementArray = []
    let artsArray = []
    let autoAndTransportArray = []
    let autoInsuranceArray = []
    let autoPaymentArray = []

    let babySuppliesArray = []
    let babysitterAndDaycareArray = []
    let bankFeeArray =[]
    let billsAndUtilitiesArray = []
    let bonusArray = []
    let booksArray = []
    let booksAndSuppliesArray = []
    let businessServicesArray = []
  

    let cashAndAtmArray = []
    let charityArray = []
    let checkArrayPositive = []
    let checkArrayNegative = []
    let childSupportArray = []
    let clothingArray = []
    let coffeShopsArray = []

    let dentistArray = []
    let depositArray =[]
    let doctorArray = []

    let educationArray = []
    let electronicsAndSoftwareArray = []
    let entertainmentArray = []
    let eyecareArray = []

    let fastFoodArray = []
    let federalTaxArray = []
    let feesAndChargesArray = []
    let financeChargeArray = []
    let financialArray = []
    let financialAdvisorArray = []
    let foodAndDiningArray = []
    let furnishingsArray = []

    let gasAndFuelArray = []
    let giftArray = []
    let giftsAndDonationsArray = []
    let groceriesArray = []
    let gymArray = []

    let hairArray = []
    let healthInsuranceArray = []
    let healthAndFitnessArray = []
    let hobbiesArray = []
    let homeArray = []
    let homeImprovementArray = []
    let homeInsuranceArray = []
    let homePhoneArray = []
    let homeServicesArray = []
    let homeSuppliesArray = []
    let hotelArray = []

    let incomeArray =[]
    let interestArray = []
    let internetArray = []
    let investmentsArray = []

    let kidsArray = []
    let kidsActivitiesArray = []
    // "Late Fee","Laundry","Lawn & Garden","Legal","Life Insurance",
    // "Loan Fees and Charges","Loan Insurance","Loan Interest","Loan Payment","Loan Principal","Loans","Local Tax","Low Balance",
    let lateFeeArray = []
    let laundryArray = []
    let lawnAndGardenArray = []
    let legalArray = []
    let lifeInsuranceArray = []
    let loanFeesAndChargesArray = []
    let loanInsuranceArray = []
    let loanInterestArray = []
    let loanPaymentArray = []
    let loanPrincipalArray = []
    let loansArray = []
    let localTaxArray = []
    let lowBalanceArray = []

    // "Mobile Phone","Mortgage & Rent","Movies & DVDs","Music",
    let mobilePhoneArray = []
    let mortgageAndRentArray = []
    let moviesAndDvdsArray = []
    let musicArray = []

    //"Newspapers & Magazines",
    let newspapersAndMagazinesArray = []

    //O 
    let officeSuppliesArray = []

    //P "Parking","Paycheck","Personal Care","Pet Food & Supplies","Pet Grooming","Pets","Pharmacy","Printing","Property Tax","Public Transportation",
    let parkingArray = []
    let paycheckArray = []
    let personalCareArray = []
    let petFoodAndSuppliesArray = []
    let petGroomingArray = []
    let petsArray = []
    let pharmacyArray = []
    let printingArray = []
    let propertyTaxArray = []
    let publicTransportationArray = []

    //R "Reimbursement","Rental Car & Taxi", "Restaurants",
    let reimbursementArray = []
    let rentalCarAndTaxiArray = []
    let restaurantsArray = []

    //S "Sales Tax","Sell","Services & Parts","Service Fee","Shipping","Shopping","Spa & Massage","Sporting Goods","Sports","State Tax","Streaming Services","Student Loan",
    let salesTaxArray = []
    let sellArray = []
    let servicesAndPartsArray = []
    let serviceFeeArray = []
    let shippingArray = []
    let shoppingArray = []
    let spaAndMassageArray = []
    let sportingGoodsArray = []
    let sportsArray = []
    let stateTaxArray = []
    let streamingServicesArray = []
    let studentLoanArray = []

    //T   "Taxes","Television","Toys","Trade Commissions","Transfer","Transfer for Cash Spending","Travel","Tuition",
    let taxesArray = []
    let televisionArray = []
    let toysArray = []
    let tradeCommissionsArray = []
    let transferArray = []
    let transferForCashSpendingArray = []
    let travelArray = []
    let tuitionArray = []

    //U "Uncategorized","Utilities","Vacation","Veterinary"
    let uncategorizedArray = []
    let uncategorizedCreditsArray = []
    let uncategorizedQuestionsArray = []
    let utilitiesArray = []
    let vacationArray = []
    let veterinaryArray = []


    let localQuestionsArray = []
    

   for(let i = 0; i < transactions.length; i++){

        if(typeof transactions[i].categorization !== 'undefined'){
            if(typeof transactions[i].categorization.category !== 'undefined'){
                let transaction = transactions[i]
                let category = transaction.categorization.category
          
                //A
                    switch(category){
                        case "ATM Fee":
                            atmFeeArray.push(transaction)
                            break;
                        
                        case "Advertising":
                            advertisingArray.push(transaction)
                            break;
                        
                        case "Air Travel":
                            airTravelArray.push(transaction)
                            break;
                        
                        case "Alcohol & Bars":
                            alcoholAndBarsArray.push(transaction)
                            break;
                        
                        case "Allowance":
                            if(transaction.amount > 0){
                                allowanceArray.push(transaction)
                            }
                           
                            break;

                        case "Amusement":
                            amusementArray.push(transaction)
                            break;
                        
                        case "Arts":
                            artsArray.push(transaction)
                            break;
                        
                        case "Auto & Transport":
                            autoAndTransportArray.push(transaction)
                            break;
                        
                        case "Auto Insurance":
                            autoInsuranceArray.push(transaction)
                            break;

                        case "Auto Payment":
                            autoPaymentArray.push(transaction)
                            break;
                        
                        default:
                           // console.log('')
                            break;

                        
                    }


                //B
                // "Baby Supplies","Babysitter & Daycare","Bank Fee","Bills & Utilities","Bonus","Books","Books & Supplies","Business Services","Buy",
                //deleting buy,busines services
                    switch(category){
                        case 'Baby Supplies':
                            babySuppliesArray.push(transaction)
                            break;

                        case 'Babysitter & Daycare':
                            babysitterAndDaycareArray.push(transaction)
                            break;

                        case 'Bank Fee':
                            bankFeeArray.push(transaction)
                            break;

                        case 'Bills & Utilities':
                            // case 'Electronics & Software':
                            //     electronicsAndSoftwareArray.push(transaction);
                            //     break;

                            if(typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
                                if(transaction.categorization.normalizedPayeeName === 'Apple'){
                                    electronicsAndSoftwareArray.push(transaction)
                                }
                                else {
                                    billsAndUtilitiesArray.push(transaction)
                                }
                            }
                          
                            break;

                        case 'Bonus':
                            if(transaction.amount > 0){
                                bonusArray.push(transaction)
                            }
                   
                            break;

                        case 'Books':
                            booksArray.push(transaction)
                            break;

                        case 'Books & Supplies':
                            booksAndSuppliesArray.push(transaction)
                            break;

                        case 'Business Services':
                            businessServicesArray.push(transaction)
                            break;
                        
                        case 'Buy':
                            //businessServicesArray.push(transaction)
                            deleteArray.push(transaction)
                            break;
                        
                        default:
                            break;
                    }

                //C  "Cash & ATM","Charity","Check","Child Support","Clothing","Coffee Shops","Credit Card Payment",
                //deleting credit cardy payment
                switch(category){
                    case 'Cash & ATM':
                        cashAndAtmArray.push(transaction)
                        break;
                    
                    case 'Charity':
                        charityArray.push(transaction)
                        break;
                    
                    case 'Check':
                        //checkArray.push(transaction)
                        if(transaction.amount > 0){
                            checkArrayPositive.push(transaction)
                        } else {
                            checkArrayNegative.push(transaction)
                        }
                      
                    
                        break;

                    case 'Child Support':
                        childSupportArray.push(transaction)
                        break;
                    
                    case 'Clothing':
                        clothingArray.push(transaction)
                        break;

                    case 'Coffee Shops':
                        coffeShopsArray.push(transaction)
                        break;

                    case 'Credit Card Payment':
                        deleteArray.push(transaction)
                        break;

                    default:
                        break;
                }

                //D  "Dentist","Deposit","Dividend & Cap Gains", "Doctor",
                //deleting dividend and cap gains
                switch(category){
                    case 'Dentist':
                        dentistArray.push(transaction)
                        break;
                    
                    case 'Deposit':
                        depositArray.push(transaction)
                        break;

                    case 'Dividend & Cap Gains':
                        deleteArray.push(transaction)
                        break;

                    case 'Doctor':
                        doctorArray.push(transaction)
                        break;
                    
                    default:
                        break;
                

                }

                //E   "Education","Electronics & Software","Entertainment","Eyecare",

                switch(category){
                    case 'Education':
                        educationArray.push(transaction);
                        break;
                    
                    case 'Electronics & Software':
                        electronicsAndSoftwareArray.push(transaction);
                        break;

                    case 'Entertainment':
                        entertainmentArray.push(transaction);
                        break;
                    
                    case 'Eyecare':
                        eyecareArray.push(transaction);
                        break;
                    
                    default:
                        break;

                }



                //F    "Fast Food","Federal Tax","Fees & Charges","Finance Charge","Financial","Financial Advisor","Food & Dining","Furnishings",

                switch(category){
                    case 'Fast Food':
                        fastFoodArray.push(transaction);
                        break;

                    case 'Federal Tax':
                        federalTaxArray.push(transaction)
                        break;
                    
                    case 'Fees & Charges':
                        feesAndChargesArray.push(transaction)
                        break;

                    case 'Finance Charge':
                        financeChargeArray.push(transaction)
                        break;

                    case 'Financial':
                        ///financialArray.push(transaction)
                        localQuestionsArray.push({category:'Financial',transaction: transaction})
                        break;

                    case 'Financial Advisor':
                        financialAdvisorArray.push(transaction)
                        break;

                    case 'Food & Dining':
                        foodAndDiningArray.push(transaction)
                        break;

                    case 'Furnishings':
                        furnishingsArray.push(transaction)
                        break;
                    
                    default:
                        break;

                }

                //G  "Gas & Fuel","Gift","Gifts & Donations","Groceries","Gym",

                switch(category){
                    case 'Gas & Fuel':
                        gasAndFuelArray.push(transaction)
                        break;

                    case 'Gift':
                        if(transaction.amount < 0){
                            giftArray.push(transaction)
                        }
                        
                        break;
                    
                    case 'Gifts & Donations':
                        giftsAndDonationsArray.push(transaction)
                        break;

                    case 'Groceries':
                        groceriesArray.push(transaction)
                        break;

                    case 'Gym':
                        gymArray.push(transaction)
                        break;

                    default:
                        break;
                    

                }

                //H    "Hair","Health & Fitness","Hobbies","Home","Home Improvement","Home Insurance","Home Phone","Home Services","Home Supplies","Hotel",
                
                switch(category){
                    case 'Hair':
                        hairArray.push(transaction)
                        break;

                   case 'Health Insurance':
                        healthInsuranceArray.push(transaction)
                        break;
                    
                    case 'Health & Fitness':
                        healthAndFitnessArray.push(transaction)
                        break;
                    
                    case 'Hobbies':
                        hobbiesArray.push(transaction)
                        break;

                    case 'Home':
                        homeArray.push(transaction)
                        break;

                    case 'Home Improvement':
                        homeImprovementArray.push(transaction)
                        break;

                    case 'Home Insurance':
                        homeInsuranceArray.push(transaction)
                        break;

                    case 'Home Phone':
                        homePhoneArray.push(transaction)
                        break;

                    case 'Home Services':
                        homeServicesArray.push(transaction)
                        break;

                    case 'Home Supplies':
                        homeSuppliesArray.push(transaction)
                        break;

                    case 'Hotel':
                        hotelArray.push(transaction)
                        break;

                    default:
                        break;
                }

                //I   "Income","Interest Income","Internet","Investments",
                //deleting "Investments"

                switch(category){
                    case 'Income':
                        incomeArray.push(transaction)
                        break;

                    case 'Interest Income':
                        interestArray.push(transaction)
                        break;
                    
                    case 'Internet':
                        internetArray.push(transaction)
                        break;

                        case 'Internet/Broadband Charges':
                            internetArray.push(transaction)
                            break;

                    case 'Investments':
                        deleteArray.push(transaction)
                        break;
                    
                    default:
                        break;

                }

                //K "Kids","Kids Activities",
                switch(category){
                    case 'Kids':
                        kidsArray.push(transaction)
                        break;
                    
                    case 'Kids Activities':
                        kidsActivitiesArray.push(transaction)
                        break;
                    
                    default:
                        break;
                }

                //L    "Late Fee","Laundry","Lawn & Garden","Legal","Life Insurance","Loan Fees and Charges","Loan Insurance","Loan Interest","Loan Payment","Loan Principal","Loans","Local Tax","Low Balance",
                switch(category){
                    case 'Late Fee':
                        lateFeeArray.push(transaction)
                        break;
                    
                    case 'Laundry':
                        laundryArray.push(transaction)
                        break;

                    case 'Lawn & Garden':
                        lawnAndGardenArray.push(transaction)
                        break;

                    case 'Legal':
                        legalArray.push(transaction)
                        break;
                    
                    case 'Life Insurance':
                        lifeInsuranceArray.push(transaction)
                        break;

                    case 'Loan Fees and Charges':
                        loanFeesAndChargesArray.push(transaction)
                        break;

                    case 'Loan Insurance':
                        loanInsuranceArray.push(transaction)
                        break;

                    case 'Loan Interest':
                        loanInterestArray.push(transaction)
                        break;

                    case 'Loan Payment':
                        loanPaymentArray.push(transaction)
                        break;

                    case 'Loan Principal':
                        loanPrincipalArray.push(transaction)
                        break;

                    case 'Loans':
                        loansArray.push(transaction)
                        break;

                    case 'Local Tax':
                        localTaxArray.push(transaction)
                        break;

                    case 'Low Balance':
                        lowBalanceArray.push(transaction)
                        break;

                    default:
                        break;
                }

                //M  "Mobile Phone","Mortgage & Rent","Movies & DVDs","Music",
                switch(category){
                    case 'Mobile Phone':
                        mobilePhoneArray.push(transaction)
                        break;
                    
                    case 'Mortgage & Rent':
                        mortgageAndRentArray.push(transaction)
                        break;
                    
                    case 'Movies & DVDs':
                        moviesAndDvdsArray.push(transaction)
                        break;

                    case 'Music':
                        musicArray.push(transaction)
                        break;
                    
                    default:
                        break;
                }

                //N "Newspapers & Magazines",
                switch(category){
                    case 'Newspapers & Magazines':
                        newspapersAndMagazinesArray.push(transaction)
                        break;
                    
                    default:
                        break;
                }

                //O  "Office Supplies",
                switch(category){
                    case 'Office Supplies':
                        officeSuppliesArray.push(transaction)
                        break;

                    default:
                        break;


                    
                }

                //P "Parking","Paycheck","Personal Care","Pet Food & Supplies","Pet Grooming","Pets","Pharmacy","Printing","Property Tax","Public Transportation",
                switch(category){
                    case 'Parking':
                        parkingArray.push(transaction)
                        break;

                    case 'Paycheck':
                        paycheckArray.push(transaction)
                        break;

                    case 'Personal Care':
                        personalCareArray.push(transaction)
                        break;

                    case 'Pet Food & Supplies':
                        petFoodAndSuppliesArray.push(transaction)
                        break;

                    case 'Pet Grooming':
                        petGroomingArray.push(transaction)
                        break;

                    case 'Pets':
                        petsArray.push(transaction)
                        break;

                    case 'Pharmacy':
                        pharmacyArray.push(transaction)
                        break;

                    case 'Printing':
                        printingArray.push(transaction)
                        break;

                    case 'Property Tax':
                        propertyTaxArray.push(transaction)
                        break;

                    case 'Public Transportation':
                        publicTransportationArray.push(transaction)
                        break;

                    default:
                    break;
                }

               //R "Reimbursement","Rental Car & Taxi", "Restaurants",
                 switch(category){
                    case 'Reimbursement':
                        reimbursementArray.push(transaction)
                        break;

                    case 'Rental Car & Taxi':
                        rentalCarAndTaxiArray.push(transaction)
                        break;

                    case 'Restaurants':
                        restaurantsArray.push(transaction)
                        break;

                    default:
                    break;
                    
                 }

                //S "Sales Tax","Sell","Services & Parts","Service Fee","Shipping","Shopping","Spa & Massage","Sporting Goods","Sports","State Tax","Streaming Services","Student Loan",
                //deleting sell
                switch(category){
                    case 'Sales Tax':
                        salesTaxArray.push(transaction)
                        break;

                    case 'Sell':
                        deleteArray.push(transaction)
                        break;

                        case 'Services & Parts':
                            servicesAndPartsArray.push(transaction)
                            break;

                    case 'Service & Parts':
                        servicesAndPartsArray.push(transaction)
                        break;

                    case 'Service Fee':
                        serviceFeeArray.push(transaction)
                        break;

                    case 'Shipping':
                        shippingArray.push(transaction)
                        break;

                    case 'Shopping':
                        shoppingArray.push(transaction)
                        break;

                    case 'Spa & Massage':
                        spaAndMassageArray.push(transaction)
                        break;

                    case 'Sporting Goods':
                        sportingGoodsArray.push(transaction)
                        break;

                    case 'Sports':
                        sportsArray.push(transaction)
                        break;

                    case 'State Tax':
                        stateTaxArray.push(transaction)
                        break;

                    case 'Streaming Services':
                        streamingServicesArray.push(transaction)
                        break;

                    case 'Student Loan':
                        studentLoanArray.push(transaction)
                        break;
                    
                    default:
                        break;



                }

                //T  "Taxes","Television","Toys","Trade Commissions","Transfer","Transfer for Cash Spending","Travel","Tuition",
                switch(category){
                    case 'Taxes':
                        taxesArray.push(transaction)
                        break;

                    case 'Television':
                        televisionArray.push(transaction)
                        break;

                    case 'Toys':
                        toysArray.push(transaction)
                        break;

                    case 'Trade Commissions':
                        deleteArray.push(transaction)
                        break;

                    case 'Transfer':
                      
                        transferArray.push(transaction)
                        //localQuestionsArray.push({category:'Transfer',transaction: transaction})
                        break;

                    case 'Transfer for Cash Spending':
                        //transferArray.push(transaction)
                        deleteArray.push(transaction)
                        break;

                    case 'Travel':
                        travelArray.push(transaction)
                        break;

                    case 'Tuition':
                        tuitionArray.push(transaction)
                        break;

                    default:
                        break;
                    
                }

                //U and V "Uncategorized","Utilities","Vacation","Veterinary"
                switch(category){
                    case 'Uncategorized':
                      
                        if(transaction.amount > 0){
                            uncategorizedCreditsArray.push(transaction)
                        }
                        else {
                            //uncategorizedArray.push(transaction)
                            uncategorizedQuestionsArray.push(transaction)
                            localQuestionsArray.push({category:'Uncategorized',transaction: transaction})
                        }
                       
               
                        break;

                    case 'Utilities':
                        utilitiesArray.push(transaction)
                        break;

                    case 'Vacation':
                        vacationArray.push(transaction)
                        break;

                    case 'Veterinary':
                        veterinaryArray.push(transaction)
                        break;

                    default:
                        break;
                }







            }
        }
   }
   

   //sort transfers
   //description
   //best representation
   //see what's left over 

   let cashAppArrayNegative = []
   let cashAppArrayPositive = []
   let appleCashArrayNegative = []
   let appleCashArrayPositive = []
   let paypalArrayNegative = []
   let paypalArrayPositive = []
   let venmoArrayNegative = []
   let venmoArrayPositive = []
   let zelleArrayNegative = []
   let zelleArrayPositive = []
   let unclassifiedTransferPositive = []

   
   for(let i = 0; i < transferArray.length;  i++){
    
    let classified = false
    let transaction = transferArray[i]
    let descriptionIncludesZelle = transaction.description.includes('Zelle') ? true : false
    let bestRepIncludesZelle = transaction.description.includes('Zelle') ? true : false
        if(descriptionIncludesZelle || bestRepIncludesZelle){
            classified = true
            if(transaction.amount > 0){
                zelleArrayPositive.push(transaction)
            }
            else {
                zelleArrayNegative.push(transaction)
            }
        } 
    let descriptionIncludesCashApp = transaction.description.includes('CASH APP') ? true : false
    let bestRepIncludesCashApp = transaction.description.includes('CASH APP') ? true : false

    if(descriptionIncludesCashApp || bestRepIncludesCashApp){
        classified = true
        if(transaction.amount > 0){
            cashAppArrayPositive.push(transaction)
        }else {
            cashAppArrayNegative.push(transaction)
        }
       
    }
   


    let descriptionIncludesAppleCash = transaction.description.includes('APPLE CASH') ? true : false
    let bestRepIncludesAppleCash = transaction.description.includes('APPLE CASH') ? true : false

    if(descriptionIncludesAppleCash || bestRepIncludesAppleCash){
        classified = true
        if(transaction.amount > 0){
            appleCashArrayPositive.push(transaction)
        }else {
            appleCashArrayNegative.push(transaction)
        }
       
    }
  

    let descriptionIncludesPayPal= transaction.description.includes('PAYPAL') ? true : false
    let bestRepIncludesPayPal = transaction.description.includes('PAYPAL') ? true : false

    if(descriptionIncludesPayPal || bestRepIncludesPayPal){
        classified = true
        if(transaction.amount > 0){
            paypalArrayPositive.push(transaction)
        }else {
            paypalArrayNegative.push(transaction)
        }
       
    }
   

    let descriptionIncludesVenmo= transaction.description.toLocaleLowerCase().includes('venmo') ? true : false
    let bestRepIncludesVenmo = transaction.description.toLocaleLowerCase().includes('venmo') ? true : false

    if(descriptionIncludesVenmo || bestRepIncludesVenmo){
        classified = true
        if(transaction.amount > 0){
            venmoArrayPositive.push(transaction)
        }else {
           venmoArrayNegative.push(transaction)
        }
       
    }
   
    if(!classified){
  
        if(transaction.amount > 0){
            unclassifiedTransferPositive.push(transaction)
        }
        else {
          localQuestionsArray.push({category:'Transfer',transaction: transaction})
        }
       
    }


   }



//    for(let i = 0; i < uncategorizedQuestionsArray.length; i++){

//    }

 


   //Skipping for now checkArray,transferArray,
  
   
   //analyzeCheckArray(checkArray)
   //analyzeDeposits(depositArray)
   //analyzeTransferArray(transferArray)
   //analyzeUncategorizedArray(uncategorizedArary)

   //BUSINESS
    //addToBusinessPod(advertisingArray,'Business','Advertising','Advertising')

    //EDUCATION
    addToEducationPod(booksArray,'Education','Books and Supplies','Books & Supplies')
   // addToEducationPod(booksAndSuppliesArray,'Education','Books and Supplies','Books & Supplies')
    addToEducationPod(booksAndSuppliesArray,'Education','Misc','Education')
    addToEducationPod(officeSuppliesArray,'Education','Misc','Office Supplies')
    addToEducationPod(educationArray,'Education','Misc','Education')
    addToEducationPod(tuitionArray,'Education','Tuition','Tuition')

    //ENTERTAINMENT
    addToEntertainmentPod(alcoholAndBarsArray,'Entertainment','Alcohol and Bars','Alcohol & Bars')
    addToEntertainmentPod(amusementArray,'Entertainment','Misc','Amusement')
    addToEntertainmentPod(artsArray,'Entertainment','Misc','Arts')
    addToEntertainmentPod(entertainmentArray,'Entertainment','Misc','Entertainment')
    addToEntertainmentPod(moviesAndDvdsArray,'Entertainment','Media','Movies & DVDs')
    addToEntertainmentPod(musicArray,'Entertainment','Media','Music')
    addToEntertainmentPod(newspapersAndMagazinesArray,'Entertainment','Media','Newspapers & Magazines')
    addToEntertainmentPod(streamingServicesArray,'Entertainment','Media','Streaming Services')
    addToEntertainmentPod(televisionArray,'Entertainment','Media','Television')

    

    //FINANCIAL
    addToFinancialPod(atmFeeArray,'Financial','Fees and Charges','ATM Fee')
    addToFinancialPod(bankFeeArray,'Financial','Fees and Charges','Bank Fee')
    addToFinancialPod(cashAndAtmArray,'Financial','ATM','Cash & ATM')
    addToFinancialPod(checkArrayNegative,'Financial','Checks','Check')
    addToFinancialPod(federalTaxArray,'Financial','Taxes','Federal Tax')
    addToFinancialPod(feesAndChargesArray,'Financial','Fees and Charges','Fees & Charges')
    addToFinancialPod(financeChargeArray,'Financial','Fees and Charges','Finance Charge')
    addToFinancialPod(financialArray,'Financial','Fees and Charges','Financial')
    addToFinancialPod(financialAdvisorArray,'Financial','Fees and Charges','Financial Advisor')
    addToFinancialPod(atmFeeArray,'Financial','Fees and Charges','ATM Fee')
    addToFinancialPod(cashAndAtmArray,'Financial','ATM','Cash & ATM')
    addToFinancialPod(lateFeeArray,'Financial','Fees and Charges','Late Fee')
    addToFinancialPod(lifeInsuranceArray,'Financial','Life Insurance','Life Insurance')
    addToFinancialPod(loanFeesAndChargesArray,'Financial','Loans','Loan Fees and Charges')
    addToFinancialPod(loanInsuranceArray,'Financial','Loans','Loan Insurance')
    addToFinancialPod(loanInterestArray,'Financial','Loans','Loan Interest')
    addToFinancialPod(loanPaymentArray,'Financial','Loans','Loan Payment')
    addToFinancialPod(loanPrincipalArray,'Financial','Loans','Loan Principal')
    addToFinancialPod(loansArray,'Financial','Loans','Loans')
    addToFinancialPod(localTaxArray,'Financial','Taxes','Local Tax')
    addToFinancialPod(lowBalanceArray,'Financial','Fees and Charges','Low Balance')
    addToFinancialPod(salesTaxArray,'Financial','Taxes','Sales Tax')
    addToFinancialPod(serviceFeeArray,'Financial','Fees and Charges','Service Fee')
    addToFinancialPod(stateTaxArray,'Financial','Taxes','State Tax')
    addToFinancialPod(studentLoanArray,'Financial','Loans','Loans')
    addToFinancialPod(taxesArray,'Financial','Taxes','Taxes')
    addToFinancialPod(transferArray,'Financial','Transfers','Transfer')
 


    //FOOD AND DRINK
    addToFoodAndDrinkPod(fastFoodArray,'Food and Drink','Dining Out', 'Fast Food')
    addToFoodAndDrinkPod(coffeShopsArray,'Food and Drink','Coffee','Coffee Shops')
    addToFoodAndDrinkPod(foodAndDiningArray,'Food and Drink','Dining Out','Food & Dining')
    addToFoodAndDrinkPod(groceriesArray,'Food and Drink','Groceries','Groceries')
    addToFoodAndDrinkPod(restaurantsArray,'Food and Drink','Dining Out','Restaurants')


    //GIFTS AND CHARITY
    addToGiftsAndCharityPod(charityArray,'Gifts and Charity','Donations', 'Charity')
    addToGiftsAndCharityPod(giftArray,'Gifts and Charity','Gifts', 'Gift')
    addToGiftsAndCharityPod(giftsAndDonationsArray,'Gifts and Charity','Donations', 'Gifts & Donations')

 


   //HOUSING...create rule about Apple
   addToHousingPod(billsAndUtilitiesArray,'Housing','Utilities','Bills & Utilities')
   addToHousingPod(furnishingsArray,'Housing','Misc','Furnishings')
   addToHousingPod(homeArray,'Housing','Misc','Home')
   addToHousingPod(homeImprovementArray,'Housing','Maintenance','Home Improvement')
   addToHousingPod(homeInsuranceArray,'Housing','Insurance','Home Insurance')
   addToHousingPod(homePhoneArray,'Housing','Internet and Phone','Home Phone')
   addToHousingPod(homeServicesArray,'Housing','Maintenance','Home Services')
   addToHousingPod(homeSuppliesArray,'Housing','Maintenance','Home Supplies')
   addToHousingPod(internetArray,'Housing','Internet and Phone','Internet')
   addToHousingPod(laundryArray,'Housing','Misc','Laundry')
   addToHousingPod(lawnAndGardenArray,'Housing','Maintenance','Lawn & Garden')
   addToHousingPod(mobilePhoneArray,'Housing','Internet and Phone','Mobile Phone')
   addToHousingPod(mortgageAndRentArray,'Housing','Mortgage or Rent','Mortgage & Rent')
   
   addToHousingPod(propertyTaxArray,'Housig','Property Tax','Property Tax')
   addToHousingPod(utilitiesArray,'Housing','Utilities','Utilities')
   
   //INCOME
   addToIncomePod(allowanceArray,'Income','Credits','Allowance')
   addToIncomePod(bonusArray,'Income','Credits','Bonus')
   addToIncomePod(checkArrayPositive,'Income','Checks','Check')
   addToIncomePod(depositArray,'Income','Deposits','Deposit')
   addToIncomePod(incomeArray,'Income','Credits','Income')
   addToIncomePod(interestArray,'Income','Interest','Interest Income')
   addToIncomePod(paycheckArray,'Income','Paychecks','Paycheck')
   addToIncomePod(reimbursementArray,'Income','Credits','Reimbursement')




   addToIncomePod(cashAppArrayPositive,'Income','Credits','Transfer')
   addToIncomePod(appleCashArrayPositive,'Income','Credits','Transfer')
   addToIncomePod(paypalArrayPositive,'Income','Credits','Transfer')
   addToIncomePod(venmoArrayPositive,'Income','Credits','Transfer')
   addToIncomePod(zelleArrayPositive,'Income','Credits','Transfer')
   addToIncomePod(uncategorizedCreditsArray,'Income','Credits','Uncategorized')
   addToIncomePod(unclassifiedTransferPositive,'Income','Credits','Transfer')
    

   
   //KIDS
    addToKidsPod(babySuppliesArray,'Kids','Misc','Baby Supplies')
    addToKidsPod(babysitterAndDaycareArray,'Kids','Child Care','Babysitter & Daycare')
    addToKidsPod(childSupportArray,'Kids','Child Support','Child Support')
    addToKidsPod(kidsArray,'Kids','Misc','Kids')
    addToKidsPod(kidsActivitiesArray,'Kids','Misc','Kids Activities')
    addToKidsPod(toysArray,'Kids','Misc','Toys')



    //MEDICAL
    addToMedicalPod(dentistArray,'Medical','Dentist','Dentist')
    addToMedicalPod(doctorArray,'Medical','Doctor','Doctor')
   // addToMedicalPod(doctorArray,'Medical','Eyecare','Eyecare')
    addToMedicalPod(pharmacyArray,'Medical','Pharmacy','Pharmacy')
    addToMedicalPod(healthInsuranceArray,'Medical','Health Insurance','Health Insurance')

    //MOBILE PAYMENTS
     addToMobilePaymentsPod(appleCashArrayNegative,'Mobile Payments','Apple Cash','Transfer')
     addToMobilePaymentsPod(cashAppArrayNegative,'Mobile Payments','Cash App','Transfer')
     addToMobilePaymentsPod(paypalArrayNegative,'Mobile Payments','Paypal','Transfer')
     addToMobilePaymentsPod(venmoArrayNegative,'Mobile Payments','Venmo','Transfer')
     addToMobilePaymentsPod(zelleArrayNegative,'Mobile Payments','Zelle','Transfer')







    //PERSONAL
    addToPersonalPod(clothingArray,'Personal','Clothing','Clothing')
    addToPersonalPod(electronicsAndSoftwareArray,'Personal','Shopping','Electronics & Software')
    addToPersonalPod(gymArray,'Personal','Health and Gym','Gym')
    addToPersonalPod(hairArray,'Personal','Misc','Hair')
    addToPersonalPod(healthAndFitnessArray,'Personal','Health and Gym','Health & Fitness')
    addToPersonalPod(hobbiesArray,'Personal','Misc','Hobbies')
    addToPersonalPod(legalArray,'Personal','Misc','Legal')
    addToPersonalPod(personalCareArray,'Personal','Misc','Personal Care')
    addToPersonalPod(printingArray,'Personal','Misc','Printing')
    addToPersonalPod(shippingArray,'Personal','Shopping','Shipping')
    addToPersonalPod(shoppingArray,'Personal','Shopping','Shopping')
    addToPersonalPod(spaAndMassageArray,'Personal','Misc','Spa & Massage')
    addToPersonalPod(sportingGoodsArray,'Personal','Shopping','Sporting Goods')
    addToPersonalPod(sportsArray,'Personal','Shopping','Sports')
    
    addToPersonalPod(advertisingArray,'Personal','Misc','Advertising')



    //PETS
    addToPetsPod(petFoodAndSuppliesArray,'Pets','Food and Care','Pet Food & Supplies')
    addToPetsPod(petGroomingArray,'Pets','Food and Care','Pet Grooming')
    addToPetsPod(petsArray,'Pets','Food and Care','Pets')
    addToPetsPod(veterinaryArray,'Pets','Veterinary','Veterinary')



    //TRANSPORTATION
    addToTransportationPod(autoAndTransportArray,'Transportation','Other','Auto & Transport')
    addToTransportationPod(autoInsuranceArray,'Transportation','Insurance','Auto Insurance')
    addToTransportationPod(autoPaymentArray,'Transportation','Auto Payment','Auto Payment')
    addToTransportationPod(gasAndFuelArray,'Transportation','Gas','Gas & Fuel')
    addToTransportationPod(parkingArray,'Transportation','Parking','Parking')
    addToTransportationPod(publicTransportationArray,'Transportation','Other','Public Transportation')
    addToTransportationPod(rentalCarAndTaxiArray,'Transportation','Other','Rental Car & Taxi')
    addToTransportationPod(servicesAndPartsArray,'Transportation','Other','Services & Parts')
 


    //TRAVEL
    addToTravelPod(airTravelArray,'Travel','Air Travel','Travel')
    addToTravelPod(hotelArray,'Travel','Hotel','Hotel')
    addToTravelPod(travelArray,'Travel','Other','Travel')
    addToTravelPod(vacationArray,'Travel','Vacation','Vacation')

    addToUncategorizedPod(uncategorizedArray,'Uncategorized','Uncategorized','Uncategorized')
   

//   setTimeout(() => {
//     //trackCreatedBudget()
//     uploadTrackingRules()
//   }, 2000);

setTransactionQuestionsArray(previousState => [...localQuestionsArray])
if(localQuestionsArray.length >= 1){
    setShowTransactionDetails(true)
}
else {
    setLoading(true);
    uploadIncomePod()
    uploadSavingsPod()
    uploadEntertainmentPod()
    uploadEducationPod()
    uploadFoodAndDrinkPod()
    uploadFinancialPod()
    uploadGiftsAndCharityPod()
    uploadHousingPod()
    uploadKidsPod()
    uploadMedicalPod()
    uploadMobilePaymentsPod()
    uploadPetsPod()
    uploadPersonalPod()
    uploadTransportationPod()
    uploadTravelPod()
    uploadTrackingRules()

      //handleNavigateToMain()
}

}



//EDUCATION
function addToEducationPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...educationPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
    setEducationPod(currentPod)


}

//ENTERTAINMENT
function addToEntertainmentPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...entertainmentPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setEntertainmentPod(currentPod)


}

    //FINANCIAL
function addToFinancialPod(transactions,podTitle, lineItemDescription, transactionCategory){

        let currentPod = {...financialPod}
        let currentPodData = [...currentPod.data]
        let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
        let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


        let currentTransactionsArray = [...currentPod.transactionsArray]

        for(let i = 0; i < transactions.length; i++){
            currentTransactionsArray.push(transactions[i])
            currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
            addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
        }
        currentPodData[lineItemIndex].plannedValue = currentPlannedValue
        currentPod.data = currentPodData
        currentPod.transactionsArray = currentTransactionsArray
       
        setFinancialPod(currentPod)

    
    }

//FOOD AND DRINK
function addToFoodAndDrinkPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...foodAndDrinkPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setFoodAndDrinkPod(currentPod)


}

//GIFTS AND CHARITY
function addToGiftsAndCharityPod(transactions,podTitle, lineItemDescription, transactionCategory){
  
    let currentPod = {...giftsAndCharityPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setGiftsAndCharityPod(currentPod)


}

//HOUSING
function addToHousingPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...housingPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setHousingPod(currentPod)


}

//INCOME
function addToIncomePod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...incomePod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
       
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setIncomePod(currentPod)


}

//KIDS
function addToKidsPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...kidsPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setKidsPod(currentPod)


}

//MEDICAL
function addToMedicalPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...medicalPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setMedicalPod(currentPod)


}


//MOBILE PAYMENTS
function addToMobilePaymentsPod(transactions,podTitle, lineItemDescription, transactionCategory){
    
    let currentPod = {...mobilePaymentsPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setMobilePaymentsPod(currentPod)


}

//PERSONAL
function addToPersonalPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...personalPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)

    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setPersonalPod(currentPod)


}

   //PETS
   function addToPetsPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...petsPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
   setPetsPod(currentPod)


}


//TRANSPORTATION
function addToTransportationPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...transportationPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setTransportationPod(currentPod)


}

//TRAVEL
function addToTravelPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...travelPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setTravelPod(currentPod)


}

  //TRAVEL
  function addToUncategorizedPod(transactions,podTitle, lineItemDescription, transactionCategory){

    let currentPod = {...uncategorizedPod}
    let currentPodData = [...currentPod.data]
    let lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
    let currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)


    let currentTransactionsArray = [...currentPod.transactionsArray]

    for(let i = 0; i < transactions.length; i++){
        currentTransactionsArray.push(transactions[i])
        currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transactions[i].amount)
        addTrackingRule(transactions[i], podTitle, lineItemDescription, transactionCategory)
    }
    currentPodData[lineItemIndex].plannedValue = currentPlannedValue
    currentPod.data = currentPodData
    currentPod.transactionsArray = currentTransactionsArray
   
   
    setUncategorizedPod(currentPod)


}




async function addTrackingRule(transaction,podTitle,lineItemDescription){
    let currentTrackingRules = trackingRulesRef.current

    let transactionPayee = ''
    let transactionBestRep = ''
    let transactionCategory = ''

    if(typeof transaction.categorization !== 'undefined'){
        if(typeof transaction.categorization.normalizedPayeeName !== 'undefined'){
            transactionPayee = transaction.categorization.normalizedPayeeName
        }

        if(typeof transaction.categorization.bestRepresentation !== 'undefined'){
            transactionBestRep = transaction.categorization.bestRepresentation
        }
        

        if(typeof transaction.categorization.category !== 'undefined'){
            transactionCategory = transaction.categorization.category
        }
        

    }

    if(currentTrackingRules.length >= 1){
        let matchingCategoryObjects = currentTrackingRules.filter(obj => obj.transactionCategory === transactionCategory)

        if(matchingCategoryObjects.length >= 1){

          let matchingBestRepIndex = matchingCategoryObjects.findIndex(obj => obj.transactionBestRep === transactionBestRep)

          if(matchingBestRepIndex === -1){
            let matchingPayeeIndex = matchingCategoryObjects.findIndex(obj => obj.transactionPayeeName === transactionPayee)
            let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
                transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
                currentTrackingRules.push(trackingRule)
    
                trackingRulesRef.current = currentTrackingRules
       
          }


          else {
      
          
          }


        }
        else {
            //first of its category
      
            let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
                transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
                currentTrackingRules.push(trackingRule)
    
                trackingRulesRef.current = currentTrackingRules
        }
     

    }








    else {
        //tracking rules are at zero so add the first one
      
        let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
            transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
            currentTrackingRules.push(trackingRule)

            trackingRulesRef.current = currentTrackingRules
    }

       
    let todayEpoch = getTodaysEpoch()
    let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
    let trackingStatus = podTitle === 'Delete' ? 'Deleted' : 'Tracked'
    let myObject = {...transaction,podName: podTitle, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}


 
      let transactionDoc = doc(db,'EuklesUsersData',uid,'Transactions',String(transaction.id))
      await setDoc(transactionDoc,myObject).then(res => {
        return true
    }).catch(e => {

        
    })
  
}




// async function addTrackingRule(transaction,podTitle, lineItemDescription, transactionCategory){

 
 
//     if(typeof transaction.categorization.bestRepresentation !== 'undefined'){
      
//         let trackingRule = {transactionPayeeName: transaction.categorization.normalizedPayeeName,
//             transactionCategory: transactionCategory,transactionBestRep: transaction.categorization.bestRepresentation, thenTrackToPod:podTitle, andLineItem: lineItemDescription}
//         let currentArray = trackingRules


//         let matchingCategoryObjects = currentArray.filter(obj => obj.transactionCategory === trackingRule.transactionCategory)

//         if(matchingCategoryObjects.length >= 1){


//             let matchingBestRepresentationIndex = matchingCategoryObjects.findIndex(obj => obj.transactionBestRep === trackingRule.transactionBestRep)
            
            
//                 //*******MATCHING BEST REPRESENTATION SO CHECK FOR PAYEE */
//             if(matchingBestRepresentationIndex !== -1){

//                 let matchingPayeeIndex = matchingCategoryObjects.findIndex(obj => obj.transactionPayeeName === trackingRule.transactionPayeeName)

//                 if(matchingPayeeIndex !== -1){
                    
//                 }
//                 else {
//                     currentArray.push(trackingRule)
//                     setTrackingRules(previousState => [...currentArray])
//                 }


//             }
//                 //*************************************************** */



//             //*******NO MATCHING BEST REPRESENTATION SO ADD THE TRACKING RULE */
//             else {
                
//                 currentArray.push(trackingRule)
//                 setTrackingRules(previousState => [...currentArray])
//             }
//             //*************************************************** */


//         }




//  //*******NO MATCHING CATEGORY SO ADD THE TRACKING RULE */
//         else {
//             currentArray.push(trackingRule)
//             setTrackingRules(previousState => [...currentArray])
//         }
//     //*************************************************** */
            

     
//     }

       
//     let todayEpoch = getTodaysEpoch()
//     let trackingMonthAndYear = getMonthAndYearStringFromEpoch(transaction.transactionDate)
//     let trackingStatus = podTitle === 'Delete' ? 'Deleted' : 'Tracked'
//     let myObject = {...transaction,podName: podTitle, userLineItemDescription: lineItemDescription, trackingEpoch: todayEpoch,trackingStatus: trackingStatus, trackingMonthYear: trackingMonthAndYear}


//       let userUid = auth().currentUser.uid;
//      await firestore().collection('EuklesUsersData').doc(userUid).collection('Transactions').doc(String(transaction.id)).set(myObject).then(res => {
//          return true
//      }).catch(e => {

         
//      })
    
// }



function camelCaseForce(title){

    let titleArray = title.split(" ")
    for(let i = 0; i < titleArray.length; i++){
      
      titleArray[i] = titleArray[i].toLowerCase()
      titleArray[i] = titleArray[i][0].toUpperCase() + titleArray[i].substring(1)
    }
  
    titleArray = titleArray.join(" ")
    if(titleArray === 'Creditcard'){
      titleArray = 'Credit Card'
    }
    return titleArray
  }
  
  
  
  
  
  
  function determineFontSize(){
    let h = 14;
   
    
  return h
  }





async function generateCashFlowLocal(){
    await handleSaveAndGenerateCashFlow(finCustomerId, location.state.primaryCheckingAccountId, location.state.primaryCheckingAccountBalance)
 
}



function handleBudgetCategorySelection(title){
  if(title === 'Create a Category'){
  
 
    setShowAddBudgetGroupandLineItem(true)

    setSelectedLineItemDescription('Select a Line Item')
    
  }
  else {
    setSelectedPodTitle(title)
  
    setSelectedLineItemDescription('Select a Line Item')
  }
}

function handleSaveAndCloseAddCategory(title,lineItemDescription){
    let currentArray = customPodsArray
    currentArray.push({title:title,transactionsArray:[], data:[{description:lineItemDescription,plannedValue:0.00, spentValue:0.00, podName:title}]})
    setCustomPodsArray(previousState => [...currentArray])
    setSelectedPodTitle(title)
    setSelectedLineItemDescription(lineItemDescription)
}







function handleLineItemSelect(description,podData){
    setSelectedLineItemDescription(description)
  
    if(description === 'Create Custom Line Item'){
        handleStandardCreateCustomLineItemOnly(podData)
    }

    if(description === 'Create Custom Line Item Custom'){
        handleCreateCustomLineItemOnly()
    }
}

function determineLineItemDisplay(){

    let genericPodIndex = podTitles.findIndex(obj => obj === selectedPodTitle)

    if(genericPodIndex !== -1){
        //setSelectedLineItemDescription(incomePod.data[0].description)
   
        switch(selectedPodTitle){
  
            case 'Income':
              return(
             
          
        
              <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

<Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                  Select Line Item
                                  </Small>
  
                                  <Select  value={selectedlineItemDescription}
                           style={{minWidth:400}}
                            fullWidth
                            disableUnderline = {true}
                            onChange={(e) =>  handleLineItemSelect(e.target.value,incomePod.data)}
                        
                                      >
                                          <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                            {incomePod.data.map((item,i) => {
                                                                      return(
                                                                      <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                      )
                                                                  })}
                            </Select>
   


  </div>
    
        
                  
           
            )

            
        
            case 'Savings':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,savingsPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {savingsPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()} value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
            case 'Education':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,educationPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {educationPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
       
            case 'Entertainment':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,entertainmentPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {entertainmentPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
                        
                 
                  )
      
      
            case 'Financial':
                return(
             
                    
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,financialPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {financialPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
        
            case 'Food and Drink':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,foodAndDrinkPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {foodAndDrinkPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
        
        
            case 'Gifts and Charity':
                return(
             
                    
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,giftsAndCharityPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {giftsAndCharityPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
          
              
                        
                 
                  )
      
        
        
            case 'Housing':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,housingPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {housingPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
      
            case 'Kids':
                return(
             
                    
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,kidsPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {kidsPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
            case 'Medical':
                return(
             
                    
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,medicalPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {medicalPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
      
            case 'Pets':
                return(
 
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,petsPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {petsPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()} value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
                        
                 
                  )
      
      
            case 'Personal':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,personalPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {personalPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
      
            case 'Transportation':
                return(
             
                    
        
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,transportationPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {transportationPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
      
            case 'Travel':
                return(
             
                    
                    <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

                    <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                                      Select Line Item
                                                      </Small>
                      
                                                      <Select  value={selectedlineItemDescription}
                                                     style={{minWidth:400}}
                                                fullWidth
                                                disableUnderline = {true}
                                                onChange={(e) =>  handleLineItemSelect(e.target.value,travelPod.data)}
                        
                                                >
                                                    <MenuItem value='Create Custom Line Item'>Create Custom Line Item</MenuItem> 
                                                {travelPod.data.map((item,i) => {
                                                                                          return(
                                                                                          <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                          )
                                                                                      })}
                                                </Select>
                       
                    
                    
                      </div>
              
                        
                 
                  )
      
        
        
        
                default: 
                  return null;
         
        
        
        
          }
    }

    else {
        let customIndex = customPodsArray.findIndex(obj => obj.title === selectedPodTitle)
    
      
        return(
             
            <div style={{display:'flex', flexDirection:'column', marginTop:15}}>

            <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                              Select Line Item
                                              </Small>
              
                                              <Select  value={selectedlineItemDescription}
                                             style={{minWidth:400}}
                                        fullWidth
                                        disableUnderline = {true}
                                        onChange={(e) =>  handleLineItemSelect(e.target.value,customPodsArray[customIndex].data)}
                
                                        >
                                            <MenuItem value='Create Custom Line Item Custom'>Create Custom Line Item</MenuItem> 
                                        {customPodsArray[customIndex].data.map((item,i) => {
                                                                                  return(
                                                                                  <MenuItem key={i.toString()}  value={item.description}>{item.description}</MenuItem> 
                                                                                  )
                                                                              })}
                                        </Select>
               
            
            
              </div>
      

      
                
         
          )
    
    }


   
  
  }

  function handleStandardSaveAndCloseLineItemOnly(podTitle,lineItemDescription){
    let currentPod = ''
    let currentData = ''
  
    switch(podTitle){
        case 'Income':
           currentPod = {...incomePod}
           currentData = [...currentPod.data]
           currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
           currentPod.data = currentData
            setIncomePod(currentPod)
           break;

           case 'Savings':
            currentPod = {...savingsPod}
            currentData = [...currentPod.data]
            currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
            currentPod.data = currentData
            setSavingPod(currentPod)
            break;

            case 'Education':
                currentPod = {...educationPod}
                currentData = [...currentPod.data]
                currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                currentPod.data = currentData
                setEducationPod(currentPod)
                break;

                case 'Entertainment':
                    currentPod = {...entertainmentPod}
                    currentData = [...currentPod.data]
                    currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                
                    currentPod.data = currentData
                    setEntertainmentPod(currentPod)
                    break;

                    case 'Financial':
                        currentPod = {...financialPod}
                        currentData = [...currentPod.data]
                        currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                        currentPod.data = currentData
                        setFinancialPod(currentPod)
                        break;

                        case 'Food and Drink':
                            currentPod = {...foodAndDrinkPod}
                            currentData = [...currentPod.data]
                            currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                            currentPod.data = currentData
                            setFoodAndDrinkPod(currentPod)
                            break;

                            case 'Gifts and Charity':
                                currentPod = {...giftsAndCharityPod}
                                currentData = [...currentPod.data]
                                currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                currentPod.data = currentData
                                setGiftsAndCharityPod(currentPod)
                                break;

                                case 'Housing':
                                    currentPod = {...housingPod}
                                    currentData = [...currentPod.data]
                                    currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                    currentPod.data = currentData
                                    setHousingPod(currentPod)
                                    break;

                            case 'Kids':
                                currentPod = {...kidsPod}
                                currentData = [...currentPod.data]
                                currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                currentPod.data = currentData
                                setKidsPod(currentPod)
                                break;

                                case 'Medical':
                                    currentPod = {...medicalPod}
                                    currentData = [...currentPod.data]
                                    currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                    currentPod.data = currentData
                                    setMedicalPod(currentPod)
                                    break;

                                    case 'Mobile Payments':
                                        currentPod = {...mobilePaymentsPod}
                                        currentData = [...currentPod.data]
                                        currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                        currentPod.data = currentData
                                        setMobilePaymentsPod(currentPod)
                                        break;

                                        case 'Pets':
                                            currentPod = {...petsPod}
                                            currentData = [...currentPod.data]
                                            currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                            currentPod.data = currentData
                                            setPetsPod(currentPod)
                                            break;

                                            case 'Personal':
                                                currentPod = {...personalPod}
                                                currentData = [...currentPod.data]
                                                currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                                currentPod.data = currentData
                                                setPersonalPod(currentPod)
                                                break;

                                                case 'Transportation':
                                                    currentPod = {...transportationPod}
                                                    currentData = [...currentPod.data]
                                                    currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                                    currentPod.data = currentData
                                                    setTransportationPod(currentPod)
                                                    break;

                                                    case 'Travel':
                                                        currentPod = {...transportationPod}
                                                        currentData = [...currentPod.data]
                                                        currentData.push({description:lineItemDescription, plannedValue:0.00, spentValue:0.00, podName: podTitle})
                                                        currentPod.data = currentData
                                                        setTravelPod(currentPod)
                                                        break;
                                                        
                                                        default:
                                                            break;
        

                          
        
    }
  }

  function handleStandardCreateCustomLineItemOnly(lineData){
  
    setShowAddLineItemOnly(true)
    //navigation.navigate('AddLineItemOnly',{podTitle:selectedPodTitle,podLineItems: lineData, handleSaveAndCloseLineItemOnly: handleStandardSaveAndCloseLineItemOnly})
  }

  function handleCreateCustomLineItemOnly(){
    let currentCustomArray = customPodsArray
    let thePodIndex = currentCustomArray.findIndex(obj => obj.title === selectedPodTitle)
    let lineData = [...currentCustomArray[thePodIndex].data]
    setShowAddLineItemOnly(true)
    //navigation.navigate('AddLineItemOnly',{podTitle:selectedPodTitle,podLineItems: lineData, handleSaveAndCloseLineItemOnly: handleSaveAndCloseLineItemOnly})
  }

  function handleSaveAndCloseLineItemOnly(podTitle, lineItemDescription){
                    let currentCustomArray = customPodsArray
                    let thePodIndex = currentCustomArray.findIndex(obj => obj.title === podTitle)
                    currentCustomArray[thePodIndex].data.push({description:lineItemDescription,plannedValue:0.00, spentValue:0.00, podName: podTitle})
                
                    setCustomPodsArray(previousState => [...currentCustomArray])
  }




  async function addTransactionToUncategorized(){

    let currentArray = transactionQuestionsArray;
    let transaction = transactionQuestionsArray[0].transaction
    addTrackingRule(transaction, 'Delete', 'Delete', transactionQuestionsArray[0].category)
    
    
    currentArray.splice(0,1)
    setTransactionQuestionsArray(previousState => [...currentArray])
 
    setSelectedPodTitle('Select a Budget Group')
    setSelectedLineItemDescription('Select a Line Item')

    if(currentArray.length < 1){
        setLoading(true)
        uploadIncomePod()
        //uploadSavingsPod()
        let totalIncome = 0.00
        let monthlySavingsGoal = 0.00
   
       
      
        let currentIncomePod = {...incomePod}
        let podData = [...currentIncomePod.data]
        podData.forEach(lineItem => {
           totalIncome += Math.abs(lineItem.plannedValue)
        })
      
        if(totalIncome > 0.00){
           monthlySavingsGoal = parseFloat(0.25) * parseFloat(totalIncome)
        }
        uploadGoalAndSavings(monthlySavingsGoal)
        uploadEntertainmentPod()
        uploadEducationPod()
        uploadFoodAndDrinkPod()
        uploadFinancialPod()
        uploadGiftsAndCharityPod()
        uploadHousingPod()
        uploadKidsPod()
        uploadMedicalPod()
        uploadMobilePaymentsPod()
        uploadPetsPod()
        uploadPersonalPod()
        uploadTransportationPod()
        uploadTravelPod()
        uploadTrackingRules()
          //handleNavigateToMain()

        
    }
  
  }


  function handleSubmitUncategorizedTransactionAlt(groupAndLineItem,lineItemOnly){

    let podTitle = selectedPodTitle
    let lineItemDescription = selectedlineItemDescription

    if(groupAndLineItem){
        podTitle = groupName
        lineItemDescription = editLineDescription
    }
    if(lineItemOnly){
        lineItemDescription = editLineDescription
    }


 
    let transactionCategory = transactionQuestionsArray[0].transaction.categorization.category
    let transactionPayee = transactionQuestionsArray[0].transaction.categorization.normalizedPayeeName
    let transactionBestRep = transactionQuestionsArray[0].transaction.categorization.bestRepresentation

   let currentPod = ''
   let currentPodData = []
   let lineItemIndex = 0
   let currentPlannedValue = 0



    let currentArray = transactionQuestionsArray

    let filteredTransactions = []
    let samePayeesArray = []
 
    for(let i = 0; i < currentArray.length; i++){
        if(currentArray[i].transaction.categorization.category === transactionCategory){
                    let matchingPayee = false;
                    let matchingBestRep = false;

                    if(currentArray[i].transaction.categorization.normalizedPayeeName === transactionPayee){
                        matchingPayee = true;
                    }

                    if(currentArray[i].transaction.categorization.bestRepresentation === transactionBestRep){
                       matchingBestRep = true
                    }

                    // if(matchingPayee || matchingBestRep){
                    //     samePayeesArray.push({category: currentArray[i].transaction.categorization.category,transaction: currentArray[i].transaction})
                    // }
                    if(matchingBestRep){
                        samePayeesArray.push({category: currentArray[i].transaction.categorization.category,transaction: currentArray[i].transaction})
                    }
                    else if(!matchingBestRep){
                        filteredTransactions.push(currentArray[i])
                    }


        }
        else {
            filteredTransactions.push(currentArray[i])
        }
    
    }


    for(let i = 0; i < samePayeesArray.length; i++){
        let transaction = samePayeesArray[i].transaction
        switch(podTitle){
  
            case 'Income':
                 currentPod = {...incomePod}
                 currentPodData = [...currentPod.data]
                lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
                if(lineItemIndex !== -1){
                    currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
            
    
         
                    currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                    addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                
                currentPodData[lineItemIndex].plannedValue = currentPlannedValue
                currentPod.data = currentPodData
               
               setIncomePod(currentPod)
                   }
                   else {
                    currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                    addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                    currentPod.data = currentPodData
               
                    setIncomePod(currentPod)
                   }
                break;
        
            case 'Savings':
                currentPod = {...savingsPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setSavingPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
              setSavingPod(currentPod)
               }
               break;
           
            case 'Education':
                currentPod = {...educationPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setEducationPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setEducationPod(currentPod)
               }
              
               break;
      
       
            case 'Entertainment':
                currentPod = {...entertainmentPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setEntertainmentPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setEntertainmentPod(currentPod)
               }
               break;
      
    
            case 'Financial':
               currentPod = {...financialPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setFinancialPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setFinancialPod(currentPod)
               }
               break;
        
            case 'Food and Drink':
              currentPod = {...foodAndDrinkPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setFoodAndDrinkPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setFoodAndDrinkPod(currentPod)
               }
               break;
        
        
            case 'Gifts and Charity':
           currentPod = {...giftsAndCharityPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setGiftsAndCharityPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
               setGiftsAndCharityPod(currentPod)
               }
               break;
        
        
        
            case 'Housing':
             currentPod = {...housingPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setHousingPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setHousingPod(currentPod)
               }
               break;
        
      
            case 'Kids':
               currentPod = {...kidsPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setKidsPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setKidsPod(currentPod)
               }
               break;
        
      
            case 'Medical':
             currentPod = {...medicalPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setMedicalPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setMedicalPod(currentPod)
               }
               break;
        
      
            case 'Pets':
             currentPod = {...petsPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setPetsPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setPetsPod(currentPod)
               }
               break;
        
      
            case 'Personal':
            currentPod = {...personalPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setPersonalPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setPersonalPod(currentPod)
               }
               break;
        
      
            case 'Transportation':
             currentPod = {...transportationPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setTransportationPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setTransportationPod(currentPod)
               }
               break;
        
      
            case 'Travel':
             currentPod = {...travelPod}
                currentPodData = [...currentPod.data]
               lineItemIndex = currentPodData.findIndex(obj => obj.description === lineItemDescription)
               if(lineItemIndex !== -1){
                currentPlannedValue = Math.abs(currentPodData[lineItemIndex].plannedValue)
        

     
                currentPlannedValue = parseFloat(currentPlannedValue) + Math.abs(transaction.amount)
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
            
            currentPodData[lineItemIndex].plannedValue = currentPlannedValue
            currentPod.data = currentPodData
           
           setTravelPod(currentPod)
               }
               else {
                currentPodData.push({description: lineItemDescription, plannedValue: Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                currentPod.data = currentPodData
           
                setTravelPod(currentPod)
               }
               break;
        
        
        
        
        
        
        
      
        
                default: 
                    let currentCustomArray = customPodsArray
                    let thePodIndex = currentCustomArray.findIndex(obj => obj.title === podTitle)
                    if(thePodIndex === -1){
                        let newPod = {title:podTitle,data:[{description:editLineDescription,plannedValue:Math.abs(transaction.amount),spentValue:0.00, podName:podTitle}]}
                        currentCustomArray.push(newPod)
                        setCustomPodsArray(previousState => [...currentCustomArray])
                        addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                        setCustomPodsArray(previousState => [...currentCustomArray])
                    }
                    else {
                        let theLineIndex = currentCustomArray[thePodIndex].data.findIndex(obj => obj.description === lineItemDescription)
                        if(theLineIndex === -1){
                            currentCustomArray[thePodIndex].data.push({description:editLineDescription,plannedValue:Math.abs(transaction.amount),spentValue:0.00,podName:podTitle})
                            addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                            setCustomPodsArray(previousState => [...currentCustomArray])
                        }
                        else {
                            let currentCustomPlannedValue = Math.abs(currentCustomArray[thePodIndex].data[theLineIndex].plannedValue)
                            currentCustomPlannedValue = parseFloat(currentCustomPlannedValue) + Math.abs(transaction.amount)
                            addTrackingRule(transaction, podTitle, lineItemDescription, transactionCategory)
                            currentCustomArray[thePodIndex].data[theLineIndex].plannedValue = currentCustomPlannedValue
                            setCustomPodsArray(previousState => [...currentCustomArray])
                        }
                      
                    }
                
                    
                  
         
        
        
        
          }
        
    }



    // let currentArray = transactionQuestionsArray;
    // transactionQuestionsArray.splice(0,1)

    //setTransactionQuestionsArray(previousState => [...currentArray])
    setTransactionQuestionsArray(previousState => [...filteredTransactions])

   setSelectedPodTitle('Select a Budget Group')
   setSelectedLineItemDescription('Select a Line Item')
   setEditLineDescription('')
    setGroupName('')
   setShowAddBudgetGroupandLineItem(false)
   setShowAddLineItemOnly(false)

    if(filteredTransactions.length < 1){
    setLoading(true)
     uploadIncomePod()
     let totalIncome = 0.00
     let monthlySavingsGoal = 0.00

    
   
     let currentIncomePod = {...incomePod}
     let podData = [...currentIncomePod.data]
     podData.forEach(lineItem => {
        totalIncome += Math.abs(lineItem.plannedValue)
     })
   
     if(totalIncome > 0.00){
        monthlySavingsGoal = parseFloat(0.25) * parseFloat(totalIncome)
     }
     uploadGoalAndSavings(monthlySavingsGoal)
     //uploadSavingsPod()
     uploadEntertainmentPod()
     uploadEducationPod()
     uploadFoodAndDrinkPod()
     uploadFinancialPod()
     uploadGiftsAndCharityPod()
     uploadHousingPod()
     uploadKidsPod()
     uploadMedicalPod()
     uploadMobilePaymentsPod()
     uploadPetsPod()
     uploadPersonalPod()
     uploadTransportationPod()
     uploadTravelPod()
     uploadTrackingRules()
       //handleNavigateToMain()
    }
   

  }

  async function uploadGoalAndSavings(goal){
        let goalDoc = doc(db,'EuklesUsersData',uid,'MonthlySavingsGoal','goal')
        await setDoc(goalDoc,{ value: parseFloat(goal.toFixed(2))})
        // await firestore().collection('EuklesUsersData').doc(uid).collection('MonthlySavingsGoal').doc('goal').set({
        //     value: parseFloat(goal.toFixed(2))
        // })

        let savingsDoc = doc(db,'EuklesUsersData',uid,currentMonth,'Savings')
        await setDoc(savingsDoc,{ ['Savings']:{
            plannedValue: parseFloat(goal.toFixed(2)),
            spentValue: 0.00,
            podName:'Savings'
        }})


  }

  

async function uploadIncomePod(){
    let podName = 'Income'
    let thePod = {...incomePod}
    let podData =   [...thePod.data]
    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
         
        })
          
        }
        
    }
}

async function uploadSavingsPod(){
    let podName = 'Savings'
    let thePod = {...savingsPod}
    let podData =   [...thePod.data]
    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)


    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
        
        })
          
        }
        
    }
}

async function uploadEducationPod(){
    let podName = 'Education'
    let thePod = {...educationPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
    


    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
    
        })
          
        }
        
    }
 
}


async function uploadEntertainmentPod(){
    let podName = 'Entertainment'
    let thePod = {...entertainmentPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)


    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
       
        })
          
        }
        
    }
 
}

async function uploadFinancialPod(){
    let podName = 'Financial'
    let thePod = {...financialPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
            
        })
          
        }
        
    }
 
}


async function uploadFoodAndDrinkPod(){
    let podName = 'Food and Drink'
    let thePod = {...foodAndDrinkPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)


    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
     
        })
          
        }
        
    }
 
}

async function uploadGiftsAndCharityPod(){
    let podName = 'Gifts and Charity'
    let thePod = {...giftsAndCharityPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
  
        })
          
        }
        
    }
 
}

async function uploadHousingPod(){
    let podName = 'Housing'
    let thePod = {...housingPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
        
        })
          
        }
        
    }
 
}

async function uploadKidsPod(){
    let podName = 'Kids'
    let thePod = {...kidsPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   
    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
      
        })
          
        }
        
    }
 
}

async function uploadMedicalPod(){
    let podName = 'Medical'
    let thePod = {...medicalPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
   
        })
          
        }
        
    }
 
}

async function uploadMobilePaymentsPod(){
    let podName = 'Mobile Payments'
    let thePod = {...mobilePaymentsPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
          
        })
          
        }
        
    }
 
}

async function uploadPetsPod(){
    let podName = 'Pets'
    let thePod = {...petsPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
          
        })
          
        }
        
    }
 
}

async function uploadPersonalPod(){
    let podName = 'Personal'
    let thePod = {...personalPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
         
        })
          
        }
        
    }
 
}

async function uploadTransportationPod(){
    let podName = 'Transportation'
    let thePod = {...transportationPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
   
        })
          
        }
        
    }
 
}

async function uploadTravelPod(){
    let podName = 'Travel'
    let thePod = {...travelPod}
    let podData =   [...thePod.data]

    let filteredPod = podData.filter(obj => obj.plannedValue > 0.00)
   

    let podDocRef = doc(db,'EuklesUsersData',uid,currentMonth,podName)

    for(let i = 0; i < filteredPod.length; i++){
        let lineItem = filteredPod[i]
        if(i === 0){
                await setDoc(podDocRef,{
                    [lineItem.description]:{
                        plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                        spentValue: 0.00,
                        podName:podName
                    }
                }).catch(e => {
                    return false
                })
               
            
           
        }
        else {
           await updateDoc(podDocRef,{
            [lineItem.description]:{
                plannedValue: parseFloat(lineItem.plannedValue.toFixed(2)),
                spentValue: 0.00,
                podName:podName
            }
           }).catch(e => {
        
        })
          
        }
        
    }
 
}

async function uploadTrackingRules(){

   let currentRules = trackingRulesRef.current
  
    let count = 0
    for(let i = 0; i < currentRules.length; i++){
      let rulesRef = collection(db,'EuklesUsersData',uid,'TrackingRules')
      await addDoc(rulesRef,currentRules[i])
     
        count = parseInt(count) + 1
    }
 
    handleNavigateToMain();

 
}

async function handleNavigateToMain(){

    let mArray = [currentMonth]
    let monthsRef = doc(db,'EuklesUsersData',uid,'MonthsWithBudgets','months')
    await setDoc(monthsRef,{mArray})
  
    
    let userDoc = doc(db,'EuklesUsersData',uid)
    await updateDoc(userDoc,{updatedAccountsFormat: true, queryMonth: currentMonth})
  

 
    

  

   
    navigate('../insideautosubmitloading',{state:{trackingRules:trackingRulesRef.current, localBudgetAccounts: localBudgetAccounts, finCustomerId: finCustomerId}})
}



function handleSaveGroupAndLineItem(){
    setShowAddBudgetGroupandLineItem(false)
    
    handleSubmitUncategorizedTransactionAlt(false,true)
}

function handleSaveLineItem(){
    setShowAddLineItemOnly(false)
}


function handleCancelAddGroupAndLineItem(){
    setShowAddBudgetGroupandLineItem(false)

}

function evaluateGroupTitleAndLineDescription(){
    if(groupName !== '' && editLineDescription !== ''){
        let existingIndex = podTitles.findIndex(obj => obj === groupName)
        let existingCustomIndex = customPodsArray.findIndex(obj => obj.title === groupName)
        if(existingIndex === -1 && existingCustomIndex === -1){
            setCanSaveGroupAndLineItem(false)
        }

        
    }
}
    
if(loading){
  return(
  
    <Backdrop sx={{backgroundColor:'white'}} open={true} style={{zIndex:1400}}>
    <Grid container direction='column'  justifyContent='center' alignItems='center'>
      <Grid item>
      <Ellipsis color={theme.palette.primary.main} />
      </Grid>
      {/* <Grid item style={{paddingTop:5}}>
        <Typography style={{color:'white', fontWeight:'bold'}}>{localLoadingMessage}</Typography>
      </Grid> */}
    </Grid>
   
   
  </Backdrop> 
  )
}
else {
    if(showMinimumTransactionError){
        return (
            <Alert variant="filled" severity="error"  action={<div style={{display:'flex',flexDirection:'row',paddingTop:25}}> 
            <Button onClick={() => handleNoCheckingOrNoCardStepOne()} color="inherit" size="small">
            Reset and Go Manual
           </Button>
           <Button  onClick={() => navigate('../finicity',{state:{comingFrom: 'InsideAutoSubmit',urlToUse:url,finCustomerId:finCustomerId}})} color="inherit" size="small">
             Connect Accounts
           </Button>
           </div>
          
           
         }>
       We didn't detect a checking account or credit card.  Budget Even works best with at least one checking account and one credit card connected.
       </Alert>
        )
    }

    if(showInfoAboutGoingManual){
        return(
            <Alert onClick={() => localProcessCancellation()} style={{maxWidth:900}} variant="filled" severity="info" action={<div style={{display:'flex',flexDirection:'row',paddingTop:25}}> <Button onClick={() => handleNoCheckingOrNoCardStepOne()} style={{fontWeight:'bold'}} color="inherit" size="small">
            Go Manual
           </Button>
           <Button 
           onClick={() => navigate('../finicity',{state:{comingFrom: 'InsideAutoSubmit',urlToUse:url,finCustomerId:finCustomerId}})}

           style={{fontWeight:'bold'}}  color="inherit" size="small">
             Go Back
           </Button>
           </div>
          
           
         }>
             <AlertTitle>Budget Even Manual</AlertTitle>
       
             We will remove your connected accounts so you can still utilize Budget Even manually.  You can create a monthly budget, add transactions, and track your net worth by manually adding entries.  Switch to premium at any time.
       </Alert>
        )
    }

    else {

        if(showTransactionDetails){

            if(showAddLineItemOnly){
                return(
                    <div style={{display:'flex',flexDirection:'column', flex:1,height:'100%', justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
                    <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
                    <div style={{display:'flex', flexDirection:'column', alignContent:'center',alignItems:'center', justifyContent:'center'}}>
                      <Typography variant='h6' display="block" mb={1}>
                      Enter Line item Description
                    </Typography>
                     <LightTextField fullWidth style={{width:400,marginBottom:25}} name="orderNo"
                        InputProps={{disableUnderline:true}}
                     
                        inputProps={{style: {fontWeight:'bold'}}}
                        onChange={(e) => setEditLineDescription(e.target.value)}
                        value={editLineDescription}
                          
                            /> 

<Button disabled={editLineDescription === '' ? true : false} onClick={() => handleSaveGroupAndLineItem ()} style={{margin: '0 auto', display: "flex"}} variant='contained'>Submit</Button>
</div>
                    </div>
                )
            }

            if(showAddBudgetGroupAndLineItem){
                return(       <div style={{display:'flex',flexDirection:'column', flex:1,height:'100%', justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
                <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
                <div style={{display:'flex', flexDirection:'column', alignContent:'center',alignItems:'center', justifyContent:'center'}}>
                    <Typography variant='h6' display="block" mb={1}>
                    Enter Budget Group title
                  </Typography>
                  <Typography variant='caption'>*Alphanumeric characters only</Typography>
                   <LightTextField fullWidth style={{width:400}} name="group"
                      InputProps={{disableUnderline:true}}
                   
                      inputProps={{style: {fontWeight:'bold'}}}
                      onChange={(e) => setGroupName(e.target.value)}
                      value={groupName}
                        
                          /> 
                  </div>
                  <div style={{display:'flex', flexDirection:'column',marginTop:25, alignContent:'center',alignItems:'center', justifyContent:'center'}}>
                      <Typography variant='h6' display="block" mb={1}>
                      Enter Line item Description
                    </Typography>
                     <LightTextField fullWidth style={{width:400,marginBottom:25}} name="lineItem"
                        InputProps={{disableUnderline:true}}
                     
                        inputProps={{style: {fontWeight:'bold'}}}
                        onChange={(e) => setEditLineDescription(e.target.value)}
                        value={editLineDescription}
                        onBlur={() => evaluateGroupTitleAndLineDescription()}
                          
                            /> 
                    </div>
                  
                    <Button disabled={canSaveGroupAndLineItem} onClick={() => handleSubmitUncategorizedTransactionAlt(true,false)} style={{margin: '0 auto', display: "flex"}} variant='contained'>Submit</Button>
                    <div style={{marginTop:25}}>
                    <Button  onClick={() => handleCancelAddGroupAndLineItem()} style={{margin: '0 auto', display: "flex"}} variant='text'>Cancel</Button>
                    </div>
            
                    </div>
                )
            }
            else {
                return(
                    <div style={{display:'flex', flex:1,flexDirection:'column',backgroundColor:'white',height:'100%'}}>
                               <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
                        <div style={{display:'flex', paddingTop:50, flexDirection:'column',backgroundColor:theme.palette.primary.dialogBackground.dialogBackground, alignContent:'center',alignItems:'center', justifyContent:'center'}}>
                        <Typography style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold',marginLeft:10, marginRight:10, marginBottom:15, textAlign:'center', marginTop:10}}>Select a Budget Group and a Budget Line item to classify the following {transactionQuestionsArray.length} transactions: </Typography>
                        </div>
                      <div style={{display:'flex', flexDirection:'column', alignContent:'center',alignItems:'center', justifyContent:'center'}}>
              
                   
                    <Divider style={{width:400, height:3,backgroundColor:'black'}}/>
                      <div style={{height:1, backgroundColor: theme.palette.primary.navy, width:'auto', maxWidth:500}}></div>
              
                      {transactionQuestionsArray.map((item,i) => {
                        if(i === 0){
                          return (           
                         
                            <div key={`${item}`} style={{marginTop:5, marginBottom:5, paddingLeft:5, paddingRight:5}}>
                               {/* <Typography  style={{fontWeight:'bold'}}>Institution: {item.institutionName}</Typography> */}
                              <Typography  style={{fontWeight:'bold'}}>{formatTransactionDate(item.transaction.transactionDate)} {item.transaction.description}</Typography>
                        <Typography  style={{fontWeight:'bold'}}>Amount: {new Intl.NumberFormat('us-US',{style: 'currency', currency:'usd', maximumFractionDigits:2}).format(item.transaction.amount)}</Typography>
                        {typeof item.transaction.categorization.category === 'undefined' ? null :   <Typography style={{fontWeight:'bold'}}>Category: {item.transaction.categorization.category} </Typography> }
                        {typeof item.transaction.categorization.normalizedPayeeName === 'undefined'  ? null :  <Typography  style={{fontWeight:'bold'}}>Payee Name: {item.transaction.categorization.normalizedPayeeName}</Typography> }
                        {typeof item.transaction.categorization.bestRepresentation === 'undefined' ? null :   <Typography  style={{fontWeight:'bold'}}>Alt. Description: {item.transaction.categorization.bestRepresentation}</Typography> }
                      
                            </div>
                          )
                        }
                        else {
                          return null
                        }
                    
                      })}
                     <Divider style={{width:400, height:3,backgroundColor:'black'}}/>
                      <div style={{height:1, backgroundColor: theme.palette.primary.navy,width:'auto', maxWidth:500}}></div>
              
                           
                  
                
           
        
                <div style={{display:'flex', flexDirection:'column', marginTop:15}}>
        
        <Small display="block" mb={1} style={{color: theme.palette.primary.dialogBackground, fontWeight:'bold'}}>
                                          Select Budget Group
                                          </Small>
          
                                          <Select  value={selectedPodTitle}
                                  style={{minWidth:400}}
                                    fullWidth
                                    disableUnderline = {true}
                                    onChange={(e) =>  handleBudgetCategorySelection(e.target.value)}
                                
                                              >
                        {podTitles.map((item,i) => {
                        return(
                          <MenuItem key={i.toString()} value={item}>{item}</MenuItem>
                        )
                      })}
                      {customPodsArray.map((item,i) => {
                            return(
                              <MenuItem key={i.toString()} value={item.title}>{item.title}</MenuItem>
                            )
                      })}
                                 
                                    </Select>
           
        
        
          </div>
                  
                         
              
                            
               
              
                                      {selectedPodTitle === 'Select a Budget Group' ? null : determineLineItemDisplay()}
                            
                      
              
                               
                                   
                                    {selectedPodTitle !== 'Select a Budget Group' && selectedlineItemDescription !== 'Select a Line Item' ?  <Button  mode='contained' 
                                    onClick={() => handleSubmitUncategorizedTransactionAlt(false,false)}
                                    labelStyle={{fontWeight:'bold'}} style={{alignSelf:'center', color: theme.palette.primary.navy, marginTop:15}}>Next</Button> : null}
                                    </div>
              
                                      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop:100}}>
                                      <div style={{width:100,backgroundColor:'black', height:3}} ></div> 
                                      <Typography style={{textAlign:'center', fontWeight:'bold',marginLeft:10,marginRight:10 }}>Or</Typography>
                                      <div style={{width:100,backgroundColor:'black', height:3}} ></div> 
                                      </div>
                                 
              
              <Button labelStyle={{fontWeight:'bold', color:theme.palette.primary.dialogBackground}} onClick={() => addTransactionToUncategorized()} style={{alignSelf:'center'}}>Ignore this transaction</Button>
                                    <div style={{position:'absolute', bottom:0, left:0}}>
              
              </div>
                               
                         </div>
                  )
            }
          
        }
      
      
      
      
      
      
      
        else {
          return (
            
            <div style={{display:'flex',flexDirection:'column', flex:1,height:'100%', justifyContent:'center', alignContent:'center',backgroundColor:'white'}}>
            <img style={{alignSelf:'center',width:200, marginTop:25}} src={BudgetEvenLogo}  alt="Logo" />
            <div style={{display:'flex', flexDirection:'column', alignContent:'center',alignItems:'center', justifyContent:'center'}}>
        
       
              <Table style={{width: isDownMedium ? '100%' : 'auto',  minWidth: isDownMedium ? 300 : 500,}}>
            <TableHead sx={{backgroundColor: theme.palette.primary.dialogBackground}}>
              <TableRow>
              <TableCell  style={{color:'white', fontWeight:'bold'}}>Institution</TableCell>
              <TableCell style={{color:'white', cursor:'pointer', fontWeight:'bold'}}>Transactions</TableCell>
          
              </TableRow>
        
            </TableHead>
            <TableBody style={{backgroundColor:'white', }}>

  
            {localBudgetAccounts.map((item,i) => {
                            return(
                                <TableRow key={`${item.name} + ${i}`}>
                                    <TableCell  >
                                    <Typography style={{color: theme.palette.primary.blackcoral.blackcoral,fontSize: determineFontSize(), fontWeight:'600'}}>{camelCaseForce(item.institutionName)}: {camelCaseForce(item.accountNickname)}</Typography>
                                    </TableCell>
                                 
                                    <TableCell style={{textAlign:'right'}}>
                                   
                                      {!item.isLoadingTransactions && item.errorRetrievingTransactions ?   <Typography style={{textAlign:'center',color: 'red',fontSize: determineFontSize(), fontWeight:'600'}} >X</Typography> : null}
                                      {!item.isLoadingTransactions && !item.errorRetrievingTransactions ?      <Typography style={{textAlign:'center',color: theme.palette.primary.blackcoral.blackcoral,fontSize: determineFontSize(), fontWeight:'600'}} >{item.numberOfTransactions}</Typography> : null}
                                    
                                    </TableCell>
                                  
                                </TableRow>
                            )
                        })}
            </TableBody>
            </Table>
    




            <div style={{width:'100%', height:200}}>
                      <Typography style={{color:'transparent'}}>Hello</Typography>
                    </div>
       
        
                  
        
                    </div>
              </div>
          )
        }
      
      
      
      
      }
}

 
}